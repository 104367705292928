/** @jsx jsx */
// @flow
import { useState, useEffect, useRef, useCallback, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { ItemRow } from '../../layouts/entry.js'
import { Button } from '../Button'
import CurrencyInput from 'react-currency-input'
import axios from '../../utils/axios'
import { useHistory } from 'react-router-dom'
import { useAlert } from 'react-alert'
import currency from 'currency.js'

// Fix autofocus issues with CurrencyInput
// on iOS it will still auto focus even if autoFocus=false
// see https://github.com/jsillitoe/react-currency-input/issues/90
let componentDidMount_super = CurrencyInput.prototype.componentDidMount
CurrencyInput.prototype.componentDidMount = function () {
  if (!this.props.autoFocus) {
    let setSelectionRange_super = this.theInput.setSelectionRange
    this.theInput.setSelectionRange = () => {}
    componentDidMount_super.call(this, ...arguments)
    this.theInput.setSelectionRange = setSelectionRange_super
  } else {
    componentDidMount_super.call(this, ...arguments)
  }
}
let componentDidUpdate_super = CurrencyInput.prototype.componentDidUpdate
CurrencyInput.prototype.componentDidUpdate = function () {
  if (!this.props.autoFocus) {
    let setSelectionRange_super = this.theInput.setSelectionRange
    this.theInput.setSelectionRange = () => {}
    componentDidUpdate_super.call(this, ...arguments)
    this.theInput.setSelectionRange = setSelectionRange_super
  } else {
    componentDidMount_super.call(this, ...arguments)
  }
}

const CheckPanel = styled.div`
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  box-shadow: 0 -8px 24px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  max-width: 575px;
`

const CheckPanelBottomContainer = styled.div`
  padding: 0 12px 12px 12px;
  @media (max-width: 360px) {
    padding: 0 12px 12px 12px;
  }
`

const TipAmountMenu = styled.div`
  min-height: 32px;
  border-radius: 16px;
  border: solid 1px rgba(148, 158, 175, 0.16);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 15px 0;
  width: 100%;
`

const Amount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  color: #949eaf;
  padding: 3px 25px;
  cursor: pointer;

  ${(p) =>
    p.active &&
    css`
      background-color: #feebcb;
      color: #fcac24;
      border-radius: 16px;
      font-weight: bold;
    `}
`

const CheckTotalsContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  margin-bottom: 15px;
`

const CheckItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #000000;
  width: 100%;
  padding-right: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.04);
`

const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  justify-content: space-between;
  h3 {
    margin: 0;
  }
`

const CheckItemRow = styled(ItemRow)`
  margin: 2px 0;
`

const ButtonContainer = styled.div`
  display: flex;
  > button {
    width: 50%;

    &:first-of-type {
      margin-right: 16px;
      background: #fff;
      color: #949eaf;
      font-weight: 500;
      box-shadow: unset;
      border: 1px solid #f0f0f1;
    }
  }
`

const SplitPaymentContainer = styled.div`
  padding: 10px;
  background-color: rgba(43, 149, 44, 0.1);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 10px;
`

const SplitPaymentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;

  > span:last-of-type {
    color: green;
    font-weight: bold;
  }
`

const CheckSubtotalRow = styled.div`
  background-color: #e4edfa;
  padding: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  p {
    margin: 10px 0;
  }
`

let lastAnimationTimeTotalDue = new Date().getTime()

const TipPanel = ({
  setShowConfirmCloseCheckModal,
  setShowFixPaymentIssueModal,
  onSplitBill,
  displayTotal,
  setDisplayTotal,
  ...rest
}) => {
  const currencyInputRef = useRef()
  const totalDueRef = useRef()
  // default tip options
  const defaultTips = [18, 20, 25]
  const { checkin } = useSelector((state) => state)
  const dispatch = useDispatch()
  const history = useHistory()
  const alert = useAlert()

  const {
    checkinId,
    checkoutTriggered,
    gratuity,
    ticket: {
      discount,
      totals: { tax },
      undiscountedTotals: { subTotal, totalForTipCalculation, totalTippable },
      payments,
      splitPayments,
      splitTotal,
      tip,
      items,
      serviceCharges,
      tipIncludesTax,
    },
  } = checkin

  const [num, setNum] = useState(tip)

  const [tipOption, setTipOption] = useState(() => {
    return gratuity.isPercent && defaultTips.includes(gratuity.amount)
      ? gratuity.amount
      : 'Custom'
  })

  const updateGratuity = useCallback(
    (isPercent, gratuity) => {
      axios.API.put(`/checkin/${checkinId}/gratuity`, {
        isPercent,
        gratuity,
      })
        .then((res) => {
          const { gratuity } = res.data
          dispatch({
            type: 'checkin/updateCheckin',
            payload: {
              gratuity,
            },
          })
        })
        .catch((error) => {
          alert.show(error.message || error, {
            timeout: 0,
            header: 'Could not update tip. Try again.',
            gif: 'warning',
          })
        })
    },
    [alert, checkinId, dispatch]
  )

  const percentChange = (percent) => {
    setTipOption(percent)
    updateGratuity(true, percent)
  }

  const customChange = (input) => {
    const nextTip = parseFloat(input.target.value.replace('$', '')) || 0
    setNum(nextTip)
  }

  const calculateTipWithPercent = useCallback(
    (percent) => {
      const percentTippable = totalForTipCalculation / totalTippable
      const subTotalRemaining =
        totalTippable -
        (splitTotal?.subTotal + (tipIncludesTax ? splitTotal?.tax : 0) || 0)
      const remainingTippable = subTotalRemaining * percentTippable
      return currency(remainingTippable || 0, {
        fromCents: true,
      }).multiply(percent / 100).value
    },
    [totalTippable, tipIncludesTax, splitTotal, totalForTipCalculation]
  )

  useEffect(() => {
    const minTip = calculateTipWithPercent(18) * 100
    if (serviceCharges >= minTip && serviceCharges !== 0 && minTip !== 0) {
      setNum(0)
      updateGratuity(false, 0)
    }
  }, [serviceCharges, calculateTipWithPercent, updateGratuity])

  // recalculate tip and total if subtotal changes
  useEffect(() => {
    const { isPercent, amount } = checkin.gratuity
    const nextTip = isPercent
      ? calculateTipWithPercent(amount)
      : currency(amount, { fromCents: true }).value
    setNum(nextTip || 0)
  }, [checkin.gratuity, calculateTipWithPercent, checkin.ticket.totals.due])

  useEffect(() => {
    const displayTotal = currency(subTotal + serviceCharges, {
      fromCents: true,
    })
      .add(num * 100)
      .add(tax)
      .subtract(discount + payments.venue + payments.stripe + payments.split)
      .value
    setDisplayTotal(displayTotal)
  }, [
    num,
    setDisplayTotal,
    discount,
    payments.stripe,
    payments.venue,
    payments.split,
    subTotal,
    tax,
    serviceCharges,
  ])

  const tabClosedExitClick = () => {
    history.push('/thank-you')
  }

  const handleCurrencyClick = () => {
    setTipOption('Custom')
    setTimeout(() => {
      const { current: element } = currencyInputRef
      const selectionEnd =
        element.theInput.value.length - element.props.suffix.length
      const isNegative =
        (element.theInput.value.match(/-/g) || []).length % 2 === 1
      const selectionStart = element.props.prefix.length + (isNegative ? 1 : 0)
      element.theInput.setSelectionRange(selectionStart, selectionEnd)
    })
  }

  useEffect(() => {
    if (displayTotal) {
      const now = new Date().getTime()
      if (now - lastAnimationTimeTotalDue > 750) {
        lastAnimationTimeTotalDue = now
        if (totalDueRef.current) {
          totalDueRef.current.classList.toggle('animate__heartBeat')
        }
        setTimeout(() => {
          if (totalDueRef.current) {
            totalDueRef.current.classList.toggle('animate__heartBeat')
          }
        }, 750)
      }
    }
  }, [displayTotal])

  return (
    <CheckPanel {...rest}>
      <CheckSubtotalRow>
        <span>Check Subtotal</span>
        <span
          css={css`
            font-weight: bold;
          `}
        >
          {currency(subTotal, { fromCents: true }).format()}
        </span>
      </CheckSubtotalRow>
      {splitPayments.length > 0 && (
        <Fragment>
          <SplitPaymentContainer>
            <span
              css={css`
                margin-bottom: 8px;
                display: block;
                color: #333333;
              `}
            >
              Split via Outpay (exc. tax/tip):
            </span>
            {splitPayments.map((payment, i) => (
              <SplitPaymentRow key={i}>
                <span>
                  {payment.firstName} {payment.lastName}
                  {payment.subTotal === 0 &&
                    payment.tax === 0 &&
                    payment.tip === 0 && (
                      <span
                        css={css`
                          font-size: 14px;
                        `}
                      >
                        {' '}
                        (Refunded)
                      </span>
                    )}
                </span>
                <span>
                  (
                  {currency(payment.subTotal, {
                    fromCents: true,
                  }).format()}
                  )
                </span>
              </SplitPaymentRow>
            ))}
          </SplitPaymentContainer>

          <ItemRow
            css={css`
              padding: 0 20px;

              > span {
                font-weight: bold;
              }

              @media (max-width: 360px) {
                padding: 0 12px;
              }
            `}
          >
            <span>Remaining Subtotal</span>
            <span>
              {currency(subTotal, { fromCents: true })
                .subtract(
                  currency(
                    splitPayments.reduce((acc, curr) => acc + curr.subTotal, 0),
                    {
                      fromCents: true,
                    }
                  )
                )
                .format()}
            </span>
          </ItemRow>
        </Fragment>
      )}
      <CheckPanelBottomContainer>
        <TipAmountMenu>
          {defaultTips.map((tip) => {
            return (
              <Amount
                active={tipOption === tip}
                onClick={() => (checkoutTriggered ? null : percentChange(tip))}
                key={tip}
              >
                {`${tip}%`}
              </Amount>
            )
          })}
          <CurrencyInput
            ref={currencyInputRef}
            onClick={handleCurrencyClick}
            css={css`
              ${tipOption === 'Custom' &&
              css`
                && {
                  background-color: #feebcb;
                  color: #fcac24;
                  border-radius: 16px;
                  font-weight: bold;
                  &:focus {
                    &::-webkit-input-placeholder {
                      /* Chrome/Opera/Safari */
                      color: #fcac24;
                    }
                    outline-width: 0;
                  }
                }
              `}

              &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: #949eaf;
              }

              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              line-height: 1.43;
              text-align: center;
              color: #949eaf;
              padding: 3px 5px;
              cursor: pointer;
              border: 0 transparent;
              background: none;
              width: 100%;
            `}
            thousandSeparator=","
            decimalSeparator="."
            prefix="$"
            allowNegative={false}
            value={tipOption === 'Custom' ? num : null}
            onChangeEvent={customChange}
            placeholder="Tip"
            disabled={checkin.checkoutTriggered}
            inputMode="numeric"
            pattern="[0-9]*"
          />
        </TipAmountMenu>
        <CheckTotalsContainer>
          <CheckItems>
            {serviceCharges > 0 && (
              <CheckItemRow>
                Service Charges:
                <b>{currency(serviceCharges, { fromCents: true }).format()}</b>
              </CheckItemRow>
            )}
            <CheckItemRow>
              Tax:{' '}
              <b>
                {currency(splitTotal ? tax - splitTotal.tax : tax, {
                  fromCents: true,
                }).format()}
              </b>
            </CheckItemRow>
            <CheckItemRow>
              Tip: <b>{currency(num).format()}</b>
            </CheckItemRow>
            {discount > 0 && (
              <CheckItemRow>
                Discount:
                <b
                  css={css`
                    color: green;
                  `}
                >
                  ({currency(discount, { fromCents: true }).format()})
                </b>
              </CheckItemRow>
            )}
            {payments?.venue > 0 && (
              <CheckItemRow>
                Paid at {checkin.vendor.name}:
                <b
                  css={css`
                    color: green;
                  `}
                >
                  ({currency(payments.venue, { fromCents: true }).format()})
                </b>
              </CheckItemRow>
            )}
            {payments?.stripe > 0 && (
              <CheckItemRow>
                Paid w/ Outpay:
                <b
                  css={css`
                    color: green;
                  `}
                >
                  (
                  {currency(payments.stripe, {
                    fromCents: true,
                  }).format()}
                  )
                </b>
              </CheckItemRow>
            )}
          </CheckItems>
          <TotalContainer>
            Total Due
            <h3 ref={totalDueRef} className="animate__animated">
              {currency(displayTotal).format()}
            </h3>
          </TotalContainer>
        </CheckTotalsContainer>
        {tipOption === 'Custom' && // show accept if editing custom tip
          checkin.gratuity.amount !== currency(num).intValue &&
          !checkin.checkoutTriggered && (
            <Button
              active
              onClick={() => updateGratuity(false, currency(num).intValue)}
            >
              Accept
            </Button>
          )}
        {!checkin.checkoutTriggered &&
          (checkin.gratuity.amount === currency(num).intValue ||
            tipOption !== 'Custom') &&
          !checkin.stripe.paymentIssue && (
            <ButtonContainer>
              <Button active={items.length !== 0} onClick={onSplitBill}>
                Split Bill
              </Button>
              <Button
                active
                secondary={items.length === 0}
                onClick={() => setShowConfirmCloseCheckModal(true)}
              >
                {items.length > 0 ? 'Pay Bill' : 'Cancel'}
              </Button>
            </ButtonContainer>
          )}
        {!checkin.checkoutTriggered &&
          checkin.ticket.open &&
          checkin.stripe.paymentIssue && (
            <Button active onClick={() => setShowFixPaymentIssueModal(true)}>
              Update Payment Method
            </Button>
          )}
        {checkin.checkoutTriggered &&
          !checkin.ticket.open &&
          checkin.stripe.paymentIssue && (
            <Button active onClick={() => setShowFixPaymentIssueModal(true)}>
              {checkin.ticket.payments.platform
                ? 'Pay Balance Due'
                : 'Retry Payment'}
            </Button>
          )}
        {checkin.checkoutTriggered && checkin.ticket.open && (
          <Button>Processing...</Button>
        )}
        {checkin.checkoutTriggered &&
          !checkin.ticket.open &&
          !checkin.stripe.paymentIssue && (
            <Button active onClick={tabClosedExitClick}>
              Tab Closed - Exit
            </Button>
          )}
      </CheckPanelBottomContainer>
    </CheckPanel>
  )
}

export default TipPanel
