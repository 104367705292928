import checkmark from '../assets/lottie/checkmark.json'

export default (alert, alertData) => {
  const { data, error, ...defaults } = alertData
  const flowDependentCodes = [
    'card_declined'
  ]
  let alertCode = error.code
  if (flowDependentCodes.includes(error.code)) {
    alertCode = `${data.flow}_${error.code}`
  }
  console.log('ALERT FLOW CODE', alertCode)
  console.log('ERROR IS', error)
  console.log('DATA IS', data)
  const alertOptions = {
    checkin_card_declined: {
      header: 'Failed to Open Tab',
      message: `Your card was declined for preauth. Try again with another payment method.`,
      gif: 'wallet',
    },
    active_card_declined: {
      header: 'Payment Issue',
      message:
        'Preauthorization failed, please update your card or add a new one.',
      gif: 'wallet',
    },
    checkout_card_declined: {
      header: 'Payment Failed',
      message:
        'Your card was declined. Please update your payment method and try again.',
      gif: 'wallet',
    },
    offline: {
      header: 'Venue Offline',
      message: 'Looks like we\'re having some network issues. Please try again shortly.',
      gif: 'online',
    },  
    checkin_invalid_table: {
      header: 'Failed to Open Tab',
      message:
        'The table is invalid. Try to scan qr code again or try another qr code.',
      gif: 'warning',
    },
    post_ticket_fail: {
      header: 'Failed to Open Tab',
      message:
        'Looks like there was a problem opening your ticket. Please try again.',
      gif: 'pos',
    },
    discount_fail: {
      timeout: 0,
      code: 'discount_fail',
      type: 'error',
      header: 'Discount failed',
      message: "Looks like your discount wasn't applied.",
      gif: 'flag',
    },
    payment_fail: {
      header: 'Payment Failed',
      message: "Looks like your payment didn't go through.",
      gif: 'wallet',
    },
    closed_on_pos: {
      timeout: 0,
      type: 'success',
      header: 'Already Closed',
      message: 'Your tab has already been closed. Have a good night!',
      gif: 'orderCompleted',
    },
    ticket_not_found: {
      header: 'Ticket not Found',
      message: 'Please open a new tab.',
      gif: 'question',
    },
    closed_by_error: {
      header: 'Ticket Closed due to Error',
      message:
        'Your ticket has been closed. Please contact Support to fix this issue.',
      gif: 'flag',
    },
    already_paid: {
      header: 'Already paid',
      message: 'Your tab has already been taken care of.',
      gif: 'paymentProcessed',
    },
    unresolved_payment: {
      header: 'Payment Issue',
      message: 'Your tab was closed due to a payment issue, please retry payment.',
      gif: 'warning',
    },
    employee_not_clocked_in: {
      header: 'Employee not clocked in',
      message: 'Please notify your server to clock in employee.',
      gif: 'warning',
    },
    // NON-ERRORS
    success_post_ticket: {
      header: "Success. Your tab's open.",
      lottie: {
        loop: false,
        animationData: checkmark,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      },
    },
    split_pay_received: {
      header: 'Split Payment Received'
    },
    idle_close_items: {
      header: `You're all set!`,
      message: `We've closed your check due to inactivity and collected payment. If you'd like to open another check please scan the QR.`,
      lottie: {
        loop: false,
        animationData: checkmark,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }
    },
    success_post_ticket_from_invalid_table: {
      header: "Table not found.",
      lottie: {
        loop: false,
        animationData: checkmark,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      },
    },
    success_post_ticket_from_invalid_revenue_center: {
      header: "Revenue center not found.",
      lottie: {
        loop: false,
        animationData: checkmark,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      },
    },
    success_post_ticket_from_invalid_server: {
      header: "Server not found.",
      lottie: {
        loop: false,
        animationData: checkmark,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      },
    },
    // NON-ERRORS, DON'T NEED FOR NOW
    // ticket_open: {
    //   type: 'success',
    //   header: 'Your Tab is now Open',
    //   message: 'You can now place your order through your server.',
    // },
    // ticket_updated: {
    //   code: 'ticket_updated',
    //   type: 'success',
    //   header: 'Tab Updated',
    //   message: "We're updating your tab.",
    // },
    // ticket_closed: {
    //   code: 'ticket_closed',
    //   type: 'success',
    //   header: 'Checkout Complete',
    //   message: "We've sent your receipt to the email on file.",
    // },
    // venue_online: {
    //   type: 'info',
    //   header: 'Venue Online',
    //   message: "We're back online!",
    // },
    default: {
      header: 'Oops, something went wrong.',
      message: 'Reload your browser or contact support if your issue persists.',
      gif: 'warning',
    },
  }
  console.log(alertOptions[alertCode])
  const showOptions = { ...defaults, ...alertOptions[alertCode] || alertOptions.default}
  console.log(showOptions)
  alert.show(showOptions.message, showOptions)
}
