/** @jsx jsx */
// @flow
import { useEffect, useState, useCallback, Fragment } from 'react'
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { BillItem } from '../components/BillItem'
import axios from '../utils/axios'
import { Button } from '../components/Button'
import { useAlert } from 'react-alert'
import ModalCloseButton from '../components/modals/ModalCloseButton'
import getCSSHeight from '../utils/getCSSHeight.js'
import AddMobilePay from '../components/Checkout/AddMobilePay'
import ModalContainer from '../components/Modal'
import AddCreditCard from '../components/Checkout/AddCreditCard'
import SelectPaymentButton from '../components/Buttons/SelectPaymentButton'
import Loader from '../components/Loader/Loader'
import { isIOS } from 'react-device-detect'
import Header from '../components/Header/Header'

const BillContentContainer = styled.div`
  width: 100%;
  height: ${(props) =>
    `calc(100vh - ${props.checkPanelHeight + (isIOS ? 80 : 0)}px)`};
  padding: 50px 20px 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  align-items: center;
  > button {
    width: 100%;
  }
`

export const BillPageContainer = styled.div`
  width: 100%;
  height: ${getCSSHeight()};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CheckPanel = styled.div`
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  box-shadow: 0 -8px 24px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  max-width: 575px;
  position: absolute;
  bottom: 0;
  padding: 10px;
`

const AddPaymentRow = styled.div``

const ModalContentContainer = styled.div`
  width: 100%;
  z-index: 2;
  max-width: 575px;
  pointer-events: auto;
`

const WrongCheckContainer = styled.div`
  text-align: center;
`

const HintContainer = styled.div`
  border-radius: 20px;
  background-color: #eee;
  padding: 10px 20px;
`

const HintTitle = styled.p`
  font-size: 20px;
  margin: 8px 0;
`

const HintText = styled.p``

const AcceptCheck = () => {
  const { checkin, patron, app, vendor } = useSelector((state) => state)
  const { ticket } = checkin

  const [showAddCreditCard, setShowAddCreditCard] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [triggerCheckin, setTriggerCheckin] = useState(false)
  const [checkPanelHeight, setCheckPanelHeight] = useState(0)

  const dispatch = useDispatch()
  const history = useHistory()
  const alert = useAlert()
  const params = useParams()

  const runCheckin = useCallback(() => {
    console.log('RUN CHECKIN')
    if (patron.defaultCard && vendor.id && !checkin.checkinId) {
      dispatch({
        type: 'app/setIsAutoCheckin',
        payload: {
          isAutoCheckin: false,
        },
      })
      setShowLoader(true)
      const table = app.table
      const revenueCenter = app.revenueCenter
      const server = app.server
      const check = params.check_id
      const locationId = checkin.pos.options.location
      return axios.API.post(`/checkin/patron/${patron.id}${locationId ? '/?location=' + locationId : ''}`, {
        vendor_id: vendor.id,
        ...(table && {
          table,
        }),
        ...(revenueCenter && {
          revenueCenter,
        }),
        ...(server && {
          server,
        }),
        ...(check && {
          check,
        }),
      })
        .then(({ data }) => {
          setShowLoader(false)
          dispatch({
            type: 'checkin/postCheckin',
            payload: data,
          })
          dispatch({
            type: 'app/updateCheck',
            payload: {
              check: null,
            },
          })
          history.push('/bill')
        })
        .catch((error) => {
          console.log('error: ', error)
          if (
            (error.message || '')
              .toLowerCase()
              .includes('One or more checkins already exist')
          ) {
            setTimeout(() => {
              runCheckin()
            }, 5000)
          } else {
            setShowLoader(false)
            alert.show(error.message || error, {
              timeout: 0,
              header: 'Error opening check',
              gif: 'warning',
            })
          }
        })
    }
  }, [
    dispatch,
    history,
    vendor.id,
    patron.defaultCard,
    patron.id,
    app.table,
    app.revenueCenter,
    app.server,
    checkin.checkinId,
    alert,
    params.check_id,
  ])

  const handleStripeSuccess = useCallback(
    (card) => {
      dispatch({
        type: 'patron/updatePatron',
        payload: {
          defaultCard: card,
          cards: [card],
        },
      })

      setTriggerCheckin(true)
    },
    [dispatch]
  )

  useEffect(() => {
    if (triggerCheckin) {
      runCheckin()
    }
  }, [triggerCheckin, runCheckin])

  const handleAcceptCheck = () => {
    runCheckin()
  }

  useEffect(() => {
    setTimeout(() => {
      setCheckPanelHeight(document.getElementById('checkPanel')?.clientHeight)
    }, 1000)
  }, [])

  return (
    <BillPageContainer>
      <Header showSideNav>
        <h3>#{ticket?.checkNumber}</h3>
      </Header>

      {ticket?.items?.length > 0 && (
        <BillContentContainer checkPanelHeight={checkPanelHeight}>
          {ticket.items.map((item) => {
            return <BillItem item={item} key={item.id} />
          })}
        </BillContentContainer>
      )}
      <CheckPanel id="checkPanel">
        <WrongCheckContainer>
          <p>Wrong Check? Tell you server.</p>
        </WrongCheckContainer>
        {patron.defaultCard ? (
          ['guestBill', 'guestSplitBill'].includes(app.action) ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p>Welcome back, {patron.firstName}!</p>
              <p>How would you like to pay your share?</p>
              <p>
                Click &quot;Accept&quot; to use this card or the pencil icon to
                add a new card
              </p>
              <SelectPaymentButton />
              {showLoader ? (
                <Loader />
              ) : (
                <Button
                  active={!!patron.defaultCard}
                  onClick={handleAcceptCheck}
                >
                  Accept Check
                </Button>
              )}
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <SelectPaymentButton />
              {showLoader ? (
                <Loader />
              ) : (
                <Button
                  active={!!patron.defaultCard}
                  onClick={handleAcceptCheck}
                >
                  Accept Check
                </Button>
              )}
            </div>
          )
        ) : ['guestBill', 'guestSplitBill'].includes(app.action) ? (
          <div>
            <HintContainer>
              <HintTitle>Now how would you like to pay your share?</HintTitle>
              <HintText>
                This will pre-authorize your card, but you won&apos;t be charged
                until the end
              </HintText>
            </HintContainer>
            <AddPaymentRow>
              <AddMobilePay onSuccess={handleStripeSuccess} />
              <Button active dark onClick={() => setShowAddCreditCard(true)}>
                Add Credit Card
              </Button>
            </AddPaymentRow>
          </div>
        ) : (
          <div>
            <HintContainer>
              <HintTitle>How would you like to pay?</HintTitle>
              <HintText>
                <b>Pro tip:</b> Open 1 check for your party. Then you can split
                at the end however you want.
              </HintText>
            </HintContainer>
            <AddPaymentRow>
              <AddMobilePay onSuccess={handleStripeSuccess} />
              <Button active dark onClick={() => setShowAddCreditCard(true)}>
                Add Credit Card
              </Button>
            </AddPaymentRow>
          </div>
        )}
      </CheckPanel>

      <ModalContainer
        isOpen={showAddCreditCard}
        onRequestClose={() => setShowAddCreditCard(false)}
      >
        <Fragment>
          <ModalCloseButton />
          <ModalContentContainer>
            <AddCreditCard onSuccess={handleStripeSuccess} />
          </ModalContentContainer>
        </Fragment>
      </ModalContainer>
    </BillPageContainer>
  )
}

export default AcceptCheck
