import * as React from 'react'

function SvgVisa(props) {
  return (
    <svg width={43} height={30} {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#0742A6"
          d="M0 6.92C0 3.098 3.105 0 6.93 0h28.832c3.827 0 6.93 3.098 6.93 6.92v16.16c0 3.822-3.104 6.92-6.93 6.92H6.93C3.103 30 0 26.902 0 23.08V6.92z"
        />
        <g fill="#FFF">
          <path d="M19.58 19.398h-2.309l1.444-8.694h2.309zm8.368-8.482a5.817 5.817 0 00-2.07-.37c-2.28 0-3.885 1.184-3.895 2.877-.019 1.248 1.15 1.942 2.023 2.358.893.426 1.197.704 1.197 1.083-.01.582-.722.85-1.386.85-.922 0-1.416-.138-2.166-.462l-.304-.139-.323 1.952c.541.24 1.538.454 2.574.463 2.422 0 4-1.166 4.018-2.97.01-.99-.608-1.748-1.938-2.367-.807-.398-1.302-.666-1.302-1.073.01-.37.419-.75 1.33-.75a4.037 4.037 0 011.72.333l.208.093.314-1.878z" />
          <path
            fillRule="nonzero"
            d="M31.017 16.318l.921-2.433c-.01.019.19-.508.304-.832l.161.75s.437 2.08.532 2.515h-1.918zm2.85-5.613H32.08c-.55 0-.968.157-1.206.721l-3.43 7.973h2.423l.485-1.304h2.964c.066.305.275 1.304.275 1.304h2.137l-1.862-8.694z"
          />
          <path d="M15.344 10.704l-2.263 5.929-.247-1.203c-.417-1.387-1.727-2.895-3.19-3.644l2.07 7.603h2.442l3.628-8.684h-2.44z" />
          <path d="M10.984 10.704H7.268l-.037.175c2.897.722 4.816 2.461 5.604 4.552l-.807-3.997c-.133-.555-.541-.712-1.045-.729z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgVisa
