import React from 'react'
import styled from '@emotion/styled'
import SvgAmericanExpress from '../assets/images/svgr-converted/AmericanExpress'
import SvgMastercard from '../assets/images/svgr-converted/Mastercard'
import SvgVisa from '../assets/images/svgr-converted/Visa'
import SvgJcb from '../assets/images/svgr-converted/Jcb'
import SvgDinersclub from '../assets/images/svgr-converted/Dinersclub'
import Discover from '../assets/images/discover@3x.png'

const CardImg = styled.img`
  height: 40px;
  width: auto;
  object-fit: cover;
`

const determineCCLogo = (brand) => {
  let cardLogo = ''
  switch (brand) {
    case 'visa':
      cardLogo = <SvgVisa />
      break
    case 'mastercard':
      cardLogo = <SvgMastercard />
      break
    case 'jcb':
      cardLogo = <SvgJcb />
      break
    case 'dinersclub':
      cardLogo = <SvgDinersclub />
      break
    case 'american_express':
      cardLogo = <SvgAmericanExpress />
      break
    case 'discover':
      cardLogo = <CardImg alt="discover" src={Discover} />
      break
    default:
      console.log('Could not determine CC logo.')
      break
  }
  return cardLogo
}

export default determineCCLogo
