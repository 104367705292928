/** @jsx jsx */
// @flow
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { useState, useEffect } from 'react'

const CountdownDiv = styled.div`
  color: #fff;
`

const calculateTimeLeft = (endsAt) => {
  const ms = +new Date(endsAt) - +new Date()
  let minutes = Math.floor((ms / 1000 / 60) % 60)
  let seconds = Math.floor((ms / 1000) % 60)
  if(minutes <= 0 && seconds <=0) {
    return '00:00'
  }
  if(minutes.toString().length < 2) {
    minutes = `0${minutes}`
  }
  if(seconds.toString().length < 2) {
    seconds = `0${seconds}`
  }
  return minutes || seconds ? `${minutes}:${seconds}` : null
}

export default ({
  endsAt,
  css
}) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endsAt))

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(endsAt));
    }, 1000)
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  })

  return (
    <CountdownDiv css>
      {timeLeft}
    </CountdownDiv>
  )
} 

