/** @jsx jsx */
// @flow
import { css, jsx } from '@emotion/core'
import { Button } from './Button'
import { useDispatch } from 'react-redux'

const ChatButton = ({ onClick }) => {
  const dispatch = useDispatch()

  const onChatClick = () => {
    dispatch({
      type: 'app/setIsChatOpen',
      payload: {
        isChatOpen: true,
      },
    })
    if (onClick) {
      onClick()
    }
  }

  return (
    <Button
      css={css`
        background-color: #feebcb;
        pointer-events: none;
        font-size: 16px;
        text-align: center;
        color: #fcac24;
        background-color: #fcac24;
        color: #ffffff;
        pointer-events: auto;
        border-radius: 0;
        margin: 0;
        margin-bottom: 10px;
      `}
      onClick={onChatClick}
    >
      Chat with us
    </Button>
  )
}

export default ChatButton
