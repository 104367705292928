/** @jsx jsx */
// @flow
import { useState, Fragment } from 'react'
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { useAlert } from 'react-alert'
import { Button } from '../Button'

import SvgAmericanExpress from '../../assets/images/svgr-converted/AmericanExpress'
import SvgMastercard from '../../assets/images/svgr-converted/Mastercard'
import SvgVisa from '../../assets/images/svgr-converted/Visa'
import SvgJcb from '../../assets/images/svgr-converted/Jcb'
import SvgDinersclub from '../../assets/images/svgr-converted/Dinersclub'
import SvgByStripeOutlineLight from '../../assets/images/svgr-converted/ByStripeOutlineLight'
import Discover from '../../assets/images/discover@3x.png'

import media from '../../styles/media'
import Loader from '../../components/Loader/Loader'

const CommonInputStyles = css`
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  color: #949eaf;

  grid-column: 1 / 3;
  height: 48px;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;

  ::placeholder {
    color: #949eaf;
  }
`

const CardInfoContainer = styled.form`
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #31354c;
  margin-top: 30px;
  padding: 20px;
  width: 100%;
  align-items: center;
  .StripeElement {
    ${CommonInputStyles};

    div {
      width: 100%;
    }
  }
  margin-top: auto;
`

const PoweredByContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`
const GreyText = styled.div`
  color: #d1cdcd;
  font-size: 0.6rem;

  @media screen and (min-width: 340px) {
    font-size: 0.75rem;
  }
`
const CardIconsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`

const CardIcons = styled.div`
  width: 100%;
  max-width: 386px;

  display: grid;
  grid-gap: 5px;
  grid-template-rows: 30px;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

  img {
    height: 100%;
    width: auto;
    object-fit: cover;
  }
  ${media.greaterThan('350px')`
    grid-gap: 10px;
  `}
`

const CardSubmitContainer = styled.div`
  grid-column: 1 / 3;
  display: flex;
  justify-content: center;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`

const FormInput = styled.input`
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  color: #949eaf;

  height: 48px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  outline: none;

  background-color: #f8f8f8;
  border: 0;

  ::placeholder {
    color: #949eaf;
  }
`

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      fontWeight: 400,
      fontFamily: 'Helvetica',
      color: '#949eaf',
      '::placeholder': {
        color: '#949eaf',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
}

const StripeCreditCardForm = ({ isPayment, isLoading, amount, onCard }) => {
  const elements = useElements()
  const stripe = useStripe()
  const alert = useAlert()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return
      }

      if ((isPayment && !firstName) || !isCardComplete()) {
        alert.show('Enter name and card details', {
          timeout: 0,
          header: 'Fill out fields',
          gif: 'warning',
        })
        return
      }

      const cardElement = elements.getElement(CardNumberElement)
      onCard({
        cardElement,
        firstName,
        lastName,
        email,
      })
    } catch (error) {
      alert.show(error.message || error, {
        timeout: 0,
        header: 'Could not get payment.',
        gif: 'warning',
      })
    }
  }

  const handleCardNumberElementChange = (event) => {
    if (event.complete) {
      const expiryElement = elements.getElement(CardExpiryElement)
      expiryElement.focus()
    }
  }

  const handleCardExpiryElementChange = (event) => {
    if (event.complete) {
      const cvcElement = elements.getElement(CardCvcElement)
      cvcElement.focus()
    }
  }

  const isCardComplete = () => {
    const cardElement = elements.getElement(CardNumberElement)
    const expiryElement = elements.getElement(CardExpiryElement)
    const cvcElement = elements.getElement(CardCvcElement)
    return (
      cardElement._complete && expiryElement._complete && cvcElement._complete
    )
  }

  return (
    <CardInfoContainer onSubmit={handleSubmit}>
      {isPayment && (
        <Fragment>
          <Row>
            <FormInput
              css={css`
                margin-right: 8px;
              `}
              placeholder="enter first name"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
            <FormInput
              placeholder="enter last name"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
          </Row>
          <Row>
            <FormInput
              type="email"
              placeholder="enter email for receipt"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </Row>
        </Fragment>
      )}
      <CardNumberElement
        css={css`
          margin-bottom: 16px;
        `}
        id="cardNumber"
        name="Card_Number"
        options={ELEMENT_OPTIONS}
        onChange={handleCardNumberElementChange}
      />
      <div
        css={css`
          margin-bottom: 16px;
          display: flex;
        `}
      >
        <CardExpiryElement
          css={css`
            width: 50%;
            margin-right: 16px;
          `}
          id="expiry"
          options={ELEMENT_OPTIONS}
          onChange={handleCardExpiryElementChange}
        />
        <CardCvcElement
          css={css`
            width: 50%;
          `}
          id="cvc"
          options={ELEMENT_OPTIONS}
        />
      </div>

      <PoweredByContainer>
        <SvgByStripeOutlineLight
          onClick={() => window.open('https://stripe.com', '_blank')}
        />
        <GreyText>Safe and Secure SSL Encrypted</GreyText>
      </PoweredByContainer>
      <CardIconsContainer>
        <CardIcons>
          <SvgVisa />
          <img alt="discover" src={Discover} />
          <SvgMastercard />
          <SvgAmericanExpress />
          <SvgJcb />
          <SvgDinersclub />
        </CardIcons>
      </CardIconsContainer>
      <CardSubmitContainer>
        {isLoading ? (
          <Loader />
        ) : (
          <Button
            css={css`
              outline: 0;
              :focus {
                box-shadow: 0 0 3pt 2pt #00ff00;
              }
            `}
            type="submit"
            active
          >
            {isPayment ? `Pay ${amount}` : 'Add Payment Method'}
          </Button>
        )}
      </CardSubmitContainer>
    </CardInfoContainer>
  )
}

export default StripeCreditCardForm
