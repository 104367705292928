/** @jsx jsx */
// @flow
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import SvgOutpayLogotypeDisclimerOnlight from '../assets/images/outpay-logotype-disclimer-onlight.svg'

const Container = styled.div`
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`

const LogoDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
  margin-top: 100px;

  > img {
    width: 100%;
    margin-bottom: 4px;
  }
`

const FeedbackReceipt = () => {
  return (
    <Container>
      <LogoDiv>
        <img alt="Outpay logo" src={SvgOutpayLogotypeDisclimerOnlight} />
      </LogoDiv>
    </Container>
  )
}

export default FeedbackReceipt
