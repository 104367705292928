import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider as AlertProvider } from 'react-alert'
import { BrowserRouter as Router } from 'react-router-dom'
import store from './redux/store'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import AlertTemplate from './components/Alert'
import { isMobileOnly, isIOS, isAndroid } from 'react-device-detect'
import NotSupported from './layouts/not-supported'
import 'animate.css'
import ReceiptFeedback from './static/feedback-receipt'

Sentry.init({
  environment: process.env.REACT_APP_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
})

const alertOptions = {
  position: 'top center',
  transition: 'fade',
  offset: '0px',
}

const persistor = persistStore(store)
// Uncomment this if you want to purge your local redux storage
// persistor.purge()

// Lock orientation if running full screen when available like if adding webapp to homescreen
const isRunningFullscreen = () => {
  return window.matchMedia('(display-mode: fullscreen)').matches
}

if (isRunningFullscreen()) {
  const lockFunction = window.screen.orientation.lock
  if (lockFunction) {
    lockFunction.call(window.screen.orientation, 'portrait')
  }
}

if (window.location.pathname === '/feedback-receipt') {
  document
    .getElementById('document')
    .classList.remove('supported-landscape-rotate')
  ReactDOM.render(
    <React.StrictMode>
      <ReceiptFeedback />
    </React.StrictMode>,
    document.getElementById('root')
  )
} else {
  if (isMobileOnly && (isIOS || isAndroid)) {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router>
              <AlertProvider template={AlertTemplate} {...alertOptions}>
                <App />
              </AlertProvider>
            </Router>
          </PersistGate>
        </Provider>
      </React.StrictMode>,
      document.getElementById('root')
    )
  } else {
    // remove class that rotates normal app on landscape
    document
      .getElementById('document')
      .classList.remove('supported-landscape-rotate')
    ReactDOM.render(
      <React.StrictMode>
        <NotSupported />
      </React.StrictMode>,
      document.getElementById('root')
    )
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
