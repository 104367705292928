/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import Cookies from 'js-cookie'
import { Fragment } from 'react'
import { GoogleLogin } from 'react-google-login'
import { useDispatch } from 'react-redux'
import GoogleLoginDark from '../../../assets/images/btn_google_dark_normal_ios@3x.png'
import GoogleLoginLight from '../../../assets/images/google-login.svg'
import axios from '../../../utils/axios'
import getDefaultCard from '../../../utils/getDefaultCard'
import { Button } from '../../Button'
import { useAlert } from 'react-alert'

const Spacer = styled.div`
  width: 50px;
  height: 50px;
`

const ButtonText = styled.p`
  color: ${(props) => (props.split ? '#000' : '#fff')};
  @media (max-width: 360px) {
    font-size: 14px;
  }
`

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

const GoogleLoginButton = ({ split, light }) => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const responseGoogleSuccess = async (response) => {
    return axios.API.post('/patron/google', {
      id_token: response.tokenId,
      client_id: clientId,
    })
      .then(({ data, message }) => {
        let defaultCard = null
        let autoCheckin = true
        if (data.patron.default_payment_method) {
          defaultCard = getDefaultCard(
            data.patron.default_payment_method,
            data.patron.cards
          )
          autoCheckin = false
        }

        const patron = {
          token: data?.token,
          id: data?.patron?.id,
          email: data?.patron?.email,
          firstName: data?.patron?.first_name ?? '',
          lastName: data?.patron?.last_name ?? '',
          defaultCard,
          autoCheckin,
        }

        Cookies.set('outpay_token', patron.token)

        dispatch({
          type: 'patron/createLoginPatron',
          payload: patron,
        })
        dispatch({
          type: 'app/setIsAutoCheckin',
          payload: {
            isAutoCheckin: autoCheckin,
          },
        })
      })
      .catch((error) => {
        alert.show(error.message || error, {
          timeout: 0,
          header: 'Log in failed.',
          gif: 'warning',
        })
      })
  }

  const responseGoogle = (response) => {
    console.log(
      'Default response from google login. Login not successful.',
      response
    )
  }

  return (
    <Fragment>
      <GoogleLogin
        clientId={clientId}
        onSuccess={responseGoogleSuccess}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
        render={(renderProps) => {
          return (
            <Button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              css={css`
                height: 50px;
                min-height: unset;
                display: inline-flex;
                justify-content: ${split ? 'center' : 'space-between'};
                background-color: ${light ? '#fff' : '#4285f4'};
                align-items: center;
                box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.28);
                padding: 0;
                border-radius: 8px;
                border: ${light ? '2px solid #000' : '1px solid transparent'};
                margin: 0 0 4px 0;
                outline: 0;
                width: 100%;

                > div {
                  justify-content: center;
                  color: ${light ? '#000' : '#fff'};
                  font-size: 16px;
                  font-weight: bold;
                  font-stretch: normal;
                }

                && {
                  pointer-events: auto;
                }
              `}
            >
              <img
                css={css`
                  width: 50px;
                `}
                alt="GoogleLogin"
                src={light ? GoogleLoginLight : GoogleLoginDark}
              />

              <div>
                <ButtonText split={split}>
                  {split
                    ? 'Continue with Google to Split the Bill'
                    : 'Continue with Google'}
                </ButtonText>
              </div>
              {!split && <Spacer />}
            </Button>
          )
        }}
      />
    </Fragment>
  )
}

export default GoogleLoginButton
