/** @jsx jsx */
// @flow
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import determineCCLogo from '../../helpers/determine-cc-brand'
import ErrorIcon from '../../assets/images/svgr/ErrorIcon'
import EditIcon from '../../assets/images/svgr/EditIcon'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const Button = styled.button`
  ${(p) =>
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 8px;
      background-color: #fff;
      color: rgba(252, 172, 36, 0.4);
      box-shadow: 0 1px 3px 0 #c4c4c4;
      border: solid 0.5px #c4c4c4;
      min-height: 50px;
      cursor: pointer;

      font-family: Helvetica;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-decoration: none;
      margin: 5px 0;
      pointer-events: auto;
    `
  };
`

const SelectPaymentRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 0px 10px;
  text-decoration: none;

  .edit-icon {
    height: 25px;
    width: 25px;
    opacity: 0.8;
  }
`

type SelectPaymentButtonProps = {
  issue: Boolean
}

export default ({
  issue = false,
}: SelectPaymentButtonProps) => {
  const { patron } = useSelector((state) => state)
  const history = useHistory()
  return (
    <Button
      onClick={() => history.push('/select-payment')}
    >
      <SelectPaymentRow to="/select-payment">
        { issue && (
            <ErrorIcon className="edit-icon" css={css`
              fill: #ff4545;
            `}/>
        )}
        <p>Payment Method</p>
          {determineCCLogo(patron.defaultCard.brand)}
          <b>•••• {patron.defaultCard.last4}</b>
          <EditIcon className="edit-icon" />
      </SelectPaymentRow>
    </Button>
  )
} 

