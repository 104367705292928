export default (offset) => {
  let height =
    window.innerWidth > window.innerHeight
      ? document.documentElement?.clientWidth || window.innerWidth
      : document.documentElement?.clientHeight || window.innerHeight

  if (+offset) {
    height += offset
  }
  return `${height}px`
}
