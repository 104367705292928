/** @jsx jsx */
/* global zE */
import { jsx } from '@emotion/core'
import { useSelector } from 'react-redux'
import { Button } from '../Button'

const SupportButton = ({ label }) => {
  const { patron } = useSelector((state) => state)
  const name =
    patron && patron.firstName && patron.lastName
      ? `${patron.firstName} ${patron.lastName}`
      : ''
  const openWidget = () => {
    zE('webWidget', 'identify', {
      name,
      email: patron.email,
    })
    zE('webWidget', 'prefill', {
      name: {
        value: name,
      },
      email: {
        value: patron.email,
      },
    })
    zE('webWidget', 'show')
    zE('webWidget', 'open')
  }
  zE('webWidget:on', 'close', () => {
    zE('webWidget', 'hide')
  })
  return (
    <Button active={true} onClick={openWidget}>
      {label ? label : 'Support'}
    </Button>
  )
}

export default SupportButton
