/** @jsx jsx */
// @flow
import { useState } from 'react'
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CurvedModalContainer from './CurvedModal'
import ModalContainer from '../Modal'
import axios from '../../utils/axios'
import ModalCloseButton from './ModalCloseButton'
import Loader from '../../components/Loader/Loader'
import { Button } from '../../components/Button'
import { ButtonsContainer } from '../../layouts/bill'
import currency from 'currency.js'
import { useAlert } from 'react-alert'
import SelectPaymentButton from '../Buttons/SelectPaymentButton'

const ContentHeader = styled.p`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  margin-bottom: 0;
`
const ContentSubHeader = styled.p`
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  margin: 0.5em 0;
`

export const Divider = styled.span`
  height: 1px;
  width: 100%;
  background-color: #f0f0f0;
  display: block;
  text-align: center;
  margin: 20px 0;
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const FixPaymentIssueModal = ({ isOpen, setShowFixPaymentIssueModal, displayTotal }) => {
  const { patron, checkin } = useSelector((state) => state)
  const [showLoader, setShowLoader] = useState(false)
  const alert = useAlert()
  const dispatch = useDispatch()

  const fixPaymentIssue = ({ addOn }) => {
    setShowLoader(true)
    return axios.API.post(`/checkin/${checkin.checkinId}/fix`, {
      addOn
    })
      .then((response) => {
        console.log('response', response)
        setShowLoader(false)
      })
      .catch((error) => {
        console.log('error: ', error)
        setShowFixPaymentIssueModal(false)
        setShowLoader(false)
        alert.show(error.message || error, {
          timeout: 0,
          header: 'Failed to resolve payment issue.',
          gif: 'warning',
        })
      })
  }
  useEffect(() => {
    if (patron.token) {
      axios.API.get(`/cc/patron/${patron.id}`)
        .then(({ data }) => {
          const currentDefaultCard = data.cards.find((card) => {
            return card.id === data.default
          })

          dispatch({
            type: 'patron/updatePatron',
            payload: {
              defaultCard: currentDefaultCard,
              cards: data.cards,
            },
          })
        })
        .catch((error) => {
          alert.show(error.message || error, {
            timeout: 0,
            header: 'Could not get credit cards.',
            gif: 'warning',
          })
        })
    }
  }, [patron.id, patron.defaultCard.id, dispatch, alert, patron.token])
  useEffect(() => {
    setShowLoader(false)
  }, [checkin])
  return (
    <ModalContainer
      isOpen={isOpen}
      onRequestClose={() => setShowFixPaymentIssueModal(false)}
    >
      <Fragment>
        <ModalCloseButton onClick={() => setShowFixPaymentIssueModal(false)} />
        <CurvedModalContainer isOpen={isOpen}>
          <ContentHeader>
            {!checkin.ticket.open && ( checkin.ticket.payments.platform ?
              'Unresolved Payment Issue - Checkout Failed' 
              : 'Card Declined - Checkout Failed' )
            }
          </ContentHeader>
          <ContentSubHeader>
            {!checkin.ticket.open && ( checkin.ticket.payments.platform ?
              `Please update your payment method and pay remaining ${currency(displayTotal).format()} + $1 failed transaction fee.`
              : 'Please fix your payment method and retry checkout to avoid additional transaction fees.' )
            }
          </ContentSubHeader>
          <SelectPaymentButton issue={true} />
          <Divider />
          <ButtonsContainer
            css={css`
              grid-template-columns: 1fr;

              button {
                margin: 0;
              }
            `}
          >
            {showLoader ? <LoaderContainer><Loader/></LoaderContainer> : (
              <Button active onClick={fixPaymentIssue}>
                { checkin.ticket.payments.platform ? `Pay ${currency(displayTotal + 1).format()}` : 'Checkout - Retry'}
              </Button>
            )}
          </ButtonsContainer>
        </CurvedModalContainer>
      </Fragment>
    </ModalContainer>
  )
   
}

export default FixPaymentIssueModal
