/** @jsx jsx */
// @flow
import { jsx } from '@emotion/core'
import Feedback from './feedback'

const ThankYou = () => {
  return <Feedback />
}

export default ThankYou
