/** @jsx jsx */
// @flow
import { Fragment, useCallback } from 'react'
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import CurvedModalContainer from './CurvedModal'
import { Button } from '../Button'
import ModalContainer from '../Modal'
import { ButtonsContainer } from '../../layouts/bill'
import AddMobilePay from '../Checkout/AddMobilePay'
import ModalCloseButton from './ModalCloseButton'

const ContentHeader = styled.p`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
`

const AddNewPaymentMethodModal = ({ isOpen, onRequestClose }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { vendor, checkin, patron } = useSelector((state) => state)

  const handleStripeSuccess = useCallback((data) => {
    const card = {
      last4:  data.card.last4,
      exp_year:  data.card.exp_year,
      exp_month:  data.card.exp_month,
      brand:  data.card.brand,
      id:  data.id,
    }
    dispatch({
      type: 'patron/updatePatron',
      payload: {
        defaultCard: card,
        cards: [...patron.cards, card]
      },
    })
    history.push(checkin ? '/bill' : `/vendor/${vendor.id}`)
  }, [history, vendor.id, checkin, dispatch, patron.cards])

  return (
    <ModalContainer isOpen={isOpen} onRequestClose={onRequestClose}>
      <Fragment>
        <ModalCloseButton />
        <CurvedModalContainer isOpen={isOpen}>
          <ContentHeader>Add New Payment Method</ContentHeader>
          <ButtonsContainer
            css={css`
              grid-template-columns: 1fr;
              button {
                margin: 0;
              }
            `}
          >
            <AddMobilePay onSuccess={handleStripeSuccess} />
            <Button active onClick={() => history.push('/add-payment')}>
              Add Credit Card
            </Button>
          </ButtonsContainer>
        </CurvedModalContainer>
      </Fragment>
    </ModalContainer>
  )
}

export default AddNewPaymentMethodModal
