/** @jsx jsx */
// @flow
import { Fragment, useState, useEffect } from 'react'
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'
import ModalContainer from '../Modal'
import { Button } from '../Button'
import { ButtonsContainer } from '../../layouts/bill'
import axios from '../../utils/axios'
import CurvedModalContainer from './CurvedModal'
import { Divider } from './ConfirmOpenCheck'
import ModalCloseButton from './ModalCloseButton'
import { useAlert } from 'react-alert'
import Loader from '../../components/Loader/Loader'
import currency from 'currency.js'
import EmailForm from '../../components/Form/EmailForm'
import Config from '../../config'

const ContentHeader = styled.h3`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
  line-height: 40px;
  height: 40px;
`

const ConfirmCloseCheckModal = ({
  isOpen,
  handleCheckout,
  handleClose,
  displayTotal,
}) => {
  const [showLoader, setShowLoader] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [handleCloseTimeout, setHandleCloseTimeout] = useState(null)
  const [email, setEmail] = useState('')
  const { checkin, patron } = useSelector((state) => state)
  const { ticket } = checkin
  const dispatch = useDispatch()
  const alert = useAlert()

  useEffect(() => {
    if (isOpen) {
      setHandleCloseTimeout(
        setTimeout(() => {
          handleClose()
        }, 30000)
      )
    }
  }, [isOpen, handleClose])

  useEffect(() => {
    if (
      isOpen &&
      new Date() - (checkin.timeSent || 0) > Config.WAIT_TIME_FOR_UPDATE_TICKET
    ) {
      setIsFetching(true)
      axios.API.put(`/checkin/${checkin.checkinId}/?force=true`).catch(
        (error) => {
          setIsFetching(false)
          console.log('error: ', error)
          alert.show(error.message || error, {
            timeout: 0,
            header: 'Could not update check. Try again.',
            gif: 'warning',
          })
        }
      )
    }
  }, [isOpen, alert, checkin.checkinId, checkin.timeSent])

  useEffect(() => {
    setIsFetching(false)
  }, [checkin])

  useEffect(() => {
    // This force update is for totals due goes negative in cases of split pay.
    if (checkin.ticket.totals.due < 0) {
      axios.API.put(`/checkin/${checkin.checkinId}/?force=true`).catch(
        (error) => {
          console.log('error: ', error)
        }
      )
    }
  }, [checkin.checkinId, checkin.ticket.totals.due, alert])

  useEffect(() => {
    const { venue, stripe, split, platform } = checkin.ticket.payments
    const totalPayments = venue + stripe + split - platform
    // If preauth meets or exceeds amount due send to checkout.
    if (
      checkin.checkoutTriggered &&
      !(
        checkin.ticket.undiscountedTotals.total -
        (totalPayments + checkin.ticket.undiscountedTotals.discounts)
      ) &&
      showLoader
    ) {
      setShowLoader(false)
      handleCheckout()
    }
  }, [
    checkin.checkoutTriggered,
    checkin.ticket.payments,
    checkin.ticket.totals.due,
    showLoader,
    handleCheckout,
    checkin.ticket.undiscountedTotals.discounts,
    checkin.ticket.undiscountedTotals.total,
  ])

  const closeCheck = () => {
    console.log('clicking button for checkout: ', checkin.checkinId)
    if (checkin.checkinId) {
      clearTimeout(handleCloseTimeout)
      setHandleCloseTimeout(null)
      setShowLoader(true)
      return axios.API.put(`/checkin/${checkin.checkinId}/close`)
        .then((res) => {
          if (checkin.stripe.preauth >= checkin.ticket.undiscountedTotals.due) {
            setShowLoader(false)
            handleCheckout()
          }
          dispatch({
            type: 'checkin/updateCheckin',
            payload: res.data,
          })
        })
        .catch((error) => {
          console.log('error: ', error)
          setShowLoader(false)
          alert.show(error.message || error, {
            timeout: 0,
            header: 'Could not close check. Try again.',
            gif: 'warning',
          })
        })
    }
  }

  const handleNotYet = () => {
    clearTimeout(handleCloseTimeout)
    setHandleCloseTimeout(null)
    handleClose()
  }

  const handleConfirmAndPay = async () => {
    if (email) {
      setShowLoader(true)
      try {
        const { data } = await axios.API.put(`/patron/${patron.id}`, {
          email: email,
          email_verified: 0,
        })
        dispatch({
          type: 'patron/updatePatron',
          payload: {
            email: data.email,
          },
        })
        closeCheck()
      } catch (error) {
        console.error(error)
        // just close check if error as email is optional
        closeCheck()
      }
    } else {
      closeCheck()
    }
  }

  return (
    <ModalContainer isOpen={isOpen} onRequestClose={handleNotYet}>
      <Fragment>
        <ModalCloseButton />
        <CurvedModalContainer isOpen={isOpen}>
          {ticket.items.length > 0 && (
            <p
              css={css`
                margin: 10px 0;
              `}
            >
              Total Due:
            </p>
          )}
          {isFetching ? (
            <Loader sm />
          ) : (
            <ContentHeader>
              {ticket.items.length > 0 && currency(displayTotal).format()}
            </ContentHeader>
          )}
          <p
            css={css`
              margin: 8px 0 0 0;
            `}
          >
            {ticket.items.length > 0
              ? 'Confirm Payment and Close Check?'
              : "You haven't ordered anything, are you sure you want to close your check?"}
          </p>
          <Divider />
          {!patron.email && (
            <Fragment>
              <EmailForm emailChange={setEmail} />
              <Divider />
            </Fragment>
          )}
          {showLoader ? (
            <div
              css={css`
                height: 120px;
              `}
            >
              <Loader />
            </div>
          ) : (
            <ButtonsContainer
              css={css`
                grid-template-columns: 1fr;

                button {
                  margin: 0;
                }
              `}
            >
              <Button
                active={checkin.checkinId && !isFetching}
                onClick={handleConfirmAndPay}
              >
                {ticket.items.length > 0 ? 'Confirm and Pay' : 'Close Check'}
              </Button>
              <Button secondary active onClick={handleNotYet}>
                Not Yet
              </Button>
            </ButtonsContainer>
          )}
        </CurvedModalContainer>
      </Fragment>
    </ModalContainer>
  )
}

export default ConfirmCloseCheckModal
