import { combineReducers } from 'redux'
import checkinReducer from './checkinSlice'
import patronReducer from './patronSlice'
import vendorReducer from './vendorSlice'
import alertsReducer from './alertsSlice'
import appReducer from './appSlice'

export default combineReducers({
  checkin: checkinReducer,
  patron: patronReducer,
  vendor: vendorReducer,
  alerts: alertsReducer,
  app: appReducer,
})
