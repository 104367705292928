/** @jsx jsx */
// @flow
import { jsx } from '@emotion/core'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useStripe } from '@stripe/react-stripe-js'
import axios from '../../utils/axios'
import PaymentRequestWithStripe from './PaymentRequestWithStripe'
import { useAlert } from 'react-alert'

const AddMobilePay = ({ onSuccess, onCancel, onPaymentRequest }) => {
  const stripe = useStripe()
  const alert = useAlert()
  const { patron, app } = useSelector((state) => state)

  const handlePaymentMethod = useCallback(
    async (event) => {
      console.log(event)
      const intent = await axios.API.get(`/cc/patron/${patron.id}/wallet`)
        .then(({ data }) => {
          console.log('Payment Intent Response', data)
          return data
        })
        .catch((err) => {
          console.log(err)
        })

      console.log('intent: ', intent)

      // Confirm the PaymentIntent without handling potential next actions (yet).
      const { error: confirmCardSetup } = await stripe.confirmCardSetup(
        intent.clientSecret,
        {
          payment_method: event.paymentMethod.id,
        },
        {
          handleActions: false,
        }
      )

      if (confirmCardSetup) {
        console.log('In confirm Error', confirmCardSetup)
        console.log(confirmCardSetup.message)
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        alert.show(confirmCardSetup.message || confirmCardSetup, {
          timeout: 0,
          header: 'Could not add payment.',
          gif: 'warning',
        })
        return event.complete('fail')
      }

      console.log('No Confirm Error')
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.

      // SEND REQUEST TO SET AS DEFAULT PAYMENT OPTION HERE
      await axios.API.put(`/cc/patron/${patron.id}`, {
        card_id: event.paymentMethod.id,
      })

      event.complete('success')

      if (onSuccess) {
        onSuccess(event.paymentMethod)
      }
    },
    [onSuccess, patron.id, stripe, alert]
  )

  const handlePaymentMethodError = useCallback(
    (event) => {
      console.log('CANCEL EVENT', event)
      if (onCancel) {
        onCancel(event)
      }
    },
    [onCancel]
  )

  return (
    <PaymentRequestWithStripe
      label={app.isAutoCheckin ? 'Open tab with card' : 'Add Card'}
      amount={0}
      onPaymentMethod={handlePaymentMethod}
      onCancel={handlePaymentMethodError}
      onPaymentRequest={onPaymentRequest}
    />
  )
}

export default AddMobilePay
