// @flow
import React from 'react'
import styled from '@emotion/styled'
import { Button } from '../Button'
import SupportButton from '../Support/SupportButton'
import { useHistory } from 'react-router-dom'
import Lottie from 'react-lottie'

const AlertTemplateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  background-color: #000;
  opacity: 0.95;
  border: 0;
  min-height: 100vh;
  cursor: pointer;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-decoration: none;
  margin: 10px 20px;
`

const AlertContainer = styled.div`
  flex-flow: column nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 5vw;
  min-width: 90vw;
  min-height: 50vh;
  border-radius: 8px;
`

const Icon = styled.img`
  max-height: 10vh;
  margin: 2vh;
`

const ContentHeader = styled.h3`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  margin-top: 0;
`

const AlertTemplate = ({ style, options, message, close }) => {
  const history = useHistory()
  const toAddPayment = ['preauth_fail', 'payment_fail', 'checkin_card_declined']
  const toSupport = ['closed_by_error']
  return (
    <AlertTemplateContainer style={style}>
      <AlertContainer>
        {options.gif && (
          <Icon
            src={require(`../../assets/gif/${options.gif || 'warning'}.gif`)}
            alt="loading..."
          />
        )}
        {options.lottie && (
          <Lottie options={options.lottie} height={150} width={150} />
        )}
        <ContentHeader>{options.header}</ContentHeader>
        {options.message || message}
        {toAddPayment.includes(message) && (
          <Button
            active
            onClick={() => {
              history.push('/add-payment')
              close()
            }}
          >
            Try New Card
          </Button>
        )}
        {toAddPayment.includes(message) && (
          <Button
            active
            onClick={() => {
              history.push('/select-payment')
              close()
            }}
          >
            Use a Different Card
          </Button>
        )}
        {toSupport.includes(message) && <SupportButton />}
        <Button active onClick={close}>
          Dismiss
        </Button>
      </AlertContainer>
    </AlertTemplateContainer>
  )
}

export default AlertTemplate
