import React from 'react'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'

const Row = styled.div`
  width: 20px;
  height: 2px;
  background-color: black;
  margin: 4px 0;
  margin-right: 8px;
`

const MenuIcon = () => {
  const dispatch = useDispatch()

  const toggleMenu = () => {
    dispatch({
      type: 'app/setSideNavWidth',
      payload: {
        sideNavWidth: '60%',
      },
    })
  }

  return (
    <div onClick={toggleMenu}>
      <Row></Row>
      <Row></Row>
      <Row></Row>
    </div>
  )
}

export default MenuIcon
