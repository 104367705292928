/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import currency from 'currency.js'

export const BillItem = ({ item }) => (
  <div
    css={css`
      display: grid;
      grid-template-columns: 1fr 40px 80px;
      text-align: left;
      width: 100%;
      p {
        margin: 5px 0;
      }
      align-items: center;
    `}
  >
    <p>{item.name}</p>
    <p>{`${item.quantity}x`}</p>
    <div
      css={css`
        display: flex;
        justify-content: flex-end;
      `}
    >
      <b>{currency(item.pricePerUnit, { fromCents: true }).format()}</b>
    </div>
  </div>
)

BillItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    pricePerUnit: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }),
}
