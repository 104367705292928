/** @jsx jsx */
import { Fragment } from 'react'
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import SvgAppleLogin from '../../../assets/images/svgr/AppleLogin'
import SvgAppleLoginLight from '../../../assets/images/svgr-converted/AppleLoginSmall'
import axios from '../../../utils/axios'
import getDefaultCard from '../../../utils/getDefaultCard'
import { Button } from '../../Button'

const Spacer = styled.div`
  width: 31px;
  height: 44px;
`

const ButtonText = styled.p`
  @media (max-width: 360px) {
    font-size: 14px;
  }
`

const COOKIES = {
  firstName: 'siwa_first_name',
  lastName: 'siwa_last_name',
  email: 'siwa_email',
}

const appleClientID = process.env.REACT_APP_APPLE_CLIENT_ID

const AppleLoginButton = ({ split, light }) => {
  const dispatch = useDispatch()
  const alert = useAlert()

  const handleAppleSignIn = async () => {
    try {
      const data = await window.AppleID.auth.signIn()
      console.log('apple sign in success', data)
      if (data.user?.name?.firstName) {
        Cookies.set(COOKIES.firstName, data.user.name.firstName)
      }
      if (data.user?.name?.lastName) {
        Cookies.set(COOKIES.lastName, data.user.name.lastName)
      }
      if (data.user?.email) {
        Cookies.set(COOKIES.email, data.user.email)
      }

      const siwaFirstName = Cookies.get(COOKIES.firstName)
      const siwaLastName = Cookies.get(COOKIES.lastName)
      const siwaEmail = Cookies.get(COOKIES.email)

      let appleUser = {}
      if (siwaFirstName) {
        appleUser['first_name'] = siwaFirstName
      }

      if (siwaLastName) {
        appleUser['last_name'] = siwaLastName
      }

      if (siwaEmail) {
        appleUser['email'] = siwaEmail
      }

      // TODO: validate 'data.authorization.state' to be what we passed in
      axios.API.post('/patron/apple', {
        code: data.authorization.code,
        client_id: appleClientID,
        ...appleUser,
      })
        .then(({ data, message }) => {
          console.log('/patron/apple', data, message)
          let defaultCard = null
          let autoCheckin = true
          if (data.patron.default_payment_method) {
            defaultCard = getDefaultCard(
              data.patron.default_payment_method,
              data.patron.cards
            )
            autoCheckin = false
          }

          const patron = {
            token: data?.token,
            id: data?.patron?.id,
            email: data?.patron?.email,
            firstName: data?.patron?.first_name ?? '',
            lastName: data?.patron?.last_name ?? '',
            defaultCard,
            autoCheckin,
          }

          Cookies.set('outpay_token', patron.token)

          if (data?.patron?.first_name) {
            Cookies.remove(COOKIES.firstName)
          }
          if (data?.patron?.last_name) {
            Cookies.remove(COOKIES.lastName)
          }
          if (data?.patron?.email) {
            Cookies.remove(COOKIES.email)
          }

          dispatch({
            type: 'patron/createLoginPatron',
            payload: patron,
          })
          dispatch({
            type: 'app/setIsAutoCheckin',
            payload: {
              isAutoCheckin: autoCheckin,
            },
          })
        })
        .catch((error) => {
          alert.show(error.message || error, {
            timeout: 0,
            header: 'Log in failed.',
            gif: 'warning',
          })
        })
    } catch (error) {
      //handle error.
      console.error('AppleIDSignInOnFailure error: ', error)
    }
  }

  return (
    <Fragment>
      <Button
        css={css`
          height: 50px;
          width: 100%;
          display: flex;
          justify-content: ${split ? 'center' : 'space-between'};
          align-items: center;
          border-radius: 8px;
          margin: 0;
          pading: 0 !important;
          && {
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            pointer-events: auto;
          }
          background-color: ${light ? '#fff' : '#000'};
          border: ${light ? '2px solid #000' : 'unset'};
          color: #fff;
          p {
            margin: 0;
            color: ${light ? '#000' : '#fff'};
          }
          img {
            height: 21px;
            width: auto;
            margin-left: 10px;
          }
          .appleSignInButton {
            position: absolute !important;
            width: 100%;
            opacity: 0 !important;
            :hover {
              opacity: 0;
            }
          }
        `}
        onClick={handleAppleSignIn}
      >
        {light ? <SvgAppleLoginLight /> : <SvgAppleLogin />}

        <ButtonText className="noselect">
          {split
            ? 'Continue with Apple to Split the Bill'
            : 'Continue with Apple'}
        </ButtonText>
        {!split && <Spacer />}
      </Button>
    </Fragment>
  )
}

export default AppleLoginButton
