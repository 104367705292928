/** @jsx jsx */
// @flow
import { css, jsx } from '@emotion/core'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'

import { Button } from '../Button'

const LogoutButton = ({ handleLogout, onClick }) => {
  const dispatch = useDispatch()

  const logoutClick = () => {
    Cookies.remove('outpay_token')
    dispatch({
      type: 'checkin/closeCheckin',
    })
    dispatch({
      type: 'patron/logoutPatron',
    })

    if (handleLogout) {
      handleLogout()
    }
    if (onClick) {
      onClick()
    }
  }
  return (
    <Button
      css={css`
        background-color: #feebcb;
        pointer-events: none;
        font-size: 16px;
        text-align: center;
        color: #fcac24;
        background-color: #fcac24;
        color: #ffffff;
        pointer-events: auto;
        border-radius: 0;
        margin: 0;
      `}
      onClick={logoutClick}
    >
      Logout
    </Button>
  )
}

export default LogoutButton
