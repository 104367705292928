/** @jsx jsx */
// @flow
import { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { ItemRow } from '../../layouts/entry.js'
import { Button } from '../Button'
import CurrencyInput from 'react-currency-input'
import currency from 'currency.js'
import './TipPanelSplit.css'

// Fix autofocus issues with CurrencyInput
// on iOS it will still auto focus even if autoFocus=false
// see https://github.com/jsillitoe/react-currency-input/issues/90
let componentDidMount_super = CurrencyInput.prototype.componentDidMount
CurrencyInput.prototype.componentDidMount = function () {
  if (!this.props.autoFocus) {
    let setSelectionRange_super = this.theInput.setSelectionRange
    this.theInput.setSelectionRange = () => {}
    componentDidMount_super.call(this, ...arguments)
    this.theInput.setSelectionRange = setSelectionRange_super
  } else {
    componentDidMount_super.call(this, ...arguments)
  }
}
let componentDidUpdate_super = CurrencyInput.prototype.componentDidUpdate
CurrencyInput.prototype.componentDidUpdate = function () {
  if (!this.props.autoFocus) {
    let setSelectionRange_super = this.theInput.setSelectionRange
    this.theInput.setSelectionRange = () => {}
    componentDidUpdate_super.call(this, ...arguments)
    this.theInput.setSelectionRange = setSelectionRange_super
  } else {
    componentDidMount_super.call(this, ...arguments)
  }
}

const CheckPanel = styled.div`
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  box-shadow: 0 -8px 24px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  padding: 20px;
  max-width: 575px;

  @media (max-width: 360px) {
    padding: 10px;
  }
`

const TipAmountMenu = styled.div`
  min-height: 32px;
  border-radius: 16px;
  border: solid 1px rgba(148, 158, 175, 0.16);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 15px 0;
  width: 100%;
`

const Amount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  color: #949eaf;
  padding: 3px 25px;
  cursor: pointer;

  ${(p) =>
    p.active &&
    css`
      background-color: #feebcb;
      color: #fcac24;
      border-radius: 16px;
      font-weight: bold;
    `}
`

const CheckTotalsContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  margin-bottom: 15px;
`

const CheckItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #000000;
  width: 100%;
  padding-right: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.04);
`

const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  justify-content: space-between;
  h3 {
    margin: 0;
  }
`

const CheckItemRow = styled(ItemRow)`
  margin: 2px 0;
`

const TooltipText = styled.span`
  position: absolute;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #000;
  padding: 10px;
  text-align: center;
  font-size: 16px;
  color: #000;
  bottom: 100%;
  left: ${(document.documentElement?.clientWidth || window.innerWidth) > 380
    ? 35
    : 16}px;
  width: 200px;

  ::before {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
  }

  ::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
`

let lastAnimationTimeTotalDue = new Date().getTime()
const splitFee = 30
const prefillPortions = [4, 3, 2, 1]

const TipPanelSplit = ({
  displayTotal,
  setDisplayTotal,
  actions,
  onSplitInfo,
  selectedTotal,
  portionChange,
  initialSplitAmount,
  initialTax,
  ...rest
}) => {
  const currencyInputRef = useRef()
  const subTotalInputRef = useRef()
  const totalDueRef = useRef()
  // default tip options
  const defaultTips = [18, 20, 25]
  const { checkin } = useSelector((state) => state)
  const dispatch = useDispatch()

  const {
    checkoutTriggered,
    ticket: {
      totals: { tax: totalTax, subTotal },
      undiscountedTotals: { totalForTipCalculation, totalTippable },
      payments,
      splitTotal,
      tipIncludesTax,
    },
  } = checkin
  console.log('splitCheckin', checkin)
  console.log('initial split amount', initialSplitAmount)
  const [splitAmount, setSplitAmount] = useState(initialSplitAmount)
  const [num, setNum] = useState(0)
  const [tax, setTax] = useState(initialTax)

  const [tipOption, setTipOption] = useState(20)
  const [isValidAmount, setIsValidAmount] = useState(false)
  const [selectedPortion, setSelectedPortion] = useState()

  const updateGratuity = (isPercent, amount) => {
    setTimeout(() => {
      dispatch({
        type: 'checkin/updateCheckin',
        payload: {
          gratuity: {
            isPercent,
            amount,
          },
        },
      })
    }, 100)
  }

  const percentChange = (percent) => {
    const nextTip = currency(
      (splitAmount * 100 + (tipIncludesTax ? tax : 0)) *
        (totalForTipCalculation / totalTippable),
      { fromCents: true }
    ).multiply(percent / 100).value
    setTipOption(percent)
    setNum(nextTip)
    updateGratuity(true, percent)
  }

  const customChange = (input) => {
    const nextTip = parseFloat(input.target.value.replace('$', '')) || 0
    setNum(nextTip)
  }

  const splitAmountChange = useCallback(
    (input) => {
      const amount = input.target
        ? parseFloat(input.target.value.replace('$', '')) || 0
        : input

      const amountInCents = currency(amount).multiply(100).value
      let balanceRemaining =
        checkin.ticket.totals.subTotal -
        (checkin.ticket.splitPayments.reduce((acc, curr) => {
          return acc + curr.subTotal
        }, 0) +
          checkin.ticket.payments.venue +
          amount * 100)
      if (balanceRemaining < 50) {
        setSplitAmount(amount + balanceRemaining / 100)
        balanceRemaining = (amount + balanceRemaining / 100) * 100
      } else {
        setSplitAmount(amount)
        balanceRemaining = amount * 100
      }
      if (
        (amountInCents >= 50 && amountInCents <= subTotal - 50) ||
        amountInCents === subTotal
      ) {
        const nextTax = Math.round(totalTax * (balanceRemaining / subTotal))
        const remainingTax = totalTax - (splitTotal.tax + nextTax)
        const addCents = remainingTax > 0 && remainingTax < 3 ? remainingTax : 0
        console.log('next, remaining, add', nextTax, remainingTax, addCents)
        setTax(nextTax + addCents)
        subTotalInputRef.current.theInput.classList.remove('error')
        setIsValidAmount(true)
      } else {
        subTotalInputRef.current.theInput.classList.add('error')
        setIsValidAmount(false)
      }
    },
    [subTotal, totalTax, checkin, splitTotal]
  )

  useEffect(() => {
    if (selectedTotal > -1) {
      splitAmountChange(
        currency(selectedTotal, {
          fromCents: true,
        }).value
      )
      setSelectedPortion(undefined)
    }
  }, [selectedTotal, splitAmountChange])

  useEffect(() => {
    // console.log('tax', tax)
    // console.log('**USE EFFECT',  (checkin.ticket.totals.tax - checkin.ticket.splitTotal.tax) - Math.round(tax))
    // const addCent = (checkin.ticket.totals.tax - checkin.ticket.splitTotal.tax) - Math.round(tax) === 1 ? 1 : 0
    onSplitInfo({
      amount: currency(splitAmount).multiply(100).value,
      tax: tax,
      tip: currency(num).multiply(100).value,
      splitFee,
    })
  }, [onSplitInfo, splitAmount, tax, num])

  // recalculate tip and total if subtotal changes
  useEffect(() => {
    const { isPercent, amount } = checkin.gratuity
    const nextTip = isPercent
      ? currency(
          (splitAmount * 100 + (tipIncludesTax ? tax : 0)) *
            (totalForTipCalculation / totalTippable),
          {
            fromCents: true,
          }
        ).multiply(amount / 100).value
      : currency(amount, { fromCents: true }).value
    setNum(nextTip)
  }, [
    splitAmount,
    checkin.gratuity,
    totalForTipCalculation,
    totalTippable,
    tax,
    tipIncludesTax,
  ])

  useEffect(() => {
    const displayTotal = currency(splitAmount * 100, { fromCents: true })
      .add(num * 100)
      .add(tax)
      .add(splitFee)
    setDisplayTotal(displayTotal)
  }, [
    num,
    setDisplayTotal,
    splitAmount,
    payments.stripe,
    payments.venue,
    subTotal,
    tax,
  ])

  const handleCurrencyClick = () => {
    setTipOption('Custom')
    setTimeout(() => {
      const { current: element } = currencyInputRef
      const selectionEnd =
        element.theInput.value.length - element.props.suffix.length
      const isNegative =
        (element.theInput.value.match(/-/g) || []).length % 2 === 1
      const selectionStart = element.props.prefix.length + (isNegative ? 1 : 0)
      element.theInput.setSelectionRange(selectionStart, selectionEnd)
    })
  }

  useEffect(() => {
    if (displayTotal) {
      const now = new Date().getTime()
      if (now - lastAnimationTimeTotalDue > 750) {
        lastAnimationTimeTotalDue = now
        if (totalDueRef.current) {
          totalDueRef.current.classList.toggle('animate__heartBeat')
        }
        setTimeout(() => {
          if (totalDueRef.current) {
            totalDueRef.current.classList.toggle('animate__heartBeat')
          }
        }, 750)
      }
    }
  }, [displayTotal])

  const prefillAmount = (portion) => {
    setSelectedPortion(portion)
    portionChange(portion)
    splitAmountChange(
      currency(subTotal * (1 / portion), {
        fromCents: true,
      }).value
    )
  }

  const handleInputChange = (input) => {
    portionChange(1)
    splitAmountChange(input)
  }

  return (
    <CheckPanel
      {...rest}
      css={css`
        position: relative;
      `}
    >
      {!isValidAmount &&
        !(
          tipOption === 'Custom' &&
          checkin.gratuity.amount !== currency(num).intValue
        ) && (
          <TooltipText className="scale-in-bottom">
            {splitAmount === 0
              ? 'Enter the dollar amount, tap the fraction, or select items to pay'
              : `Amount must be between
            ${currency(50, { fromCents: true }).format()} and
            ${currency(subTotal - 50, { fromCents: true }).format()}`}
          </TooltipText>
        )}
      <ItemRow
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-evenly;

          > span {
            font-size: 20px;
          }

          @media (max-width: 360px) {
            > span {
              font-size: 16px;
            }
          }
        `}
      >
        <span>Pay</span>
        <CurrencyInput
          ref={subTotalInputRef}
          css={css`
            &::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              color: #fcac24;
            }

            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            text-align: center;
            color: #fcac24;
            padding: 8px 0;
            cursor: pointer;
            border: 0 transparent;
            border-radius: 8px;
            background-color: #f8f8f8;
            width: 100px;
            outline: 0;
          `}
          thousandSeparator=","
          decimalSeparator="."
          prefix="$"
          allowNegative={false}
          value={splitAmount}
          onChangeEvent={handleInputChange}
          disabled={checkin.checkoutTriggered}
          inputMode="numeric"
          pattern="[0-9]*"
        />
        <span>of</span>
        <span
          css={css`
            font-weight: bold;
          `}
        >
          {currency(subTotal, { fromCents: true }).format()} subtotal
        </span>
      </ItemRow>
      <ItemRow
        css={css`
          > button {
            min-height: 2rem;
            margin: 0;
            margin-top: 5px;
            margin-bottom: 15px;
          }

          > button:not(:last-of-type) {
            margin-right: 8px;
          }
        `}
      >
        {prefillPortions.map((portion) => (
          <Button
            key={portion}
            active
            secondary={portion !== selectedPortion}
            onClick={() => prefillAmount(portion)}
          >
            {portion === 1 ? 'Full' : `1 / ${portion}`}
          </Button>
        ))}
      </ItemRow>
      <TipAmountMenu>
        {defaultTips.map((tip) => {
          return (
            <Amount
              active={tipOption === tip}
              onClick={() => (checkoutTriggered ? null : percentChange(tip))}
              key={tip}
            >
              {`${tip}%`}
            </Amount>
          )
        })}
        <CurrencyInput
          ref={currencyInputRef}
          onClick={handleCurrencyClick}
          css={css`
            ${tipOption === 'Custom' &&
            css`
              && {
                background-color: #feebcb;
                color: #fcac24;
                border-radius: 16px;
                font-weight: bold;
                &:focus {
                  &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: #fcac24;
                  }
                  outline-width: 0;
                }
              }
            `}

            &::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              color: #949eaf;
            }

            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            line-height: 1.43;
            text-align: center;
            color: #949eaf;
            padding: 3px 5px;
            cursor: pointer;
            border: 0 transparent;
            background: none;
            width: 100%;
          `}
          thousandSeparator=","
          decimalSeparator="."
          prefix="$"
          allowNegative={false}
          value={tipOption === 'Custom' ? num : null}
          onChangeEvent={customChange}
          placeholder="Tip"
          disabled={checkin.checkoutTriggered}
          inputMode="numeric"
          pattern="[0-9]*"
        />
      </TipAmountMenu>
      <CheckTotalsContainer>
        <CheckItems>
          <CheckItemRow>
            Tax: <b>{currency(tax, { fromCents: true }).format()}</b>
          </CheckItemRow>
          <CheckItemRow>
            Split Fee: <b>{currency(splitFee, { fromCents: true }).format()}</b>
          </CheckItemRow>
          <CheckItemRow>
            Tip: <b>{currency(num).format()}</b>
          </CheckItemRow>
          {payments?.venue > 0 && (
            <CheckItemRow>
              Paid at {checkin.vendor.name}:
              <b
                css={css`
                  color: green;
                `}
              >
                ({currency(payments.venue, { fromCents: true }).format()})
              </b>
            </CheckItemRow>
          )}
          {payments?.stripe > 0 && (
            <CheckItemRow>
              Paid w/ Outpay:
              <b
                css={css`
                  color: green;
                `}
              >
                (
                {currency(payments.stripe + payments.split, {
                  fromCents: true,
                }).format()}
                )
              </b>
            </CheckItemRow>
          )}
        </CheckItems>
        <TotalContainer>
          Total Due
          <h3 ref={totalDueRef} className="animate__animated">
            {currency(displayTotal).format()}
          </h3>
        </TotalContainer>
      </CheckTotalsContainer>
      {tipOption === 'Custom' && // show accept if editing custom tip
        checkin.gratuity.amount !== currency(num).intValue &&
        !checkin.checkoutTriggered && (
          <Button
            active
            onClick={() => updateGratuity(false, currency(num).intValue)}
          >
            Accept
          </Button>
        )}

      {!checkin.checkoutTriggered &&
        (checkin.gratuity.amount === currency(num).intValue ||
          tipOption !== 'Custom') &&
        actions}
    </CheckPanel>
  )
}

export default TipPanelSplit
