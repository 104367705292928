/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import Header from '../components/Header/Header'
import SvgArrowLeft from '../assets/images/svgr/ArrowLeft'
import AddCreditCard from '../components/Checkout/AddCreditCard'
import getCSSHeight from '../utils/getCSSHeight.js'

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 575px;
  height: ${getCSSHeight()};
`

const SubHeader = styled.h3`
  && {
    font-weight: 400;
  }
`

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const AddPayment = () => {
  const history = useHistory()

  return (
    <Container>
      <Header>
        <ContentContainer>
          <SvgArrowLeft
            css={css`
              margin-right: 8px;
            `}
            onClick={() => history.goBack()}
          />
          <SubHeader>
            Add <b>Payment Method</b>
          </SubHeader>
        </ContentContainer>
      </Header>

      <AddCreditCard />
    </Container>
  )
}

export default AddPayment
