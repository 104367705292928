/** @jsx jsx */
// @flow
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { useState } from 'react'

const FormInput = styled.input`
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  color: #949eaf;

  height: 48px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  outline: none;

  background-color: #f8f8f8;
  border: 0;

  ::placeholder {
    color: #949eaf;
  }
`

const FormContainer = styled.form`
  width: 100%;
`

const EmailForm = ({ emailChange }) => {
  const [email, setEmail] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault()
  }

  const handleEmailChange = (event) => {
    const email = event.target.value
    setEmail(email)
    emailChange(email)
  }

  return (
    <FormContainer onSubmit={handleSubmit}>
      <p
        css={css`
          margin: 0 0 16px 0;
        `}
      >
        Enter email for your receipt
      </p>
      <FormInput
        placeholder="email"
        value={email}
        onChange={handleEmailChange}
      />
    </FormContainer>
  )
}

export default EmailForm
