import React from 'react'
import styled from '@emotion/styled'
import { Button } from './Button'
import error from '../assets/lottie/error.json'
import Lottie from 'react-lottie'

const Container = styled.div`
  background-color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const Fallback = () => {
  const handleRefresh = () => {
    window.location.reload()
  }
  return (
    <Container>
      <h3>We&apos;re sorry</h3>
      <p>An error occured. Please reload the page.</p>
      <Lottie
        options={{
          loop: true,
          animationData: error,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={200}
        width={200}
      />

      <Button onClick={handleRefresh} active>
        Reload
      </Button>
    </Container>
  )
}

export default Fallback
