// @flow
import React from 'react'
import styled from '@emotion/styled'
import SvgClose from '../../assets/images/svgr-converted/Close'

export const CloseButton = styled.button`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  border: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
    path {
      fill: #fff;
    }
  }
`

const ModalCloseButton = ({ onClick, style }) => (
  <CloseButton style={style} onClick={onClick}>
    <SvgClose />
  </CloseButton>
)

export default ModalCloseButton
