import React, { useState } from 'react'
import styled from '@emotion/styled'
import getCSSHeight from '../utils/getCSSHeight.js'
import { Button } from '../components/Button.js'
import { useSelector } from 'react-redux'
import Feedback1 from '../assets/images/feedback1.png'
import Feedback2 from '../assets/images/feedback2.png'
import Feedback3 from '../assets/images/feedback3.png'
import Feedback4 from '../assets/images/feedback4.png'
import Feedback5 from '../assets/images/feedback5.png'
import axios from '../utils/axios'
import { useAlert } from 'react-alert'
import currency from 'currency.js'
import { useHistory } from 'react-router-dom'
const applePrivateRelay = '@privaterelay.appleid.com'

const Container = styled.div`
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${getCSSHeight()};
  padding-top: 100px;
`

const SubmitFeedbackContainer = styled.div`
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${getCSSHeight()};
`

const Heading = styled.h1`
  font-size: 20px;
  font-weight: 300;
`
const SubText = styled.span`
  font-size: 14px;
  font-style: oblique;
  color: #949eaf;
  width: 95%;

  @media (min-width: 400px) {
    width: 75%;
  }
`

const FeedbackContainer = styled.div`
  padding: 30px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-top: 1px solid #e8e8ec;
  border-radius: 10px;
  background: #fff;

  @media (min-width: 400px) {
    padding: 50px;
  }
`

const Fill = styled.span`
  flex: 1;
`

const FeedbackOptions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  margin-top: 40px;
`

const EmojiContainer = styled.span`
  font-size: 32px;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: ${(props) => (props.selected ? '10px solid lightgreen' : 'unset')};
`

const FeedbackInput = styled.textarea`
  border-radius: 6px;
  border: 1px solid #f0f0f0;
  width: 100%;
  background-color: #f8f8f8;
  margin-top: 16px;
  padding: 10px;

  ::placeholder {
    color: #949eaf;
    opacity: 1;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  > button {
    &:last-of-type {
      margin-right: 16px;
      background: #fff;
      color: #949eaf;
      font-weight: 500;
      box-shadow: unset;
      border: 1px solid #f0f0f1;
    }
  }
`

const TopSection = styled.div``

const Card = styled.div`
  border-radius: 20px;
  background-color: #fff;
  padding: 20px;
  width: 95%;
  text-align: center;
`

const TitleText = styled.p`
  font-size: 24px;
  font-weight: bold;
`

const Feedback = () => {
  const { checkin, vendor } = useSelector((state) => state)
  const [feedback, setFeedback] = useState(-1)
  const [feedbackText, setFeedbackText] = useState('')
  const [loading, setLoading] = useState(false)
  const alert = useAlert()
  const history = useHistory()
  const { amount, created, email } = history.location.state || {}
  const date = +created ? new Date(created * 1000) : new Date(created)
  const dateTimeText =
    date.toDateString() !== 'Invalid Date'
      ? `${date.toDateString()} at ${date.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
        })}`
      : null

  const postFeedback = async () => {
    return axios.API.post(`/feedback/receipt`, {
      ...(checkin.checkinId && {
        checkinId: checkin.checkinId,
      }),
      ...(checkin.ticket && {
        ticketId: checkin.ticket.id,
        vendorId: checkin.vendor.id,
        email,
      }),
      feedback,
      feedbackText,
    })
  }

  const submitFeedback = async () => {
    setLoading(true)

    try {
      await postFeedback()
      window.open('/feedback-receipt', '_self')
    } catch (error) {
      setLoading(false)
      alert.show(error.message || error, {
        timeout: 0,
        header: 'Could not submit feedback',
        gif: 'warning',
      })
    }
  }

  const handleDismiss = async () => {
    try {
      await postFeedback()
    } catch (error) {
      console.error(error)
    }
    window.open('/feedback-receipt', '_self')
  }

  const FeedbackSelectionContainer = () => {
    return (
      <>
        <FeedbackOptions>
          <EmojiContainer
            selected={feedback === 1}
            onClick={() => setFeedback(1)}
          >
            {feedback === 1 ? (
              <span role="img" aria-label="1">
                &#x1F621;
              </span>
            ) : (
              <img src={Feedback1} alt="feedback1" />
            )}
          </EmojiContainer>
          <EmojiContainer
            selected={feedback === 2}
            onClick={() => setFeedback(2)}
          >
            {feedback === 2 ? (
              <span role="img" aria-label="2">
                &#x1F615;
              </span>
            ) : (
              <img src={Feedback2} alt="feedback2" />
            )}
          </EmojiContainer>
          <EmojiContainer
            selected={feedback === 3}
            onClick={() => setFeedback(3)}
          >
            {feedback === 3 ? (
              <span role="img" aria-label="3">
                {' '}
                &#x1F610;
              </span>
            ) : (
              <img src={Feedback3} alt="feedback3" />
            )}
          </EmojiContainer>
          <EmojiContainer
            selected={feedback === 4}
            onClick={() => setFeedback(4)}
          >
            {feedback === 4 ? (
              <span role="img" aria-label="4">
                &#x1F603;
              </span>
            ) : (
              <img src={Feedback4} alt="feedback4" />
            )}
          </EmojiContainer>
          <EmojiContainer
            selected={feedback === 5}
            onClick={() => setFeedback(5)}
          >
            {feedback === 5 ? (
              <span role="img" aria-label="5">
                &#x1F601;
              </span>
            ) : (
              <img src={Feedback5} alt="feedback5" />
            )}
          </EmojiContainer>
        </FeedbackOptions>
      </>
    )
  }

  if (feedback !== -1) {
    return (
      <SubmitFeedbackContainer>
        <TopSection>
          <Heading>
            How was <b>the Outpay Service?</b>
          </Heading>
          <FeedbackSelectionContainer />
        </TopSection>

        <Fill />
        <FeedbackContainer>
          <Heading>
            Thanks for <b>sharing!</b>
          </Heading>
          <SubText>
            Care to elaborate? We'd love your feedback to help us improve
            Outpay.
          </SubText>
          <FeedbackInput
            placeholder="Click to start writing..."
            rows="5"
            value={feedbackText}
            onChange={(event) => setFeedbackText(event.target.value)}
          />
          <ButtonContainer>
            <Button
              active={feedback !== -1 && !loading}
              onClick={submitFeedback}
            >
              Send Feedback
            </Button>
            <Button active onClick={handleDismiss}>
              Dismiss
            </Button>
          </ButtonContainer>
        </FeedbackContainer>
      </SubmitFeedbackContainer>
    )
  }

  return (
    <Container>
      <Card>
        {vendor ? (
          <TitleText>
            You paid {currency(amount, { fromCents: true }).format()} to{' '}
            {vendor?.name} with Outpay
          </TitleText>
        ) : (
          <TitleText>
            You paid {currency(amount, { fromCents: true }).format()} with
            Outpay
          </TitleText>
        )}

        {dateTimeText && <SubText>{dateTimeText}</SubText>}
        {email && (
          <>
            <p>We've emailed your receipt to:</p>
            {email && email.includes(applePrivateRelay) ? (
              <SubText>email associated with your Apple ID</SubText>
            ) : (
              <SubText>{email}</SubText>
            )}
          </>
        )}
      </Card>
      <Fill />
      <FeedbackContainer>
        <div>
          <Heading>
            How did you like using <b>Outpay?</b>
          </Heading>
          <FeedbackSelectionContainer />
        </div>
      </FeedbackContainer>
    </Container>
  )
}

export default Feedback
