import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import rootReducer from './reducers'
import { persistReducer } from 'redux-persist'
import * as Sentry from '@sentry/react'

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => {
    const transformedState = {
      ...state,
      patron: {
        ...state.patron,
        token: state.patron.token ? '[FILTERED]' : null,
      },
    }

    return transformedState
  },
})

export default configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false, // added to get rid of serializable warning with redux-persist, can remove if there are side effects
  }),
  enhancers: [sentryReduxEnhancer],
})
