import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  table: null,
  revenueCenter: null,
  server: null,
  check: null,
  action: null,
  sideNavWidth: '0%',
  isChatOpen: false,
  idleCheckoutWithoutItems: false,
  isAutoCheckin: false,
  showOnboarding: true,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateTable(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    updateRevenueCenter(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    updateServer(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    updateCheck(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    updateAction(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    setSideNavWidth(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    setIsChatOpen(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    setIdleCheckoutWithoutItems(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    setIsAutoCheckin(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    setShowOnboarding(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const { updateTable } = appSlice.actions
export const { updateRevenueCenter } = appSlice.actions
export const { updateServer } = appSlice.actions
export const { updateCheck } = appSlice.actions
export const { updateAction } = appSlice.actions
export const { setSideNavWidth } = appSlice.actions
export const { setIsChatOpen } = appSlice.actions
export const { setIdleCheckoutWithoutItems } = appSlice.actions
export const { setIsAutoCheckin } = appSlice.actions
export const { setShowOnboarding } = appSlice.actions

export default appSlice.reducer
