import * as React from 'react'

function SvgCirclechecked(props) {
  return (
    <svg width={24} height={24} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={12} cy={12} r={12} fill="#FCAC24" />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M18 8l-8.25 8L6 12.364"
        />
      </g>
    </svg>
  )
}

export default SvgCirclechecked
