import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 18 15"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      {...props}
    >
      <path
        d="M1 7.414h16M11 13.414l6-6-6-6"
        fill="none"
        stroke="#000"
        strokeWidth={2}
      />
    </svg>
  )
}

export default SvgComponent
