/** @jsx jsx */
// @flow
import { useState, useEffect, useCallback, useRef } from 'react'
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import Cookies from 'js-cookie'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Link, useHistory, useLocation } from 'react-router-dom'
import SvgArrowRight from '../assets/images/svgr/ArrowRight'
import ConfirmLogin from '../components/ConfirmLogin'
import ConfirmOpenCheckModal from '../components/modals/ConfirmOpenCheck'
import ConfirmOpenCheckFooter from '../components/ConfirmOpenCheckFooter'
import MenuIframe from '../components/MenuIframe'
import axios from '../utils/axios'
import { useAlert } from 'react-alert'
import Loader from '../components/Loader/Loader'
import getCSSHeight from '../utils/getCSSHeight'
import Header from '../components/Header/Header'
import Onboarding from '../layouts/onboarding'
import ShareMenuIcon from '../components/ShareMenu'

const headerMargin = 43

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 575px;
`
export const IssueHeader = styled(Link)`
  display: flex;
  justify-content: space-around;
  margin-top: ${headerMargin}px;
  top: 0;
  width: 100%;
  position: fixed;
  padding: 10px 20px;
  align-items: center;
  background-color: #ff4545;
  color: #fff;
  z-index: 1;
  max-width: 575px;
`

const MenuContent = styled.div`
  margin-top: ${headerMargin}px;
  padding-bottom: 140px;
  border-radius: 16px;
  overflow: auto;
  height: ${getCSSHeight(-50)};
  -webkit-overflow-scrolling: touch;

  > img {
    width: 100%;
  }
`

const EmptyMenuContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 130px 5vw;
`

export const ItemRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  p {
    margin: 10px 0;
  }

  &.bordered {
    border-bottom: 1px solid #f0f0f1;
    padding-bottom: 10px;
    opacity: 0.9;
    border-width: 1px;
    transition: opacity border-width 0.6s ease;
  }
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const ReturnToBillLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
`

const ArrowRight = styled(SvgArrowRight)`
  width: 15px;
  height: 15px;
  margin-left: 5px;
`

const TooltipText = styled.span`
  position: absolute;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 6px;
  padding: 5px;
  text-align: center;
  top: 30px;
  right: -12px;
  font-size: 12px;
  width: 110px;
  color: #000;

  ::before {
    content: ' ';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent #000 transparent;
  }

  ::after {
    content: ' ';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
  }
`
const MenuIFrameContainer = styled.div`
  margin-top: ${headerMargin}px;
  overflow: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
`

let timer = null

const Entry = () => {
  const dispatch = useDispatch()
  const { checkin, patron, vendor, app } = useSelector((state) => state)
  const [openCheckModalOpen, setOpenCheckModalOpen] = useState(false)
  const [isScrolling, setIsScrolling] = useState(false)
  const [hideTooltip, setHideTooltip] = useState(false)
  const alert = useAlert()
  const params = useParams()
  const tooltipRef = useRef()
  const history = useHistory()
  const location = useLocation()

  // if vendor id is an int in dev, convert else use uuid string
  const vendorId = +params.vendor_id || params.vendor_id

  const patronLoggedIn = patron.token && Cookies.get('outpay_token')
  const checkinButDifferentVendor = checkin.vendor.id !== vendorId

  const openCheckModal = useCallback(() => {
    if (!checkin.checkinId || checkinButDifferentVendor) {
      setOpenCheckModalOpen(true)
    }
  }, [checkin.checkinId, checkinButDifferentVendor])

  useEffect(() => {
    dispatch({
      type: 'app/setShowOnboarding',
      payload: {
        showOnboarding: !(patronLoggedIn && patron.defaultCard),
      },
    })
  }, [patronLoggedIn, patron.defaultCard, dispatch])

  useEffect(() => {
    let hideTimeout
    setTimeout(() => {
      if (tooltipRef.current) {
        tooltipRef.current.classList.add('scale-out-top')
        hideTimeout = setTimeout(() => {
          setHideTooltip(true)
        }, 1000)
      }
    }, 3500)

    return () => {
      clearTimeout(hideTimeout)
    }
  }, [])

  useEffect(() => {
    const el = document.getElementById('app-container')
    const scrollListener = (event) => {
      if (timer !== null) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        setIsScrolling(false)
      }, 150)
      setIsScrolling(true)
    }
    el.addEventListener('scroll', scrollListener)

    return () => {
      el.removeEventListener('scroll', scrollListener)
    }
  }, [])

  useEffect(() => {
    openCheckModal()

    dispatch({
      type: 'vendor/setVendor',
      payload: {
        menu_url: null,
      },
    })

    axios.API.get(`/public/vendor/${params.vendor_id}`)
      .then(({ data }) => {
        if (data.name) {
          dispatch({
            type: 'vendor/setVendor',
            payload: {
              id: vendorId,
              ...data,
            },
          })
        }
      })
      .catch((error) => {
        alert.show(error.message || error, {
          timeout: 0,
          header: 'Could not get Vendor info.',
          gif: 'warning',
        })
      })
  }, [params.vendor_id, dispatch, alert, openCheckModal, vendorId])

  useEffect(() => {
    if (app.idleCheckoutWithoutItems) {
      alert.show('Open a new check if you still want to order', {
        header: 'Check closed due to inactivity',
        onClose: () => {
          dispatch({
            type: 'app/setIdleCheckoutWithoutItems',
            payload: {
              idleCheckoutWithoutItems: false,
            },
          })
        },
      })
    }
  }, [app.idleCheckoutWithoutItems, alert, dispatch])

  useEffect(() => {
    setTimeout(() => {
      const params = new URLSearchParams(location.search)
      const action = params.get('action')
      const locationId = params.get('location')
      console.log('location id is', locationId)
      if (
        (patronLoggedIn || ['guestBill', 'guestSplitBill'].includes(action)) &&
        app.check
      ) {
        console.warn('pushing here')
        history.push(`/vendor/${vendorId}/checks/${app.check}?action=${action}${locationId ? '&location=' + locationId : ''}`)
      }
    })
  }, [app.check, history, vendorId, patronLoggedIn, location.search])

  if (app.showOnboarding) {
    return <Onboarding patronLoggedIn={patronLoggedIn} />
  }

  return (
    <Container>
      <Header showSideNav>
        <h3>{vendor.name || 'Vendor Not Found'}</h3>
        {checkin.checkinId &&
          checkin.vendor.id === vendorId &&
          !checkin.checkoutTriggered &&
          !isScrolling && (
            <ReturnToBillLink to="/bill">
              <span>View Bill</span>
              <ArrowRight />
              {!hideTooltip && (
                <TooltipText ref={tooltipRef} className="scale-in-top">
                  Click here to go back to your tab
                </TooltipText>
              )}
            </ReturnToBillLink>
          )}
      </Header>
      {checkin?.stripe?.paymentIssue && (
        <IssueHeader to="/bill">Payment Issue</IssueHeader>
      )}
      {!vendor.menu_url && (
        <EmptyMenuContent>
          <Loader />
        </EmptyMenuContent>
      )}
      {vendor.menu_url &&
        (vendor.menu_url.includes('.png') ? (
          <MenuContent onClick={openCheckModal}>
            <img src={vendor.menu_url} alt="menu" />
          </MenuContent>
        ) : (
          <MenuIFrameContainer
            id="menuContainer"
            css={css`
              height: ${checkin.checkinId && patronLoggedIn
                ? getCSSHeight(headerMargin - 86)
                : getCSSHeight(patronLoggedIn ? -135 : -43)};
            `}
          >
            <MenuIframe menuUrl={vendor.menu_url} logoUrl={vendor.logo_url} />
          </MenuIFrameContainer>
        ))}
      {!patronLoggedIn && <ConfirmLogin />}
      {patronLoggedIn && (!checkin.checkinId || checkinButDifferentVendor) && (
        <ConfirmOpenCheckFooter setOpenCheckModalOpen={setOpenCheckModalOpen} />
      )}
      {patron.token &&
        Cookies.get('outpay_token') &&
        (!checkin.checkinId || checkinButDifferentVendor) && (
          <ConfirmOpenCheckModal
            isOpen={openCheckModalOpen}
            setOpenCheckModalOpen={setOpenCheckModalOpen}
          />
        )}
      <ShareMenuIcon />
    </Container>
  )
}

export default Entry
