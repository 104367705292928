/** @jsx jsx */
// @flow
import { useEffect } from 'react'
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CreditCardSelection from './CreditCardSelection'
import axios from '../../utils/axios'
import { useAlert } from 'react-alert'
import getCSSHeight from '../../utils/getCSSHeight'

const Panel = styled.div`
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #31354c;
  width: 100%;
  margin-top: 50px;
  height: ${getCSSHeight(-50)};
  padding: 20px 20px 24px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
`

const NoPaymentMethodMessage = styled.p`
  text-align: center;
  color: #fff;
  font-weight: bold;
`
type SelectCreditCardProps = {
  setToDelete: Function,
}

const SelectCreditCard = ({ setToDelete }: SelectCreditCardProps) => {
  const dispatch = useDispatch()
  const { patron, checkin, vendor } = useSelector((state) => state)
  const alert = useAlert()
  const history = useHistory()

  useEffect(() => {
    axios.API.get(`/cc/patron/${patron.id}`)
      .then(({ data }) => {
        const currentDefaultCard = data.cards.find((card) => {
          return card.id === data.default
        })

        dispatch({
          type: 'patron/updatePatron',
          payload: {
            defaultCard: currentDefaultCard,
            cards: data.cards,
          },
        })
      })
      .catch((error) => {
        alert.show(error.message || error, {
          timeout: 0,
          header: 'Could not get credit cards.',
          gif: 'warning',
        })
      })
  }, [patron.id, patron.defaultCard.id, dispatch, alert])

  const setDefaultCCForPatron = ({ card }) => {
    axios.API.put(`/cc/patron/${patron.id}`, {
      card_id: card.id,
    })
      .then(({ data }) => {
        dispatch({
          type: 'patron/updatePatron',
          payload: {
            defaultCard: card,
          },
        })
        window.scrollTo(0, 0)
        history.push(checkin ? '/bill' : `/vendor/${vendor.id}`)
      })
      .catch((error) => {
        alert.show(error.message || error, {
          timeout: 0,
          header: 'Could not set default card.',
          gif: 'warning',
        })
      })
  }

  return (
    <Panel>
      <CardsContainer>
        {patron.cards &&
          patron.cards.map((card, index) => {
            return (
              <CreditCardSelection
                key={card.id}
                index={index}
                active={patron.defaultCard?.id === card.id}
                card={card}
                handleClick={setDefaultCCForPatron}
                setToDelete={setToDelete}
              />
            )
          })}
        {!patron.cards && (
          <NoPaymentMethodMessage>
            Please add a payment option
          </NoPaymentMethodMessage>
        )}
      </CardsContainer>
    </Panel>
  )
}

export default SelectCreditCard
