/** @jsx jsx */
// @flow
import axios from '../../utils/axios'
import { useState } from 'react'
import { jsx } from '@emotion/core'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useStripe } from '@stripe/react-stripe-js'
import { useAlert } from 'react-alert'
import StripeCreditCardForm from './StripeCreditCardForm'

const AddCreditCard = ({ onSuccess }) => {
  const stripe = useStripe()
  const alert = useAlert()
  const { patron, vendor, checkin } = useSelector((state) => state)
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  const handleCard = async ({ cardElement }) => {
    setIsLoading(true)
    try {
      const cardToken = await stripe.createToken(cardElement).then((res) => {
        if (res.error) {
          throw new Error(res.error.message)
        } else {
          return res.token.id
        }
      })
      await axios.API.post(`/cc/patron/${patron.id}`, {
        token: cardToken,
      }).then((res) => {
        setIsLoading(false)

        if (onSuccess) {
          onSuccess(res.data)
        } else {
          dispatch({
            type: 'patron/updatePatron',
            payload: {
              defaultCard: res.data,
              cards: [...patron.cards, res.data],
            },
          })
          history.push(checkin ? '/bill' : `/vendor/${vendor.id}`)
        }
      })
    } catch (error) {
      setIsLoading(false)
      alert.show(error.message || error, {
        timeout: 0,
        header: 'Could not add payment.',
        gif: 'warning',
      })
    }
  }

  return <StripeCreditCardForm onCard={handleCard} isLoading={isLoading} />
}

export default AddCreditCard
