/** @jsx jsx */
// @flow
import { useState, useEffect, useCallback } from 'react'
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Header from '../components/Header/Header'
import { BillItemSplit } from '../components/BillItemSplit'
import axios from '../utils/axios'
import { useStripe } from '@stripe/react-stripe-js'
import TipPanelSplit from '../components/Tip/TipPanelSplit.js'
import StripeCreditCardForm from '../components/Checkout/StripeCreditCardForm'
import { Button } from '../components/Button'
import PaymentRequestWithStripe from '../components/Checkout/PaymentRequestWithStripe'
import { useAlert } from 'react-alert'
import OutpayBackground from '../components/OutpayBackground'
import ModalCloseButton from '../components/modals/ModalCloseButton'
import getCSSHeight from '../utils/getCSSHeight.js'

const BillContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 50px 20px;
  text-align: center;
  height: calc(100vh - 300px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  align-items: center;
  > button {
    width: 100%;
  }
`

export const BillPageContainer = styled.div`
  width: 100%;
  height: ${getCSSHeight()};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const SubText = styled.p`
  font-size: 14px;
  color: #a9a9a9;
`

const SplitBill = () => {
  const { checkin } = useSelector((state) => state)
  const params = useParams()
  const dispatch = useDispatch()
  const stripe = useStripe()
  const history = useHistory()
  const alert = useAlert()

  const [displayTotal, setDisplayTotal] = useState()
  const [display, setDisplay] = useState(1)
  const [initialSplitAmount, setInitialSplitAmount] = useState(0)
  const [initialTax, setInitialTax] = useState(0)
  const [splitInfo, setSplitInfo] = useState({})
  const [isPaying, setIsPaying] = useState(false)
  const [error, setError] = useState('')
  const [selectedItems, setSelectItems] = useState({})
  const [selectedTotal, setSelectedTotal] = useState(-1)
  const [resetItems, setResetItems] = useState(false)
  const [fetchSuccess, setFetchSuccess] = useState(false)

  const { ticket, vendor } = checkin
  const { items = [], totals } = ticket || {}
  console.log('TICKET IN MYBILL', checkin)
  console.log('INITIAL SPLIT AMOUNT', initialSplitAmount)
  useEffect(() => {
    if (+params.id === -1) {
      // for people going to thank you screen without state
      setError('invalid id')
      return
    }

    dispatch({
      type: 'checkin/closeCheckin',
    })

    const fetchData = async () => {
      setFetchSuccess(false)
      try {
        const { data } = await axios.API.get(`/checkin/${params.id}/split`)
        dispatch({
          type: 'checkin/updateCheckin',
          payload: {
            ...data,
            gratuity: {
              isPercent: true,
              amount: 20,
            },
          },
        })
        if (data.vendor) {
          dispatch({
            type: 'vendor/setVendor',
            payload: data.vendor,
          })
        }
        setFetchSuccess(true)
      } catch (error) {
        console.error(error)
        setError(error)
      }
    }
    fetchData()
  }, [dispatch, params.id])

  const submitPayment = useCallback(
    async ({ firstName, lastName, email, paymentMethod }) => {
      try {
        const { data } = await axios.API.post(`/checkin/${params.id}/split`, {
          subTotal: splitInfo.amount,
          tax: splitInfo.tax,
          tip: splitInfo.tip,
          firstName: firstName,
          lastName: lastName,
          email: email,
        })
        console.log(data)

        const { paymentIntent, error } = await stripe.confirmCardPayment(
          data.client_secret,
          {
            payment_method: paymentMethod,
          }
        )

        if (error) {
          throw error
        }

        console.log(paymentIntent)

        history.push('/thank-you', {
          amount: data.amount,
          created: data.created,
          email: data.metadata.email,
          vendor,
        })
      } catch (error) {
        console.error(error)
        if (error.data?.checkinId) {
          dispatch({
            type: 'checkin/updateCheckin',
            payload: error.data,
          })
          const subTotalRemaining =
            totals.subTotal -
            (error.data.ticket.splitTotal.subTotal +
              error.data.ticket.payments.venue)
          const nextTax = Math.round(
            totals.tax * (subTotalRemaining / totals.subTotal)
          )
          const remainingTax =
            totals.tax - (error.data.ticket.splitTotal.tax + nextTax)
          const addCents =
            remainingTax > 0 && remainingTax < 3 ? remainingTax : 0
          setInitialTax(nextTax + addCents)
          setInitialSplitAmount(subTotalRemaining / 100)
          setDisplay(1)
        }
        throw error
      }
    },
    [history, params.id, splitInfo, stripe, vendor, dispatch, totals]
  )

  const handleCard = useCallback(
    async ({ cardElement, firstName, lastName, email }) => {
      try {
        setIsPaying(true)
        await submitPayment({
          firstName,
          lastName,
          email,
          paymentMethod: {
            card: cardElement,
          },
        })
      } catch (error) {
        console.error(error)
        setIsPaying(false)
        alert.show(error.message || error, {
          timeout: 0,
          header: 'Submit Payment Error',
          gif: 'warning',
        })
      }
    },
    [submitPayment, alert]
  )

  const handleSplitInfo = useCallback((info) => {
    setSplitInfo(info)
  }, [])

  const handlePaymentMethod = useCallback(
    async (event) => {
      console.log(event)
      try {
        await submitPayment({
          firstName: event.payerName.split(' ')[0],
          lastName: event.payerName.split(' ')[1],
          email: event.payerEmail,
          paymentMethod: event.paymentMethod.id,
        })

        event.complete('success')
      } catch (error) {
        console.error(error)
        event.complete('fail')
        alert.show(error.message || error, {
          timeout: 0,
          header: 'Submit Payment Error',
          gif: 'warning',
        })
      }
    },
    [submitPayment, alert]
  )

  const handleSelect = (item, amount) => {
    const updatedSelectedItems = {
      ...selectedItems,
    }
    updatedSelectedItems[item.id] = item.pricePerUnit * amount
    const keys = Object.keys(updatedSelectedItems)

    setSelectedTotal(
      keys.reduce((acc, curr) => acc + updatedSelectedItems[curr], 0)
    )
    setSelectItems(updatedSelectedItems)
  }

  const resetQuantities = useCallback(() => {
    const updatedSelectedItems = {}
    for (const item of items) {
      updatedSelectedItems[item.id] = item.pricePerUnit * item.quantity
    }
    const keys = Object.keys(updatedSelectedItems)
    setSelectedTotal(
      keys.reduce((acc, curr) => acc + updatedSelectedItems[curr], 0)
    )
    setSelectItems(updatedSelectedItems)
  }, [items])

  const handlePortionChange = (portion) => {
    resetQuantities()
    setResetItems(true)
    setSelectedTotal(-1)
    setSelectItems({})
    setTimeout(() => {
      setResetItems(false)
    })
  }

  if (error) {
    return (
      <OutpayBackground>
        <p>
          <b>Split bill not found or already closed.</b>
        </p>
        <SubText>
          To open another split bill scan the QR code with your camera
        </SubText>
      </OutpayBackground>
    )
  }

  return (
    <BillPageContainer>
      <Header>
        <h3>
          My <b>Bill</b>
        </h3>
      </Header>
      {items?.length > 0 && (
        <BillContentContainer>
          {items.map((item) => {
            return (
              <BillItemSplit
                item={item}
                key={item.id}
                reset={resetItems}
                handleSelect={handleSelect}
              />
            )
          })}
        </BillContentContainer>
      )}
      {display === 1 && fetchSuccess && totals && (
        <TipPanelSplit
          onSplitInfo={handleSplitInfo}
          displayTotal={displayTotal}
          selectedTotal={selectedTotal}
          initialSplitAmount={initialSplitAmount}
          initialTax={initialTax}
          setDisplayTotal={setDisplayTotal}
          portionChange={handlePortionChange}
          actions={
            ((splitInfo.amount >= 50 &&
              splitInfo.amount <= totals.subTotal - 50) ||
              splitInfo.amount === totals.subTotal) && (
              <ButtonsContainer>
                <div>
                  <PaymentRequestWithStripe
                    label={'Bill'}
                    amount={
                      splitInfo.amount +
                      splitInfo.tax +
                      splitInfo.tip +
                      splitInfo.splitFee
                    }
                    onPaymentMethod={handlePaymentMethod}
                    onCancel={(event) => console.log('CANCEL EVENT', event)}
                  />
                </div>
                <Button
                  css={css`
                    @media (max-width: 360px) {
                      font-size: 14px;
                    }
                  `}
                  active={
                    (splitInfo.amount >= 50 &&
                      splitInfo.amount <= totals.subTotal - 50) ||
                    splitInfo.amount === totals.subTotal
                  }
                  onClick={() => setDisplay(2)}
                >
                  Add Credit Card
                </Button>
              </ButtonsContainer>
            )
          }
        />
      )}
      {display === 2 && (
        <div
          css={css`
            position: relative;
          `}
        >
          <ModalCloseButton
            style={{
              top: '-60px',
              right: '10px',
            }}
            onClick={() => setDisplay(1)}
          />
          <StripeCreditCardForm
            isPayment
            isLoading={isPaying}
            amount={displayTotal.format()}
            onCard={handleCard}
          />
        </div>
      )}
    </BillPageContainer>
  )
}

export default SplitBill
