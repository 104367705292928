import React, { useEffect } from 'react'
import styled from '@emotion/styled'

import MenuIframe from '../components/MenuIframe'
import Loader from '../components/Loader/Loader'
import getCSSHeight from '../utils/getCSSHeight'
import { useSelector, useDispatch } from 'react-redux'
import axios from '../utils/axios'
import { useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'
import ShareMenuIcon from '../components/ShareMenu'

const EmptyMenuContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 130px 5vw;
`

const MenuContent = styled.div`
  border-radius: 16px;
  overflow: auto;
  height: ${getCSSHeight()};
  -webkit-overflow-scrolling: touch;

  > img {
    width: 100%;
  }
`

const MenuIFrameContainer = styled.div`
  height: ${getCSSHeight()};
  overflow: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
`

const Menu = () => {
  const dispatch = useDispatch()
  const alert = useAlert()

  const { vendor } = useSelector((state) => state)
  const params = useParams()
  // if vendor id is an int in dev, convert else use uuid string
  const vendorId = +params.vendor_id || params.vendor_id
  useEffect(() => {
    dispatch({
      type: 'vendor/setVendor',
      payload: {
        menu_url: null,
      },
    })

    axios.API.get(`/public/vendor/${params.vendor_id}`)
      .then(({ data }) => {
        if (data.name) {
          dispatch({
            type: 'vendor/setVendor',
            payload: {
              id: vendorId,
              ...data,
            },
          })
        }
      })
      .catch((error) => {
        alert.show(error.message || error, {
          timeout: 0,
          header: 'Could not get Vendor info.',
          gif: 'warning',
        })
      })
  }, [params.vendor_id, dispatch, alert, vendorId])

  return (
    <>
      {!vendor.menu_url && (
        <EmptyMenuContent>
          <Loader />
        </EmptyMenuContent>
      )}
      {vendor.menu_url &&
        (vendor.menu_url.includes('.png') ? (
          <MenuContent>
            <img src={vendor.menu_url} alt="menu" />
          </MenuContent>
        ) : vendor.menu_url.includes('imenupro.com') ? (
          window.location.replace(vendor.menu_url.replace('!', '+'))
        ): (
          <MenuIFrameContainer id="menuContainer">
            <MenuIframe menuUrl={vendor.menu_url} logoUrl={vendor.logo_url} />
          </MenuIFrameContainer>
        ))}
      <ShareMenuIcon />
    </>
  )
}

export default Menu
