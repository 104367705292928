/** @jsx jsx */
// @flow
import { Fragment, useEffect, useState } from 'react'
import { jsx } from '@emotion/core'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import CurvedModalContainer from './CurvedModal'
import ModalContainer from '../Modal'
import ModalCloseButton from './ModalCloseButton'
import axios from '../../utils/axios'
const QRCode = require('qrcode.react')

const QRCodeContainer = styled.div`
  margin: 16px 0;
`

const Title = styled.p`
  margin: 8px 0;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
`

const Text = styled.p`
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  color: #a9a9a9;
`

const SplitBillQRModal = ({ isOpen, setShowSplitBillQRModal }) => {
  const { checkin } = useSelector((state) => state)
  const [splitBillURL, setSplitBillURL] = useState('')

  useEffect(() => {
    if (isOpen && !splitBillURL) {
      axios.API.put(`/checkin/${checkin.checkinId}/split`)
      setSplitBillURL(`${document.location.origin}/split/${checkin.checkinId}`)
    }
  }, [isOpen, checkin.checkinId, splitBillURL])

  return (
    <ModalContainer
      isOpen={isOpen}
      onRequestClose={() => setShowSplitBillQRModal(false)}
    >
      <Fragment>
        <ModalCloseButton onClick={() => setShowSplitBillQRModal(false)} />
        <CurvedModalContainer isOpen={isOpen}>
          <Title>Have your friends scan to pay their share</Title>
          <QRCodeContainer>
            <QRCode value={splitBillURL} />
          </QRCodeContainer>

          <Text>Each payer will be charged a $0.30 split fee.</Text>
        </CurvedModalContainer>
      </Fragment>
    </ModalContainer>
  )
}

export default SplitBillQRModal
