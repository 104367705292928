import * as React from 'react'

function SvgMastercard(props) {
  return (
    <svg width={43} height={30} {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          stroke="#E6E7E8"
          strokeWidth={0.652}
          d="M35.762.326H6.93a6.59 6.59 0 00-4.67 1.932A6.575 6.575 0 00.327 6.92v16.16c0 1.82.74 3.47 1.934 4.662a6.588 6.588 0 004.67 1.932h28.832a6.59 6.59 0 004.67-1.932 6.568 6.568 0 001.934-4.662V6.92c0-1.82-.739-3.47-1.934-4.662a6.586 6.586 0 00-4.67-1.932z"
        />
        <path fill="#FF5F00" d="M18.037 9.473h6.85v11.033h-6.85z" />
        <path
          fill="#EB001B"
          d="M18.743 14.991a6.969 6.969 0 012.717-5.516c-2.917-2.261-7.106-1.933-9.622.756a6.945 6.945 0 000 9.52c2.517 2.689 6.706 3.018 9.623.757a6.97 6.97 0 01-2.717-5.517z"
        />
        <path
          fill="#F79E1B"
          fillRule="nonzero"
          d="M32.306 19.619v-.458h.109v-.095h-.26v.095h.103v.458h.048zm.502 0v-.553h-.078l-.092.395-.09-.395h-.08v.553h.057V19.2l.085.36h.059l.085-.36v.42h.054z"
        />
        <path
          fill="#F79E1B"
          d="M32.972 14.991c0 2.686-1.555 5.136-4.006 6.31a7.194 7.194 0 01-7.506-.795 6.98 6.98 0 002.719-5.516 6.98 6.98 0 00-2.719-5.517 7.194 7.194 0 017.506-.795c2.45 1.174 4.006 3.624 4.006 6.31v.003z"
        />
      </g>
    </svg>
  )
}

export default SvgMastercard
