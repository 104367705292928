import * as React from 'react'

function SvgMinus(props) {
  return (
    <svg viewBox="0 0 42 42" {...props}>
      <path d="M0 20h42v2H0z" />
    </svg>
  )
}

export default SvgMinus
