import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={31} height={44} viewBox="0 0 31 44" {...props}>
      <title>{'Apple Login'}</title>
      <g fill="none" fillRule="evenodd">
        <path fill="#000" d="M0 0h31v44H0z" />
        <path
          d="M15.71 14.885c.858 0 1.933-.58 2.573-1.353.58-.7 1.002-1.68 1.002-2.658a1.79 1.79 0 00-.036-.374c-.954.036-2.102.64-2.79 1.45-.544.615-1.039 1.582-1.039 2.572 0 .145.024.29.036.338.06.013.157.025.254.025zM12.69 29.5c1.172 0 1.691-.785 3.153-.785 1.486 0 1.812.76 3.116.76 1.28 0 2.138-1.183 2.947-2.342.906-1.33 1.28-2.634 1.305-2.694-.085-.024-2.537-1.027-2.537-3.841 0-2.44 1.933-3.54 2.042-3.624-1.28-1.836-3.225-1.884-3.757-1.884-1.437 0-2.609.87-3.346.87-.797 0-1.848-.822-3.092-.822-2.367 0-4.771 1.957-4.771 5.653 0 2.295.894 4.723 1.993 6.293.942 1.329 1.764 2.416 2.947 2.416z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
