/** @jsx jsx */
// @flow
import { useState } from 'react'
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import axios from '../utils/axios'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from './entry.js'
import LogoutButton from '../components/Logout/LogoutButton'
import SupportButton from '../components/Support/SupportButton'
import { Button } from '../components/Button'
import { useAlert } from 'react-alert'
import showAlert from '../utils/alert'
import Cookies from 'js-cookie'

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 15px 30px;
`

const AdminPage = () => {
  const { patron } = useSelector((state) => state)
  const history = useHistory()
  const dispatch = useDispatch()
  const alert = useAlert()
  const [alertCode, setAlertCode] = useState('post_ticket_fail')
  const handleLogout = () => {
    history.push('/')
  }
  // useEffect(() => {
  //   console.log('ALERT CODE', alertCode)
  //   if (alertCode) {
  //     alert.show(alertCode.message, {
  //       timeout: 2500,
  //       onOpen: () => {
  //         dispatch({
  //           type: 'alert/clearAlert',
  //         })
  //       },
  //     })
  //   }
  // }, [alertCode])
  const mockAlert = () => {
    console.log('mocking alert', alertCode)
    // alert.show(alertCode, {
    //   timeout: 2500,
    //   header: 'PASSED THRU',
    //   onOpen: () => {
    //     dispatch({
    //       type: 'alert/clearAlert',
    //     })
    //   },
    // })
    showAlert(alert, alertCode)
  }

  const alerts = [
    'post_ticket_fail',
    'preauth_fail', // check for payment_unresolved and checkoutTriggered
    'discount_fail',
    'payment_fail',
    'closed_on_pos',
    'ticket_not_found',
    'venue_offline',
    'closed_by_error',
    'already_paid',
    // 'ticket_open',
    // 'ticket_updated',
    // 'ticket_closed',
    // 'venue_online',
  ]

  const clearData = async (item) => {
    await axios.API.put(`patron/${patron.id}/clear/${item}`)
    dispatch({
      type: 'alerts/clearAlerts',
    })
    dispatch({
      type: 'checkin/closeCheckin',
    })
    dispatch({
      type: 'vendor/clearVendor',
    })
    dispatch({
      type: 'patron/logoutPatron',
    })
    Cookies.remove('outpay_token')
    history.push('/')
    return window.location.reload()
  }

  return (
    <Container>
      <h3>Admin Panel</h3>
      {patron.id && (
        <PageContent>
          <LogoutButton handleLogout={handleLogout} />
          <Button active onClick={() => clearData('checkins')}>
            Clear Checkins
          </Button>
          <Button active onClick={() => clearData('accounts')}>
            Clear Apple/Google Ids
          </Button>
          <Button active onClick={() => clearData('all')}>
            Clear All
          </Button>
          <Button active onClick={() => history.push(`/select-payment`)}>
            Select Payment
          </Button>
        </PageContent>
      )}
      <PageContent>
        <SupportButton />
        <select
          value={alertCode}
          onChange={(e) => setAlertCode(e.target.value)}
        >
          {alerts.map((current, index) => {
            return (
              <option value={current} key={index}>
                {current}
              </option>
            )
          })}
        </select>
        <Button active onClick={mockAlert}>
          Mock Alert
        </Button>
      </PageContent>
    </Container>
  )
}

export default AdminPage
