/** @jsx jsx */
// @flow
import { jsx } from '@emotion/core'
import { useState, useEffect, Fragment } from 'react'
import { isSafari } from 'react-device-detect'
import { useStripe } from '@stripe/react-stripe-js'

const PaymentRequestWithStripe = ({
  label,
  amount,
  onPaymentMethod,
  onCancel,
  onPaymentRequest,
}) => {
  const stripe = useStripe()
  const [paymentRequest, setPaymentRequest] = useState(null)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Bill',
        amount: 0,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    })

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {
      console.log('Can make payment result', result)

      const canMakePayment = result || (isSafari && result?.applePay)

      if (canMakePayment) {
        setPaymentRequest(pr)
      }

      if (onPaymentRequest) {
        onPaymentRequest(canMakePayment ? pr : null)
      }
    })
  }, [stripe, onPaymentRequest])

  useEffect(() => {
    if (paymentRequest) {
      if (isSafari) {
        document.getElementById('payment-request-button-google').style.display =
          'none'
        const elements = stripe.elements()
        const prButton = elements.create('paymentRequestButton', {
          paymentRequest: paymentRequest,
          style: {
            paymentRequestButton: {
              height: '50px',
            },
          },
        })
        prButton.mount('#payment-request-button')
      } else {
        document.getElementById('payment-request-button').style.display = 'none'
        const paymentsClient = new window.google.payments.api.PaymentsClient()
        const button = paymentsClient.createButton({
          buttonType: 'short',
          buttonSizeMode: 'fill',
          onClick: () => paymentRequest.show(),
        })
        document
          .getElementById('payment-request-button-google')
          .appendChild(button)
      }
    }
  }, [paymentRequest, stripe, onPaymentRequest])

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.update({
        total: {
          label: label,
          amount: amount,
        },
      })
    }
  }, [paymentRequest, label, amount])

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.off('paymentmethod')
      paymentRequest.off('cancel')

      paymentRequest.on('paymentmethod', onPaymentMethod).on('cancel', onCancel)
    }
  }, [paymentRequest, onPaymentMethod, onCancel])

  if (paymentRequest) {
    return (
      <Fragment>
        <div id="payment-request-button"></div>
        <div
          id="payment-request-button-google"
          style={{ height: '50px' }}
        ></div>
      </Fragment>
    )
  } else {
    return null
  }
}

export default PaymentRequestWithStripe
