import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: null,
  name: null,
  menu_url: null,
  online: true,
}

const vendorSlice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    setVendor(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    clearVendor() {
      return initialState
    },
  },
})

export const { setVendor } = vendorSlice.actions

export default vendorSlice.reducer
