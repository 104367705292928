/** @jsx jsx */
// @flow
import { Fragment } from 'react'
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CurvedModalContainer from './CurvedModal'
import { Button } from '../Button'
import ModalContainer from '../Modal'
import { ButtonsContainer } from '../../layouts/bill'
import { Divider } from './ConfirmOpenCheck'
import ModalCloseButton from './ModalCloseButton'

const ContentHeader = styled.h3`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  margin-top: 0;
`

const UberButton = styled(Button)`
  && {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.32);
    background-color: #000000;
    color: #fff;
  }
`

const NoThanksButton = styled(Button)`
  background: none;
  color: #949eaf;
  text-align: center;
`

const UberModal = ({ isOpen, setShowUberModal, data }) => {
  const history = useHistory()

  const { vendor, checkin, patron } = useSelector((state) => state)
  const { ticket } = checkin || {}

  const handleNoThanksClick = () => {
    setShowUberModal(false)
    history.push(`/thank-you`, {
      ...(data && {
        amount: data.amount,
        created: data.created,
        email: data.metadata.email,
        vendor,
      }),
      ...(!data && {
        amount: ticket.payments.stripe,
        created: checkin.createdAt,
        email: patron.email,
        vendor,
      }),
    })
  }

  const handleUberClick = () => {
    window.open(
      `https://m.uber.com/ul?action=setPickup&pickup[latitude]=${vendor.latitude}&pickup[longitude]=${vendor.longitude}&pickup[nickname]=${vendor.name}`
    )
  }

  return (
    <ModalContainer isOpen={isOpen} onRequestClose={handleNoThanksClick}>
      <Fragment>
        <ModalCloseButton onClick={handleNoThanksClick} />
        <CurvedModalContainer isOpen={isOpen}>
          <ContentHeader>You&apos;re All Set</ContentHeader>
          <p
            css={css`
              margin-bottom: 0;
            `}
          >
            Get home safely by hailing Uber.
          </p>
          <Divider />
          <ButtonsContainer
            css={css`
              grid-template-columns: 1fr;
              button {
                margin: 0;
              }
            `}
          >
            <NoThanksButton active onClick={handleNoThanksClick}>
              No thanks, I got it covered.
            </NoThanksButton>
            <UberButton active onClick={handleUberClick}>
              Request Uber
            </UberButton>
          </ButtonsContainer>
        </CurvedModalContainer>
      </Fragment>
    </ModalContainer>
  )
}

export default UberModal
