import { createSlice } from '@reduxjs/toolkit'

const initialState = []

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    addAlert(state, action) {
      console.log('dispatch alert')
      return [...state, action.payload]
    },
    clearAlert(state) {
      state.shift()
      return state
    },
    clearAlerts() {
      return initialState
    },
  },
})

export const { addAlert, clearFirst, clearAlerts } = alertsSlice.actions

export default alertsSlice.reducer
