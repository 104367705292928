/** @jsx jsx */
// @flow
import { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { ItemRow } from '../../layouts/entry.js'
import { Button } from '../Button'
import CurrencyInput from 'react-currency-input'
import currency from 'currency.js'

// Fix autofocus issues with CurrencyInput
// on iOS it will still auto focus even if autoFocus=false
// see https://github.com/jsillitoe/react-currency-input/issues/90
let componentDidMount_super = CurrencyInput.prototype.componentDidMount
CurrencyInput.prototype.componentDidMount = function () {
  if (!this.props.autoFocus) {
    let setSelectionRange_super = this.theInput.setSelectionRange
    this.theInput.setSelectionRange = () => {}
    componentDidMount_super.call(this, ...arguments)
    this.theInput.setSelectionRange = setSelectionRange_super
  } else {
    componentDidMount_super.call(this, ...arguments)
  }
}
let componentDidUpdate_super = CurrencyInput.prototype.componentDidUpdate
CurrencyInput.prototype.componentDidUpdate = function () {
  if (!this.props.autoFocus) {
    let setSelectionRange_super = this.theInput.setSelectionRange
    this.theInput.setSelectionRange = () => {}
    componentDidUpdate_super.call(this, ...arguments)
    this.theInput.setSelectionRange = setSelectionRange_super
  } else {
    componentDidMount_super.call(this, ...arguments)
  }
}

const CheckPanel = styled.div`
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  box-shadow: 0 -8px 24px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  max-width: 575px;
`

const TipAmountMenu = styled.div`
  min-height: 32px;
  border-radius: 16px;
  border: solid 1px rgba(148, 158, 175, 0.16);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 15px 0;
  width: 100%;
`

const Amount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  color: #949eaf;
  padding: 3px 25px;
  cursor: pointer;

  ${(p) =>
    p.active &&
    css`
      background-color: #feebcb;
      color: #fcac24;
      border-radius: 16px;
      font-weight: bold;
    `}
`

const CheckTotalsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin-bottom: 15px;
`

const CheckItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #000000;
  width: 100%;
  padding-right: 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.04);
`

const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  padding-right: 10px;
  h3 {
    margin: 0;
  }
`

const CheckItemRow = styled(ItemRow)`
  margin: 2px 0;
`

const WrongCheckContainer = styled.div`
  text-align: center;
  > p {
    margin: 8px 0;
  }
`

const CheckSubtotalRow = styled.div`
  background-color: #e4edfa;
  padding: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  p {
    margin: 10px 0;
  }
`

const CheckPanelBottomContainer = styled.div`
  padding: 12px 12px 0;
`

let lastAnimationTimeTotalDue = new Date().getTime()

const TipPanelGuest = ({
  displayTotal,
  setDisplayTotal,
  actions,
  onSplitInfo,
  ...rest
}) => {
  const currencyInputRef = useRef()
  const totalDueRef = useRef()
  // default tip options
  const defaultTips = [18, 20, 25]
  const { checkin } = useSelector((state) => state)
  const dispatch = useDispatch()

  const {
    checkoutTriggered,
    ticket: {
      discount,
      totals: { tax: totalTax, subTotal, totalForTipCalculation },
      payments,
      serviceCharges,
    },
  } = checkin
  const [num, setNum] = useState(0)
  const [tax, setTax] = useState(0)
  const [splitAmount, setSplitAmount] = useState(0)

  const [tipOption, setTipOption] = useState(20)
  const splitAmountChange = useCallback(
    (amount) => {
      setTax(
        currency(totalTax, {
          fromCents: true,
        }).multiply(((amount * 100) / (subTotal + discount)) * 100).value || 0
      )
      setSplitAmount(amount)
    },
    [totalTax, subTotal, discount]
  )

  const prefillAmount = useCallback(
    (portion) => {
      splitAmountChange(
        currency((subTotal + discount) * (1 / portion), {
          fromCents: true,
        }).value
      )
    },
    [splitAmountChange, subTotal, discount]
  )

  useEffect(() => {
    prefillAmount(1)
  }, [prefillAmount])

  const updateGratuity = (isPercent, amount) => {
    setTimeout(() => {
      dispatch({
        type: 'checkin/updateCheckin',
        payload: {
          gratuity: {
            isPercent,
            amount,
          },
        },
      })
    }, 100)
  }

  const percentChange = (percent) => {
    const nextTip = currency(totalForTipCalculation, {
      fromCents: true,
    }).multiply(percent / 100).value
    setTipOption(percent)
    setNum(nextTip)
    updateGratuity(true, percent)
  }

  const customChange = (input) => {
    const nextTip = parseFloat(input.target.value.replace('$', '')) || 0
    setNum(nextTip)
  }

  useEffect(() => {
    onSplitInfo({
      amount: currency(splitAmount).multiply(100).value,
      tax: Math.round(tax),
      tip: currency(num).multiply(100).value,
    })
  }, [onSplitInfo, splitAmount, tax, num])

  // recalculate tip and total if subtotal changes
  useEffect(() => {
    const { isPercent, amount } = checkin.gratuity
    const nextTip = isPercent
      ? currency(totalForTipCalculation, { fromCents: true }).multiply(
          amount / 100
        ).value
      : currency(amount, { fromCents: true }).value

    setNum(nextTip)
  }, [splitAmount, checkin.gratuity, totalForTipCalculation])

  useEffect(() => {
    const displayTotal = currency(splitAmount * 100, { fromCents: true })
      .add(num * 100)
      .add(tax)
      .add(serviceCharges)
      .subtract(discount)
      .subtract(payments.venue)
      .subtract(payments.stripe)
    setDisplayTotal(displayTotal)
  }, [
    num,
    setDisplayTotal,
    splitAmount,
    payments.stripe,
    payments.venue,
    serviceCharges,
    discount,
    subTotal,
    tax,
  ])

  const handleCurrencyClick = () => {
    setTipOption('Custom')
    setTimeout(() => {
      const { current: element } = currencyInputRef
      const selectionEnd =
        element.theInput.value.length - element.props.suffix.length
      const isNegative =
        (element.theInput.value.match(/-/g) || []).length % 2 === 1
      const selectionStart = element.props.prefix.length + (isNegative ? 1 : 0)
      element.theInput.setSelectionRange(selectionStart, selectionEnd)
    })
  }

  useEffect(() => {
    if (displayTotal) {
      const now = new Date().getTime()
      if (now - lastAnimationTimeTotalDue > 750) {
        lastAnimationTimeTotalDue = now
        if (totalDueRef.current) {
          totalDueRef.current.classList.toggle('animate__heartBeat')
        }
        setTimeout(() => {
          if (totalDueRef.current) {
            totalDueRef.current.classList.toggle('animate__heartBeat')
          }
        }, 750)
      }
    }
  }, [displayTotal])

  return (
    <CheckPanel
      {...rest}
      css={css`
        position: relative;
      `}
    >
      <CheckSubtotalRow>
        <span>Check Subtotal</span>
        <span
          css={css`
            font-weight: bold;
          `}
        >
          {currency(subTotal, { fromCents: true }).format()}
        </span>
      </CheckSubtotalRow>
      <CheckPanelBottomContainer>
        <WrongCheckContainer>
          <p>Wrong Check? Tell you server.</p>
        </WrongCheckContainer>
        <TipAmountMenu>
          {defaultTips.map((tip) => {
            return (
              <Amount
                active={tipOption === tip}
                onClick={() => (checkoutTriggered ? null : percentChange(tip))}
                key={tip}
              >
                {`${tip}%`}
              </Amount>
            )
          })}
          <CurrencyInput
            ref={currencyInputRef}
            onClick={handleCurrencyClick}
            css={css`
              ${tipOption === 'Custom' &&
              css`
                && {
                  background-color: #feebcb;
                  color: #fcac24;
                  border-radius: 16px;
                  font-weight: bold;
                  &:focus {
                    &::-webkit-input-placeholder {
                      /* Chrome/Opera/Safari */
                      color: #fcac24;
                    }
                    outline-width: 0;
                  }
                }
              `}

              &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: #949eaf;
              }

              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              line-height: 1.43;
              text-align: center;
              color: #949eaf;
              padding: 3px 5px;
              cursor: pointer;
              border: 0 transparent;
              background: none;
              width: 100%;
            `}
            thousandSeparator=","
            decimalSeparator="."
            prefix="$"
            allowNegative={false}
            value={tipOption === 'Custom' ? num : null}
            onChangeEvent={customChange}
            placeholder="Tip"
            disabled={checkin.checkoutTriggered}
            inputMode="numeric"
            pattern="[0-9]*"
          />
        </TipAmountMenu>
        <CheckTotalsContainer>
          <CheckItems>
            {serviceCharges > 0 && (
              <CheckItemRow>
                Service Charges:
                <b>{currency(serviceCharges, { fromCents: true }).format()}</b>
              </CheckItemRow>
            )}
            <CheckItemRow>
              Tax: <b>{currency(tax, { fromCents: true }).format()}</b>
            </CheckItemRow>
            <CheckItemRow>
              Tip: <b>{currency(num).format()}</b>
            </CheckItemRow>
            {discount > 0 && (
              <CheckItemRow>
                Discount:
                <b
                  css={css`
                    color: green;
                  `}
                >
                  ({currency(discount, { fromCents: true }).format()})
                </b>
              </CheckItemRow>
            )}
            {payments?.venue > 0 && (
              <CheckItemRow>
                Paid at {checkin.vendor.name}:
                <b
                  css={css`
                    color: green;
                  `}
                >
                  ({currency(payments.venue, { fromCents: true }).format()})
                </b>
              </CheckItemRow>
            )}
            {payments?.stripe > 0 && (
              <CheckItemRow>
                Paid w/ Outpay:
                <b
                  css={css`
                    color: green;
                  `}
                >
                  (
                  {currency(payments.stripe, {
                    fromCents: true,
                  }).format()}
                  )
                </b>
              </CheckItemRow>
            )}
          </CheckItems>
          <TotalContainer>
            Total Due
            <h3 ref={totalDueRef} className="animate__animated">
              {currency(displayTotal).format()}
            </h3>
          </TotalContainer>
        </CheckTotalsContainer>
        {tipOption === 'Custom' && // show accept if editing custom tip
          checkin.gratuity.amount !== currency(num).intValue && (
            <Button
              active
              onClick={() => updateGratuity(false, currency(num).intValue)}
            >
              Accept
            </Button>
          )}

        {(checkin.gratuity.amount === currency(num).intValue ||
          tipOption !== 'Custom') &&
          actions}
      </CheckPanelBottomContainer>
    </CheckPanel>
  )
}

export default TipPanelGuest
