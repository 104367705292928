// @flow
import * as React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, useRouteMatch } from 'react-router-dom'
import Cookies from 'js-cookie'

type PrivateRouteProps = {
  children?: React.Node,
}

const PrivateRoute = ({ children, ...rest }: PrivateRouteProps) => {
  const { patron, vendor, checkin } = useSelector((state) => state)
  const redirectLink = vendor.id ? `/vendor/${vendor.id}` : '/' // we may need an entry point view that just prompts to scan QR or enter url
  const billMatch = useRouteMatch('/bill')
  // verify patron in state and has token to render route
  return (
    <Route
      {...rest}
      render={({ location }) =>
        (!patron.id || !Cookies.get('outpay_token')) 
        || (billMatch && !checkin.checkinId)
          ? (
            <Redirect
              to={{
                pathname: redirectLink,
                state: { from: location },
              }}
            />
          ) : (
            children
          )
      }
    />
  )
}

export default PrivateRoute
