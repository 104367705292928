/** @jsx jsx */
import { useState } from 'react'
import { jsx, css } from '@emotion/core'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Container } from './entry.js'
import Header from '../components/Header/Header'

import SvgArrowLeft from '../assets/images/svgr/ArrowLeft'
import SvgPlus from '../assets/images/svgr-converted/Plus'
import SelectCreditCard from '../components/Checkout/SelectCreditCard'
import AddNewPaymentMethodModal from '../components/modals/AddNewPaymentMethod'
import DeleteCCModal from '../components/modals/DeleteCC'

const SelectPayment = () => {
  const { vendor, checkin, app } = useSelector((state) => state)
  const [
    addNewPaymentMethodModalOpen,
    setAddNewPaymentMethodModalOpen,
  ] = useState(false)
  const [toDelete, setToDelete] = useState(null)
  const history = useHistory()

  const goBack = () => {
    history.goBack()
  }

  return (
    <Container>
      <Header
        css={css`
          justify-content: flex-start;
          align-items: center;
        `}
      >
        {app.action === 'guestBill' ? (
          <SvgArrowLeft
            css={css`
              margin-right: 8px;
            `}
            onClick={goBack}
          />
        ) : (
          <Link
            to={checkin ? '/bill' : `/vendor/${vendor.id}`}
            css={css`
              display: flex;
              flex-flow: column no-wrap;
              align-items: center;
            `}
          >
            <SvgArrowLeft
              css={css`
                margin-right: 8px;
              `}
            />
          </Link>
        )}

        <h3
          css={css`
            && {
              font-weight: 400;
              flex: 1;
            }
          `}
        >
          Select Payment <b>Method</b>
        </h3>
        <SvgPlus
          css={css`
            height: 18px;
          `}
          onClick={() => setAddNewPaymentMethodModalOpen(true)}
        />
      </Header>
      <SelectCreditCard setToDelete={setToDelete} />
      <AddNewPaymentMethodModal
        isOpen={addNewPaymentMethodModalOpen}
        onRequestClose={() => setAddNewPaymentMethodModalOpen(false)}
      />
      <DeleteCCModal
        isOpen={!!toDelete}
        handleClose={() => setToDelete(null)}
        card={toDelete}
      />
    </Container>
  )
}

export default SelectPayment
