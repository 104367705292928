import * as React from 'react'

function SvgAmericanExpress(props) {
  return (
    <svg width={43} height={30} {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#016FD0"
          d="M0 6.92C0 3.098 3.105 0 6.93 0h28.832c3.827 0 6.93 3.098 6.93 6.92v16.16c0 3.822-3.104 6.92-6.93 6.92H6.93C3.103 30 0 26.902 0 23.08V6.92z"
        />
        <path
          fill="#FFF"
          d="M8.141 22.03v-6.516h6.936l.744.965.769-.965h25.175v6.067s-.658.443-1.42.45h-13.94l-.839-1.028v1.027h-2.749v-1.753s-.376.244-1.188.244h-.935v1.51H16.53l-.743-.987-.754.986H8.14zM.042 10.603l1.564-3.627H4.31l.887 2.032V6.976H8.56l.528 1.469.512-1.469h15.094v.738s.793-.738 2.097-.738l4.899.017.872 2.005V6.976h2.814l.774 1.152V6.976h2.84v6.517h-2.84l-.742-1.155v1.155h-4.134l-.416-1.027h-1.111l-.41 1.027h-2.803c-1.122 0-1.839-.723-1.839-.723v.723h-4.227l-.84-1.027v1.027H3.91l-.415-1.027H2.387l-.413 1.027H.042v-2.89z"
        />
        <path
          fill="#016FD0"
          fillRule="nonzero"
          d="M2.16 7.779l-2.11 4.88h1.373l.39-.978h2.262l.388.977h1.403L3.76 7.779h-1.6zm.78 1.136l.69 1.707H2.248l.692-1.707z"
        />
        <path
          fill="#016FD0"
          d="M6.012 12.657V7.779l1.951.007 1.135 3.145 1.108-3.152h1.937v4.878h-1.227V9.063l-1.299 3.594H8.541L7.238 9.063v3.594zm6.97 0V7.779h4.001V8.87h-2.762v.834h2.697v1.028h-2.697v.866h2.762v1.059z"
        />
        <path
          fill="#016FD0"
          fillRule="nonzero"
          d="M17.693 7.779v4.88h1.226v-1.735h.516l1.47 1.733h1.499l-1.614-1.796a1.49 1.49 0 001.345-1.499c0-1.026-.81-1.583-1.715-1.583h-2.728zm1.226 1.092h1.402c.336 0 .58.261.58.513 0 .324-.316.514-.562.514h-1.42V8.871z"
        />
        <path
          fill="#016FD0"
          d="M23.888 12.657h-1.252V7.779h1.252zm2.969 0h-.27c-1.308 0-2.102-1.024-2.102-2.419 0-1.429.785-2.46 2.437-2.46h1.355v1.156h-1.405c-.67 0-1.144.52-1.144 1.316 0 .945.542 1.342 1.323 1.342h.322l-.516 1.065z"
        />
        <path
          fill="#016FD0"
          fillRule="nonzero"
          d="M29.525 7.779l-2.11 4.88h1.374l.39-.978h2.262l.387.977h1.404l-2.107-4.879h-1.6zm.78 1.136l.69 1.707h-1.381l.692-1.707z"
        />
        <path
          fill="#016FD0"
          d="M33.375 12.657V7.779h1.559l1.991 3.065V7.779h1.226v4.878h-1.509l-2.041-3.145v3.145zM8.98 21.196v-4.879h4.002v1.091h-2.763v.835h2.698v1.027h-2.698v.866h2.763v1.06zm19.607 0v-4.879h4.001v1.091h-2.762v.835h2.684v1.027h-2.684v.866h2.762v1.06zm-15.45 0l1.948-2.41-1.995-2.469h1.545l1.188 1.526 1.192-1.526h1.484l-1.968 2.439 1.952 2.44h-1.545l-1.153-1.503-1.126 1.503z"
        />
        <path
          fill="#016FD0"
          fillRule="nonzero"
          d="M18.629 16.317v4.88h1.258v-1.541h1.29c1.093 0 1.92-.577 1.92-1.698 0-.928-.649-1.64-1.76-1.64H18.63zm1.258 1.104h1.36c.352 0 .604.215.604.562 0 .325-.25.561-.609.561h-1.355v-1.123zm3.743-1.104v4.879h1.226v-1.734h.517l1.47 1.734h1.498l-1.613-1.798a1.489 1.489 0 001.345-1.499c0-1.026-.81-1.582-1.714-1.582H23.63zm1.226 1.091h1.402c.336 0 .58.262.58.514 0 .324-.316.513-.562.513h-1.42v-1.027z"
        />
        <path
          fill="#016FD0"
          d="M33.156 21.196v-1.06h2.454c.363 0 .52-.195.52-.409 0-.205-.157-.412-.52-.412H34.5c-.963 0-1.5-.585-1.5-1.462 0-.782.492-1.536 1.924-1.536h2.388l-.516 1.097H34.73c-.395 0-.517.206-.517.403 0 .202.15.426.452.426h1.162c1.074 0 1.54.606 1.54 1.4 0 .853-.52 1.553-1.599 1.553h-2.613zm4.5 0v-1.06h2.454c.363 0 .52-.195.52-.409 0-.205-.156-.412-.52-.412h-1.109c-.964 0-1.5-.585-1.5-1.462 0-.782.491-1.536 1.924-1.536h2.387l-.516 1.097h-2.065c-.395 0-.516.206-.516.403 0 .202.15.426.451.426h1.162c1.075 0 1.54.606 1.54 1.4 0 .853-.519 1.553-1.598 1.553h-2.614z"
        />
      </g>
    </svg>
  )
}

export default SvgAmericanExpress
