import * as React from 'react'

function SvgJcb(props) {
  return (
    <svg width={43} height={30} {...props}>
      <defs>
        <linearGradient
          id="jcb_svg__a"
          x1="100%"
          x2="3.381%"
          y1="43.985%"
          y2="43.985%"
        >
          <stop offset="0%" stopColor="#43C311" />
          <stop offset="100%" stopColor="#288C00" />
        </linearGradient>
        <linearGradient
          id="jcb_svg__c"
          x1=".258%"
          x2="101.258%"
          y1="49.75%"
          y2="49.75%"
        >
          <stop offset="0%" stopColor="#21469E" />
          <stop offset="100%" stopColor="#1E81EB" />
        </linearGradient>
        <linearGradient id="jcb_svg__e" x1="100%" x2="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#F42D3E" />
          <stop offset="100%" stopColor="#9C0017" />
        </linearGradient>
        <path
          id="jcb_svg__b"
          d="M2.598 0c-.151 0-.302.006-.45.022A2.41 2.41 0 00.629.826C.339 1.158.136 1.57.05 2.01a3.422 3.422 0 00-.047.655V5.04c0 .056-.013.06.046.06h2.785c.304 0 .61-.012.914.017.298.028.602.116.844.306.34.267.478.728.361 1.153-.11.407-.445.68-.827.793a1.413 1.413 0 01-.128.033c-.025.005-.058.004-.081.015-.011.006-.04.012-.019.033.005.005.027.005.044.005h.015c.12.012.24.033.356.066.355.1.7.317.865.67.195.42.095.94-.22 1.265-.248.256-.594.387-.936.43-.172.022-.345.018-.518.018H.134c-.026 0-.101-.014-.123 0-.013.008-.008.02-.008.04v4.993c0 .01-.006.051 0 .059.007.009.07 0 .085 0h3.366c.553 0 1.08-.148 1.528-.495a2.56 2.56 0 00.97-2.005V.007H3.195C3.01.007 2.823 0 2.637 0h-.04zM1.167 5.86c-.008.016 0 .055 0 .073v.975c0 .027-.012.116.006.13.016.01.083 0 .103 0h.754c.14 0 .281.002.421 0a.654.654 0 00.466-.183.605.605 0 00.035-.786.607.607 0 00-.43-.22c-.08-.006-.16-.003-.239-.003H1.184c-.008 0-.012.001-.017.013zm.917 1.957h-.917v1.239c0 .02-.009.063 0 .082.012.028.126.012.163.012h1.152c.15 0 .294-.01.427-.091a.664.664 0 00.299-.47.696.696 0 00-.2-.603.66.66 0 00-.462-.169h-.462z"
        />
        <path
          id="jcb_svg__d"
          d="M2.045.033c-.347.058-.68.195-.97.396A2.542 2.542 0 00.002 2.506v6.129c0 .046 0 .037.05.061.057.028.114.055.171.08.457.208.938.365 1.434.428.357.044.739.057 1.076-.09.27-.116.485-.332.596-.612.07-.18.091-.37.091-.561V5.177c0-.016-.008-.064 0-.076.01-.016.094-.006.118-.006h1.981v2.888a2.121 2.121 0 01-.157.842 1.644 1.644 0 01-.525.656c-.551.41-1.28.518-1.946.549a9.593 9.593 0 01-1.428-.046 8.802 8.802 0 01-1.46-.256v5.218c0 .007-.006.043 0 .049.01.011.1 0 .117 0h2.812c.415 0 .84.03 1.246-.076a2.474 2.474 0 001.619-1.377c.168-.373.223-.77.223-1.176V0H2.594c-.184 0-.367.003-.55.033"
        />
        <path
          id="jcb_svg__f"
          d="M2.26.006c-.26.017-.518.078-.76.18A2.478 2.478 0 00.262 1.367C.092 1.713.005 2.1.003 2.49v3.255l.05-.043c.06-.05.122-.096.186-.14.399-.271.864-.423 1.33-.513.38-.073.767-.105 1.153-.113.38-.007.761.006 1.139.049.385.042.768.11 1.148.193l.139.032c.025.006.12.014.133.034.01.014 0 .07 0 .086v.964l-.162-.082c-.413-.2-.847-.35-1.299-.423-.606-.1-1.292-.096-1.815.278-.505.36-.682 1.001-.627 1.61.053.593.37 1.102.906 1.339.529.234 1.142.202 1.696.09a5.07 5.07 0 001.301-.476v.966c0 .016.01.072 0 .086-.013.02-.11.027-.133.033a10.331 10.331 0 01-1.447.242c-.38.035-.763.042-1.144.027a6.29 6.29 0 01-1.16-.143c-.5-.115-1-.312-1.394-.66v5.669c0 .011-.007.06 0 .07.017.02.152.003.184.003h2.475c.348 0 .703.02 1.051-.006a2.401 2.401 0 001.644-.846c.398-.47.595-1.066.595-1.687V0H2.491c-.077 0-.154.001-.23.006"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          stroke="#E6E7E8"
          strokeWidth={0.652}
          d="M35.762.326H6.93a6.59 6.59 0 00-4.67 1.932A6.575 6.575 0 00.327 6.92v16.16c0 1.82.74 3.47 1.934 4.662a6.588 6.588 0 004.67 1.932h28.832a6.59 6.59 0 004.67-1.932 6.568 6.568 0 001.934-4.662V6.92c0-1.82-.739-3.47-1.934-4.662a6.586 6.586 0 00-4.67-1.932z"
        />
        <g transform="translate(11.74 7.826)">
          <use
            fill="url(#jcb_svg__a)"
            xlinkHref="#jcb_svg__b"
            transform="translate(13.545)"
          />
          <use fill="url(#jcb_svg__c)" xlinkHref="#jcb_svg__d" />
          <use
            fill="url(#jcb_svg__e)"
            xlinkHref="#jcb_svg__f"
            transform="translate(6.773)"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgJcb
