import * as React from 'react'

function SvgFileText(props) {
  return (
    <svg width={24} height={24} {...props}>
      <defs>
        <path
          id="file-text_svg__a"
          d="M15 0a3 3 0 013 3v16a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3h12zm0 2H3a1 1 0 00-1 1v16a1 1 0 001 1h12a1 1 0 001-1V3a1 1 0 00-1-1zm-2 12a1 1 0 010 2H5a1 1 0 010-2zm0-4a1 1 0 010 2H5a1 1 0 010-2zM7 6a1 1 0 010 2H5a1 1 0 010-2z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(3 1)">
        <mask id="file-text_svg__b" fill="#fff">
          <use xlinkHref="#file-text_svg__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#file-text_svg__a" />
        <g mask="url(#file-text_svg__b)">
          <path d="M-3-1h24v24H-3z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgFileText
