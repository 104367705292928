/** @jsx jsx */
// @flow
import { useState, useEffect, useCallback, Fragment } from 'react'
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import Header from '../components/Header/Header'
import { BillItem } from '../components/BillItem'
import axios from '../utils/axios'
import { useStripe } from '@stripe/react-stripe-js'
import TipPanelGuest from '../components/Tip/TipPanelGuest.js'
import StripeCreditCardForm from '../components/Checkout/StripeCreditCardForm'
import { Button } from '../components/Button'
import PaymentRequestWithStripe from '../components/Checkout/PaymentRequestWithStripe'
import { useAlert } from 'react-alert'
import OutpayBackground from '../components/OutpayBackground'
import ModalCloseButton from '../components/modals/ModalCloseButton'
import getCSSHeight from '../utils/getCSSHeight.js'
import { isSafari, isIOS } from 'react-device-detect'
import AppleLoginButton from '../components/Login/Apple/apple-login'
import GoogleLoginButton from '../components/Login/Google/google-login'
import UberModal from '../components/modals/Uber'
import AcceptCheck from '../layouts/accept-check'
import Cookies from 'js-cookie'
import Modal from 'react-modal'
import SplitEvenly from '../assets/images/split-evenly.png'
import SplitItem from '../assets/images/split-item.png'
import SplitAmount from '../assets/images/split-amount.png'
import AddMobilePay from '../components/Checkout/AddMobilePay'
import ModalContainer from '../components/Modal'
import AddCreditCard from '../components/Checkout/AddCreditCard'
import SelectPaymentButton from '../components/Buttons/SelectPaymentButton'
import Loader from '../components/Loader/Loader'

const BillContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 50px 20px 0;
  text-align: center;
  height: calc(100vh - 300px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  align-items: center;
  pointer-events: auto;

  > button {
    width: 100%;
  }
`

export const BillPageContainer = styled.div`
  width: 100%;
  height: ${getCSSHeight()};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const SubText = styled.p`
  font-size: 14px;
  color: #a9a9a9;
`

const CheckPanel = styled.div`
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  box-shadow: 0 -8px 24px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  max-width: 575px;
  position: absolute;
  bottom: 0;
  padding: 10px;
`

const WrongCheckContainer = styled.div`
  text-align: center;
`

const TermsContainer = styled.div`
  font-size: 10px;
  padding: 4px 10px;
  border-radius: 8px;
  text-align: center;
`

const Link = styled.a`
  color: #fcac24;
  text-decoration: none;
`

const SplitContainer = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 0 12px;
`

const SplitOptionContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;

  > img {
    width: 30px;
    height: 30px;
  }

  > p {
    font-size: 12px;
  }
`
const ModalTitle = styled.p`
  text-align: center;
  line-height: 24px;
  font-size: 20px;
  font-weight: bold;
`

const ModalDescription = styled.p`
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  margin: 12px 50px;
`

const ModalContentContainer = styled.div`
  width: 100%;
  z-index: 2;
  max-width: 575px;
  pointer-events: auto;
`

const SmallText = styled.p`
  font-size: 14px;
  text-align: center;
  pointer-events: auto;
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`

const SplitModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
`

const SplitOptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
`

const BillGuest = () => {
  const { checkin, patron, app, vendor } = useSelector((state) => state)
  const params = useParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const stripe = useStripe()
  const alert = useAlert()
  const history = useHistory()

  const [displayTotal, setDisplayTotal] = useState()
  const [showStripeCreditCard, setShowStripeCreditCard] = useState(false)
  const [splitInfo, setSplitInfo] = useState({})
  const [canSplit, setCanSplit] = useState(false)
  const [isPaying, setIsPaying] = useState(false)
  const [error, setError] = useState('')
  const [showUberModal, setShowUberModal] = useState(false)
  const [uberModalData, setUberModalData] = useState()
  const [showSignInModal, setShowSignInModal] = useState(false)
  const [showAddCreditCard, setShowAddCreditCard] = useState(false)
  const [triggerCheckin, setTriggerCheckin] = useState(false)
  const [showLoader, setShowLoader] = useState(false)

  const { ticket } = checkin
  console.log('TICKET IN MYBILL', checkin)
  const patronLoggedIn = patron.token && Cookies.get('outpay_token')

  useEffect(() => {
    dispatch({
      type: 'checkin/closeCheckin',
    })
    const fetchData = async () => {
      try {
        const searchParams = new URLSearchParams(location.search)
        const action = searchParams.get('action')
        const locationId = searchParams.get('location')
        const { data } = await axios.API.get(
          `/check/${params.check_id}/vendor/${params.vendor_id}${locationId ? '/?location=' + locationId : ''}`
        )

        dispatch({
          type: 'checkin/updateCheckin',
          payload: {
            ...data,
            gratuity: {
              isPercent: true,
              amount: 20,
            },
          },
        })
        if (data.vendor) {
          dispatch({
            type: 'vendor/setVendor',
            payload: data.vendor,
          })
        }
      } catch (error) {
        console.error(error)
        setError(error)
        dispatch({
          type: 'app/updateCheck',
          payload: {
            check: null,
          },
        })
      }
    }
    fetchData()
  }, [dispatch, params.vendor_id, params.check_id, patronLoggedIn])

  useEffect(() => {
    let subtotalToSplit = 0
    if (splitInfo.amount && checkin.ticket) {
      subtotalToSplit =
        splitInfo.amount +
        checkin.ticket.serviceCharges -
        (checkin.ticket.discount +
          checkin.ticket.payments.venue +
          checkin.ticket.payments.stripe)
    }
    setCanSplit(subtotalToSplit >= 100)
  }, [splitInfo.amount, checkin.ticket])

  const submitPayment = useCallback(
    async ({ firstName, lastName, email, paymentMethod }) => {
      try {
        const { data } = await axios.API.post(
          `/check/${params.check_id}/vendor/${params.vendor_id}`,
          {
            subTotal:
              splitInfo.amount +
              checkin.ticket.serviceCharges -
              (checkin.ticket.discount +
                checkin.ticket.payments.venue +
                checkin.ticket.payments.stripe),
            tax: splitInfo.tax,
            tip: splitInfo.tip,
            firstName: firstName,
            lastName: lastName,
            email: email,
          }
        )
        const { error } = await stripe.confirmCardPayment(data.client_secret, {
          payment_method: paymentMethod,
        })

        if (error) {
          throw error
        }

        setUberModalData(data)
        setShowStripeCreditCard(false)
        setShowUberModal(true)
        dispatch({
          type: 'app/updateCheck',
          payload: {
            check: null,
          },
        })
      } catch (error) {
        console.error(error)
        if (
          error.message === 'Amount due updated.' ||
          error.message === 'Check already closed.'
        ) {
          dispatch({
            type: 'checkin/updateCheckin',
            payload: error.data,
          })
          setShowStripeCreditCard(false)
        }

        throw error
      }
    },
    [splitInfo, stripe, params.vendor_id, params.check_id, checkin, dispatch]
  )

  const handleCard = useCallback(
    async ({ cardElement, firstName, lastName, email }) => {
      try {
        setIsPaying(true)
        await submitPayment({
          firstName,
          lastName,
          email,
          paymentMethod: {
            card: cardElement,
          },
        })
      } catch (error) {
        console.error(error)
        setIsPaying(false)
        alert.show(error.message || error, {
          timeout: 0,
          header: 'Submit Payment Error',
          gif: 'warning',
        })
      }
    },
    [submitPayment, alert]
  )

  const handleSplitInfo = useCallback((info) => {
    setSplitInfo(info)
  }, [])

  const handlePaymentMethod = useCallback(
    async (event) => {
      console.log(event)
      try {
        await submitPayment({
          firstName: event.payerName.split(' ')[0],
          lastName: event.payerName.split(' ')[1],
          email: event.payerEmail,
          paymentMethod: event.paymentMethod.id,
        })

        event.complete('success')
      } catch (error) {
        console.error(error)
        event.complete('fail')
        alert.show(error.message || error, {
          timeout: 0,
          header: 'Submit Payment Error',
          gif: 'warning',
        })
      }
    },
    [submitPayment, alert]
  )

  const handleStripeSuccess = useCallback(
    (card) => {
      dispatch({
        type: 'patron/updatePatron',
        payload: {
          defaultCard: card,
          cards: [card],
        },
      })

      setTriggerCheckin(true)
    },
    [dispatch]
  )

  const runCheckin = useCallback(() => {
    console.log('RUN CHECKIN')
    if (patron.defaultCard && vendor.id && !checkin.checkinId) {
      dispatch({
        type: 'app/setIsAutoCheckin',
        payload: {
          isAutoCheckin: false,
        },
      })
      setShowLoader(true)
      const table = app.table
      const revenueCenter = app.revenueCenter
      const server = app.server
      const check = params.check_id
      return axios.API.post(`/checkin/patron/${patron.id}`, {
        vendor_id: vendor.id,
        ...(table && {
          table,
        }),
        ...(revenueCenter && {
          revenueCenter,
        }),
        ...(server && {
          server,
        }),
        ...(check && {
          check,
        }),
      })
        .then(({ data }) => {
          setShowLoader(false)
          dispatch({
            type: 'checkin/postCheckin',
            payload: data,
          })
          dispatch({
            type: 'app/updateCheck',
            payload: {
              check: null,
            },
          })
          history.push('/bill')
        })
        .catch((error) => {
          console.log('error: ', error)
          if (
            (error.message || '')
              .toLowerCase()
              .includes('One or more checkins already exist')
          ) {
            setTimeout(() => {
              runCheckin()
            }, 5000)
          } else {
            setShowLoader(false)
            alert.show(error.message || error, {
              timeout: 0,
              header: 'Error opening check',
              gif: 'warning',
            })
          }
        })
    }
  }, [
    dispatch,
    history,
    vendor.id,
    patron.defaultCard,
    patron.id,
    app.table,
    app.revenueCenter,
    app.server,
    checkin.checkinId,
    alert,
    params.check_id,
  ])

  useEffect(() => {
    if (triggerCheckin) {
      runCheckin()
    }
  }, [triggerCheckin, runCheckin])

  const handleAcceptCheck = () => {
    runCheckin()
  }

  if (error) {
    return (
      <OutpayBackground>
        <p>
          <b>{error.message || 'Check not found.'}</b>
        </p>
        <SubText>To open another scan the QR code with your camera</SubText>
      </OutpayBackground>
    )
  }

  if (patronLoggedIn && app.action !== 'guestBill') {
    return <AcceptCheck />
  }

  return (
    <BillPageContainer>
      <Header>
        <h3>#{ticket?.checkNumber}</h3>
      </Header>
      <BillContentContainer>
        {ticket?.items?.length > 0 &&
          ticket.items.map((item) => {
            return <BillItem item={item} key={item.id} />
          })}
      </BillContentContainer>
      {app.action === 'guestSplitBill' ? (
        <CheckPanel>
          <WrongCheckContainer>
            <p>Wrong Check? Tell you server.</p>
            <p>
              Sign In with {isIOS ? 'Apple' : 'Google'} to accept this check &
              split with friends
            </p>
          </WrongCheckContainer>

          {(isSafari || isIOS) && <AppleLoginButton />}
          {!isSafari && <GoogleLoginButton />}

          <TermsContainer>
            By signing in, you agree to Outpay’s{' '}
            <Link
              href="https://www.termsfeed.com/live/27b69fd4-d478-4c53-b2cb-2a411c6eb16c"
              target="_blank"
            >
              Terms{' '}
            </Link>
            &{' '}
            <Link
              href="https://www.termsfeed.com/live/8a6140ca-01a3-410d-95f3-3cc610ce228d"
              target="_blank"
            >
              Privacy Policy
            </Link>
          </TermsContainer>
        </CheckPanel>
      ) : (
        !showStripeCreditCard &&
        ticket && (
          <Fragment>
            <TipPanelGuest
              onSplitInfo={handleSplitInfo}
              displayTotal={displayTotal}
              setDisplayTotal={setDisplayTotal}
              actions={
                <ButtonsContainer>
                  <div>
                    {!showSignInModal && (
                      <PaymentRequestWithStripe
                        label={'Bill'}
                        amount={
                          splitInfo.amount + splitInfo.tax + splitInfo.tip
                        }
                        onPaymentMethod={handlePaymentMethod}
                        onCancel={(event) => console.log('CANCEL EVENT', event)}
                      />
                    )}
                  </div>

                  <Button
                    css={css`
                      @media (max-width: 360px) {
                        font-size: 14px;
                      }
                    `}
                    active={
                      (splitInfo.amount >= 50 &&
                        splitInfo.amount <=
                          checkin.ticket.totals.subTotal - 50) ||
                      splitInfo.amount ===
                        checkin.ticket.totals.subTotal +
                          checkin.ticket.totals.discounts
                    }
                    onClick={() => setShowStripeCreditCard(true)}
                  >
                    Add Credit Card
                  </Button>
                </ButtonsContainer>
              }
            />

            <SplitContainer>
              {!isSafari && isIOS && (
                <p style={{ marginTop: 0 }}>
                  Looking for Apple Pay? Open this page in Safari.
                </p>
              )}

              {canSplit && (
                <Button
                  active
                  secondary
                  noMargin
                  css={css`
                    &&&& {
                      border: 2px solid #000;
                      color: #000;
                    }
                  `}
                  onClick={() => setShowSignInModal(true)}
                >
                  Split Bill
                </Button>
              )}

              {canSplit && (
                <TermsContainer>
                  By signing in, you agree to Outpay’s{' '}
                  <Link
                    href="https://www.termsfeed.com/live/27b69fd4-d478-4c53-b2cb-2a411c6eb16c"
                    target="_blank"
                  >
                    Terms{' '}
                  </Link>
                  &{' '}
                  <Link
                    href="https://www.termsfeed.com/live/8a6140ca-01a3-410d-95f3-3cc610ce228d"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                </TermsContainer>
              )}
            </SplitContainer>
          </Fragment>
        )
      )}

      {showStripeCreditCard && (
        <StripeCreditCardContainer>
          <ModalCloseButton
            style={{
              top: '-110px',
              right: '10px',
              zIndex: 2,
            }}
            onClick={() => setShowStripeCreditCard(false)}
          />
          <AmountRow>
            <AmountContainer>
              <PayText>Pay</PayText>
              <AmountText> {displayTotal.format()}</AmountText>
            </AmountContainer>
          </AmountRow>

          <StripeCreditCardForm
            isPayment
            isLoading={isPaying}
            amount={displayTotal.format()}
            onCard={handleCard}
          />
        </StripeCreditCardContainer>
      )}

      {showSignInModal && !patronLoggedIn && (
        <ModalCloseButton
          style={{
            top: '50px',
            right: '10px',
            zIndex: 12,
          }}
          onClick={() => setShowSignInModal(false)}
        />
      )}

      <Modal
        isOpen={showSignInModal}
        style={{
          overlay: {
            background: 'rgba(0, 0, 0, 0.75)',
            transition: 'opacity 0.25s ease',
            zIndex: 11,
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-45%',
            borderRadius: '20px',
            transform: 'translate(-50%, -50%)',
            padding: '10px',
          },
        }}
      >
        {!patronLoggedIn ? (
          <SplitModalContainer>
            <ModalTitle>
              You&apos;ll get a QR code for your friends to scan to pay:
            </ModalTitle>
            <SplitOptionRow>
              <SplitOptionContainer>
                <img src={SplitEvenly} alt="split_evenly" />
                <p>Evenly</p>
              </SplitOptionContainer>
              <SplitOptionContainer>
                <img src={SplitItem} alt="split_item" />
                <p>By Item</p>
              </SplitOptionContainer>
              <SplitOptionContainer>
                <img src={SplitAmount} alt="split_amount" />
                <p>By Amount</p>
              </SplitOptionContainer>
            </SplitOptionRow>
            {(isSafari || isIOS) && <AppleLoginButton split />}
            {!isSafari && isIOS && <div style={{ height: '8px' }}></div>}
            {!isSafari && <GoogleLoginButton split light />}
          </SplitModalContainer>
        ) : patron.defaultCard ? (
          <div>
            <ModalTitle>Welcome back, {patron.firstName}!</ModalTitle>
            <ModalDescription>
              Now how would you like to pay your share?
            </ModalDescription>
            <SelectPaymentButton />
            {showLoader ? (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            ) : (
              <Button active={!!patron.defaultCard} onClick={handleAcceptCheck}>
                Confirm
              </Button>
            )}

            <SmallText>
              Click{' '}
              <span
                style={{ color: '#fcac24' }}
                onClick={() => history.push('/select-payment')}
              >
                here
              </span>{' '}
              to use another card.
            </SmallText>
          </div>
        ) : (
          <div>
            <ModalTitle>Thanks, {patron.firstName}!</ModalTitle>
            <ModalDescription>
              Now how would you like to pay your share?
            </ModalDescription>
            <ButtonsContainer>
              <AddMobilePay onSuccess={handleStripeSuccess} />
              <Button active dark onClick={() => setShowAddCreditCard(true)}>
                Add Credit Card
              </Button>
            </ButtonsContainer>
            <SmallText>
              You&apos;ll only be charged for you share of the bill
            </SmallText>
          </div>
        )}
      </Modal>

      <UberModal
        isOpen={showUberModal}
        data={uberModalData}
        setShowUberModal={setShowUberModal}
      />

      <ModalContainer
        isOpen={showAddCreditCard}
        onRequestClose={() => setShowAddCreditCard(false)}
      >
        <Fragment>
          <ModalCloseButton />
          <ModalContentContainer>
            <AddCreditCard onSuccess={handleStripeSuccess} />
          </ModalContentContainer>
        </Fragment>
      </ModalContainer>
    </BillPageContainer>
  )
}

const StripeCreditCardContainer = styled.div`
  position: relative;
`

const AmountRow = styled.div`
  position: absolute;
  top: -100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
`

const PayText = styled.span`
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
`

const AmountText = styled.span`
  font-size: 32px;
  color: black;
  font-weight: bold;
`

export default BillGuest
