import React from 'react'
import styled from '@emotion/styled'
import SvgOutpayLogotypeDisclimerOnlight from '../assets/images/outpay-logotype-disclimer-onlight.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const LogoDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  width: 220px;

  > img {
    width: 100%;
    margin-bottom: 4px;
  }
`

const NotSupported = () => {
  return (
    <Container>
      <LogoDiv>
        <img alt="Outpay logo" src={SvgOutpayLogotypeDisclimerOnlight} />
      </LogoDiv>
      <p>Your device is not supported. Mobile devices only.</p>
    </Container>
  )
}

export default NotSupported
