import React from 'react'
import styled from '@emotion/styled'
import MenuIcon from './MenuIcon'

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 20px;
  top: 0;
  background-color: #f0f0f1;
  z-index: 1;
  max-width: 575px;
  position: fixed;

  h3 {
    margin: 0;
  }

  > p {
    margin: 0;
  }
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Header = ({ showSideNav, children }) => {
  return (
    <Container>
      {showSideNav && <MenuIcon />}
      <ContentContainer>{children}</ContentContainer>
    </Container>
  )
}

export default Header
