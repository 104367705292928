/** @jsx jsx */
// @flow
import { useState, useCallback, useRef, useEffect } from 'react'
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IconContainer } from './entry.js'
import Header from '../components/Header/Header'
import SvgFileText from '../assets/images/svgr/FileText'
import { BillItem } from '../components/BillItem'
import ConfirmCloseCheckModal from '../components/modals/ConfirmCloseCheck'
import UberModal from '../components/modals/Uber'
import TipPanel from '../components/Tip/TipPanel'
import SplitBillQRModal from '../components/modals/SplitBillQR'
import FixPaymentIssueModal from '../components/modals/FixPaymentIssueModal'
import { useAlert } from 'react-alert'

import '../styles/animations.css'
import getCSSHeight from '../utils/getCSSHeight.js'
// import Countdown from 'react-countdown'
import Countdown from '../components/Countdown'
import RedArrows from '../assets/gif/red-arrows.gif'

const Heading = styled.h2`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-bottom: 0;
`
const IssueHeader = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 10px 20px;
  align-items: center;
  background-color: #ff4545;
  color: #fff;
  z-index: 1;
  max-width: 575px;
  border-radius: 0.5rem;
`

const BillContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 50px 20px;
  text-align: center;
  height: calc(100vh - 300px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
`

export const BillPageContainer = styled.div`
  width: 100%;
  height: ${getCSSHeight()};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const TooltipText = styled.span`
  position: absolute;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 6px;
  padding: 5px;
  text-align: center;
  top: 30px;
  right: -12px;
  font-size: 12px;
  width: 110px;
  color: #000;

  ::before {
    content: ' ';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent #000 transparent;
  }

  ::after {
    content: ' ';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
  }
`

const RedArrowsImg = styled.img`
  height: 50px;
  position: absolute;
  left: 0;
  top: 30px;
`

const MyBill = () => {
  const { checkin, vendor, app } = useSelector((state) => state)
  const history = useHistory()
  const tooltipRef = useRef()
  const alert = useAlert()
  const [displayTotal, setDisplayTotal] = useState()
  // eslint-disable-next-line no-unused-vars
  const [showConfirmCloseCheckModal, setShowConfirmCloseCheckModal] = useState(
    false
  )
  const [showUberModal, setShowUberModal] = useState(false)
  const [showSplitBillQRModal, setShowSplitBillQRModal] = useState(false)
  const [showFixPaymentIssueModal, setShowFixPaymentIssueModal] = useState(
    checkin.stripe.paymentIssue ? true : false
  )
  const headingRef = useRef()
  const dispatch = useDispatch()

  const { ticket } = checkin || {}
  const { undiscountedTotals } = ticket || {}
  const { due, totalForTipCalculation } = undiscountedTotals || {}

  console.log('TICKET IN MYBILL', checkin)

  useEffect(() => {
    if (checkin.flow === 'complete' && !checkin.stripe?.paymentIssue) {
      if (checkin.checkoutType === 'idle' && ticket.items.length === 0) {
        dispatch({
          type: 'checkin/closeCheckin',
        })
        dispatch({
          type: 'app/setIdleCheckoutWithoutItems',
          payload: {
            idleCheckoutWithoutItems: true,
          },
        })
        history.push(`/vendor/${vendor.id}`)
      }
    }
  }, [
    checkin.flow,
    checkin.stripe,
    checkin.checkoutType,
    ticket.items.length,
    dispatch,
    history,
    vendor.id,
  ])

  useEffect(() => {
    if (checkin.stripe?.paymentIssue) {
      setShowConfirmCloseCheckModal(false)
    } else {
      setShowFixPaymentIssueModal(false)
    }
  }, [checkin.stripe])

  const handleCloseCheckModal = useCallback(() => {
    setShowConfirmCloseCheckModal(false)
  }, [])

  const handleCheckout = useCallback(() => {
    if (due <= checkin.stripe.preauth) {
      setShowConfirmCloseCheckModal(false)
      setShowUberModal(true)
    }
  }, [checkin.stripe.preauth, due])

  useEffect(() => {
    if (totalForTipCalculation && due === 0) {
      setShowConfirmCloseCheckModal(false)
      setShowUberModal(true)
    }
  }, [due, checkin.stripe.preauth, totalForTipCalculation])

  useEffect(() => {
    setTimeout(() => {
      if (tooltipRef.current) {
        tooltipRef.current.classList.add('scale-out-top')
      }
    }, 3500)
  }, [])

  const handleSplitBill = useCallback(() => {
    if (ticket?.items?.length > 0) {
      setShowSplitBillQRModal(true)
    } else {
      alert.show('Nothing to split', {
        timeout: 0,
        header: 'Split Bill Error',
        gif: 'warning',
      })
    }
  }, [alert, ticket])

  useEffect(() => {
    if (
      ['guestBill', 'guestSplitBill'].includes(app.action) &&
      ticket?.items?.length > 0
    ) {
      handleSplitBill()
      dispatch({
        type: 'app/updateAction',
        payload: {
          action: null,
        },
      })
    }
  }, [ticket, app.action, dispatch, handleSplitBill])

  useEffect(() => {
    const interval = setInterval(() => {
      if (headingRef.current) {
        headingRef.current.classList.toggle('animate__heartBeat')
      }
    }, 2500)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <BillPageContainer>
      <Header showSideNav>
        {ticket.tabName ? (
          <h3 style={{ position: 'relative' }}>
            <b>{ticket.tabName}</b>
            {!ticket?.items?.length && <RedArrowsImg src={RedArrows} />}
          </h3>
        ) : (
          <h3>
            My <b>Bill</b>
          </h3>
        )}
        {!checkin.checkoutTriggered && (
          <IconContainer
            css={css`
              position: relative;
            `}
            onClick={() => {
              history.push(`/vendor/${vendor.id}`)
            }}
          >
            <span
              css={css`
                margin-right: 5px;
              `}
            >
              Menu
            </span>
            <SvgFileText />
            <TooltipText ref={tooltipRef} className="scale-in-top">
              Click here to go back to the menu
            </TooltipText>
          </IconContainer>
        )}
      </Header>

      {!ticket?.items?.length & !checkin.checkoutTriggered && (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: auto;
          `}
        >
          <Heading
            css={css`
              margin: 32px 16px 0;
            `}
            ref={headingRef}
            className="animate__heartBeat"
          >
            Tell your server or bartender your name and check number
          </Heading>
          <p
            css={css`
              margin: 8px 16px;
            `}
          >
            Order directly with your server, not on your phone. <br />
            Items you order will show up here.
          </p>
        </div>
      )}
      {ticket?.items?.length > 0 && (
        <BillContentContainer>
          {ticket.items.map((item) => {
            return <BillItem item={item} key={item.id} />
          })}
        </BillContentContainer>
      )}
      {checkin?.stripe?.paymentIssue &&
        !checkin.stripe.paymentIssue.resolution && (
          <IssueHeader>
            {ticket.open && 'Card Declined - Update - Check Closes in: '}
            {!ticket.open &&
              `${
                ticket.payments.platform
                  ? 'Payment Failed - Balance Due'
                  : 'Card Declined - Update and Retry in: '
              }`}
            {(ticket.open || (!ticket.open && !ticket.payments?.platform)) && (
              <Countdown endsAt={checkin.stripe.paymentIssue.closeAt} />
            )}
          </IssueHeader>
        )}
      <TipPanel
        setShowConfirmCloseCheckModal={setShowConfirmCloseCheckModal}
        onSplitBill={handleSplitBill}
        displayTotal={displayTotal}
        setDisplayTotal={setDisplayTotal}
        setShowFixPaymentIssueModal={setShowFixPaymentIssueModal}
      />
      <ConfirmCloseCheckModal
        displayTotal={displayTotal}
        isOpen={showConfirmCloseCheckModal}
        handleCheckout={handleCheckout}
        handleClose={handleCloseCheckModal}
      />
      <UberModal isOpen={showUberModal} setShowUberModal={setShowUberModal} />
      <SplitBillQRModal
        isOpen={showSplitBillQRModal}
        setShowSplitBillQRModal={setShowSplitBillQRModal}
      />
      <FixPaymentIssueModal
        isOpen={showFixPaymentIssueModal}
        setShowFixPaymentIssueModal={setShowFixPaymentIssueModal}
        displayTotal={displayTotal}
      />
    </BillPageContainer>
  )
}

export default MyBill
