import * as React from 'react'

function SvgDinersclub(props) {
  return (
    <svg width={43} height={30} {...props}>
      <defs>
        <linearGradient
          id="dinersclub_svg__a"
          x1="70.752%"
          x2="32.476%"
          y1="81.957%"
          y2="18.043%"
        >
          <stop offset="0%" stopColor="#009BE0" />
          <stop offset="100%" stopColor="#006BA8" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          stroke="#E6E7E8"
          strokeWidth={0.652}
          d="M35.762.326H6.93a6.59 6.59 0 00-4.67 1.932A6.575 6.575 0 00.327 6.92v16.16c0 1.82.74 3.47 1.934 4.662a6.588 6.588 0 004.67 1.932h28.832a6.59 6.59 0 004.67-1.932 6.568 6.568 0 001.934-4.662V6.92c0-1.82-.739-3.47-1.934-4.662a6.586 6.586 0 00-4.67-1.932z"
        />
        <path
          fill="url(#dinersclub_svg__a)"
          d="M11.447 15.621c4.231.02 8.093-3.482 8.093-7.743 0-4.659-3.862-7.88-8.093-7.878H7.806C3.524-.002 0 3.22 0 7.878c0 4.262 3.524 7.764 7.806 7.743h3.641z"
          transform="translate(11.74 7.174)"
        />
        <path
          fill="#F4F6F7"
          d="M19.56 7.824c-3.911.003-7.081 3.203-7.082 7.153.001 3.949 3.171 7.149 7.084 7.15 3.913-.001 7.084-3.201 7.085-7.15 0-3.95-3.172-7.15-7.085-7.151zm-4.49 7.153a4.54 4.54 0 012.883-4.23v8.459a4.54 4.54 0 01-2.882-4.232zm6.098 4.23v-8.46a4.535 4.535 0 012.884 4.23 4.537 4.537 0 01-2.884 4.23z"
        />
      </g>
    </svg>
  )
}

export default SvgDinersclub
