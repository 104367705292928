/** @jsx jsx */
import { useState, useEffect } from 'react'
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import currency from 'currency.js'
import SvgPlus from '../assets/images/svgr-converted/Plus'
import SvgMinus from '../assets/images/svgr-converted/Minus'

const QuantitySelect = styled.div`
  border-radius: 20px;
  border: none;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
`

export const BillItemSplit = ({ item, handleSelect, reset }) => {
  const [quantity, setQuantity] = useState(0)
  const [disablePlus, setDisablePlus] = useState(false)
  const [disableMinus, setDisableMinus] = useState(true)

  useEffect(() => {
    if (reset) {
      setQuantity(0)
      setDisablePlus(false)
      setDisableMinus(true)
    }
  }, [reset, item.quantity])

  const handleMinus = () => {
    if (quantity === 0) {
      return
    }
    const value = quantity - 1
    if (value === 0) {
      setDisableMinus(true)
    }
    setDisablePlus(false)

    setQuantity(value)
    handleSelect(item, value)
  }

  const handlePlus = () => {
    if (quantity === item.quantity) {
      return
    }
    const value = quantity + 1
    if (value === item.quantity) {
      setDisablePlus(true)
    }
    setDisableMinus(false)

    setQuantity(value)
    handleSelect(item, value)
  }

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr 100px 65px;
        padding: 10px 0;
        text-align: left;
        width: 100%;
        p {
          margin: 5px 0;
        }
        align-items: center;
      `}
    >
      <p>{item.name}</p>

      <QuantitySelect
        onChange={(event) => handleSelect(item, event.target.value)}
      >
        <div style={{ opacity: disableMinus ? 0.25 : 1 }} onClick={handleMinus}>
          <SvgMinus
            css={css`
              height: 10px;
            `}
          />
        </div>
        <div>
          <span>
            {quantity} / {item.quantity}
          </span>
        </div>
        <div style={{ opacity: disablePlus ? 0.25 : 1 }} onClick={handlePlus}>
          <SvgPlus
            css={css`
              height: 10px;
            `}
          />
        </div>
      </QuantitySelect>

      <div
        css={css`
          display: flex;
          justify-content: flex-end;
        `}
      >
        <b>{currency(item.pricePerUnit, { fromCents: true }).format()}</b>
      </div>
    </div>
  )
}

BillItemSplit.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    pricePerUnit: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }),
}
