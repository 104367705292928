/** @jsx jsx */
// @flow
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'

export const Button = styled.button`
  ${(p) =>
    css`
      ${p.active &&
      css`
        && {
          background-color: #fcac24;
          color: #fff;
          box-shadow: 0 8px 16px 0 rgba(252, 172, 36, 0.32);
          pointer-events: auto;
        }
      `};

      ${p.secondary &&
      css`
        &&&& {
          color: #949eaf;
          background: #fff;
          box-shadow: none;
          border: solid 1px rgba(148, 158, 175, 0.16);
        }
      `};

      ${p.dark &&
      css`
        && {
          background-color: #000;
        }
      `};

      ${p.border &&
      css`
         {
          border: 2px solid #32cd32;
        }
      `};

      ${p.noMargin &&
      css`
        &&&& {
          margin: 0;
        }
      `};

      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 8px;
      background-color: #feebcb;
      color: #fff;
      border: 0;
      min-height: 50px;
      cursor: pointer;

      font-family: Helvetica;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-decoration: none;
      margin: 15px 0;
      pointer-events: none;
    `};
`

type ConfirmOpenCheckButtonProps = {
  active: Boolean,
  handleClick: Function,
}

export const ConfirmOpenCheckButton = ({
  active,
  handleClick,
}: ConfirmOpenCheckButtonProps) => (
  <Button
    css={css`
      background-color: #feebcb;
      pointer-events: none;
      font-size: 16px;
      text-align: center;
      color: #fcac24;

      ${active &&
      css`
        box-shadow: 0 8px 16px 0 rgba(252, 172, 36, 0.32);
        background-color: #fcac24;
        color: #ffffff;
        pointer-events: auto;
      `}
    `}
    onClick={() => handleClick()}
  >
    Confirm Open Check
  </Button>
)
