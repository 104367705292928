import React from 'react'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'
import LogoutButton from './Logout/LogoutButton'
import ChatButton from './ChatButton'
import Cookies from 'js-cookie'

const Container = styled.div`
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: #fff;
  transition: 0.2s ease;
  overflow-x: hidden;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
`

const SideNav = () => {
  const dispatch = useDispatch()

  const { app } = useSelector((state) => state)
  const cookie = Cookies.get('outpay_token')

  const closeNav = () => {
    dispatch({
      type: 'app/setSideNavWidth',
      payload: {
        sideNavWidth: '0%',
      },
    })
  }

  return (
    <React.Fragment>
      <Container style={{ width: app.sideNavWidth ?? '0%' }}>
        <ChatButton onClick={closeNav} />
        {cookie && <LogoutButton onClick={closeNav} />}
      </Container>
      {app.sideNavWidth === '60%' && <Overlay onClick={closeNav} />}
    </React.Fragment>
  )
}

export default SideNav
