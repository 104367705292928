import { css, ClassNames } from '@emotion/core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'

const ModalOverlayStyles = css`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s ease;
  z-index: 11;

  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }

  &.ReactModal__Overlay--before-close {
    opacity: 0;
  }
`

const ModalContentStyles = css`
  background: red;
  width: 100%;
`

const ModalContainer = ({ children, onRequestClose, isOpen, ...rest }) => {
  const [portraitMode, setPortraitMode] = useState(true)

  useEffect(() => {
    const listener = () => {
      if (window.orientation === 90 || window.orientation === -90) {
        setPortraitMode(false)
      } else {
        setPortraitMode(true)
      }
    }

    listener()

    window.addEventListener('orientationchange', listener)
    return () => {
      window.removeEventListener('orientationchange', listener)
    }
  }, [])

  return (
    <ClassNames>
      {({ css: emotionCSS }) => (
        <Modal
          {...rest}
          isOpen={isOpen && portraitMode}
          onRequestClose={onRequestClose}
          css={ModalContentStyles}
          overlayClassName={emotionCSS(ModalOverlayStyles)}
          shouldCloseOnOverlayClick
          htmlOpenClassName="ReactModal__Html--open"
          closeTimeoutMS={500}
          ariaHideApp={false}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            },
            content: {
              position: 'absolute',
              top: '0',
              bottom: '0',
              left: 0,
              right: 0,
              background: 'none',
              border: 0,
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: '0',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
            },
          }}
        >
          {children}
        </Modal>
      )}
    </ClassNames>
  )
}

ModalContainer.propTypes = {
  children: PropTypes.element,
  onRequestClose: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default ModalContainer
