/* global botsify_embed */
import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import getCSSHeight from '../../utils/getCSSHeight'
import ModalContainer from '../Modal'
import ModalCloseButton from './ModalCloseButton'

const Container = styled.div`
  width: 100%;
  height: ${getCSSHeight(-80)};
  background-color: #fff;
  pointer-events: auto;
`

const ChatModal = () => {
  const dispatch = useDispatch()
  const { isChatOpen } = useSelector((state) => state.app)

  const setIFrameHeight = useCallback(() => {
    setTimeout(() => {
      const iframeElement = document.getElementById('botsify_embedbot_frame')
      if (iframeElement) {
        iframeElement.style.height = '100%'
      } else {
        setIFrameHeight()
      }
    }, 1000)
  }, [])

  useEffect(() => {
    if (isChatOpen) {
      botsify_embed.load(
        '7kRm9eQtX7MorRyi6otCn0eTEHGssfoK59lBZCuC',
        null,
        'rgb(255, 255, 255)'
      )
      setIFrameHeight()
    }
  }, [isChatOpen, setIFrameHeight])

  const closeChat = () => {
    dispatch({
      type: 'app/setIsChatOpen',
      payload: {
        isChatOpen: false,
      },
    })
  }

  return (
    <ModalContainer isOpen={isChatOpen} onRequestClose={closeChat}>
      <React.Fragment>
        <ModalCloseButton />
        <Container>
          <div style={{ height: '100%' }} id="botsify-embed-div"></div>
        </Container>
      </React.Fragment>
    </ModalContainer>
  )
}

export default ChatModal
