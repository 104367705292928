import axios from 'axios'
import Cookies from 'js-cookie'
import store from '../redux/store'

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
})

API.interceptors.request.use((request) => {
  const { method, data, baseURL, url } = request
  if (data) {
    console.log(`[???] ${method.toUpperCase()}: ${baseURL}${url}`, data)
  } else {
    console.log(`[???] ${method.toUpperCase()}: ${baseURL}${url}`)
  }
  const token = Cookies.get('outpay_token')
  if (token) {
    request.headers.Authorization = `Bearer ${token}`
  }
  return request
})

API.interceptors.response.use(
  (response) => {
    const {
      status,
      data,
      config: { method, url },
    } = response
    console.log(`[${status}] ${method.toUpperCase()}: ${url}`, data)
    console.log('INTECEPTOR SUCCESS', status, data)
    return response.data
  },
  (error) => {
    if (error.response) {
      const {
        status,
        data,
        config: { method, url },
      } = error.response
      console.log('INTECEPTOR ERROR', status, data)
      console.log(`[${status}] ${method.toUpperCase()}: ${url}`, data)
      if (status === 401 || status === 403) {
        Cookies.remove('outpay_token')
        store.dispatch({
          type: 'checkin/closeCheckin',
        })
        store.dispatch({
          type: 'patron/logoutPatron',
        })
        // add back in when socket/persistent errors are hooked up
        // store.dispatch({
        //   type: 'alerts/addAlert',
        //   payload: {
        //     message: 'unauthorized'
        //   }
        // })
      }
    }
    return Promise.reject(error.response?.data || error)
  }
)

const instances = {
  API,
}

export default instances
