import { generateMedia } from './emotion-media-query'

export default generateMedia({
  xxLarge: '1400px',
  xLarge: '1300px',
  layoutMaxWidth: '1200px',
  large: '1170px',
  mediumLarge: '1024px',
  medium: '960px',
  small: '767px',
  smaller: '700px',
  homeHeading: '640px',
  xSmall: '512px',
  xxSmall: '376px',
  xxxSmall: '320px',
})
