/** @jsx jsx */
// @flow
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import SvgOutpayLogotypeDisclimerOnlight from '../assets/images/outpay-logotype-disclimer-onlight.svg'
import Food from '../assets/images/food.png'
import Food2 from '../assets/images/food2.png'
import Food3 from '../assets/images/food3.png'
import Drink from '../assets/images/drink.png'
import Drink2 from '../assets/images/drink2.png'

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 575px;
  text-align: center;
`

const PageHero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 45px 30px;
`

const LogoDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 100px;
  width: 220px;

  > img {
    width: 100%;
    margin-bottom: 4px;
  }

  > span {
    color: #a9a9a9;
  }
`

const ImgGroup = styled.div`
  width: 300px;
  position: absolute;
  top: 230px;
  left: -65px;
  display: flex;
  flex-direction: column;

  > img:nth-of-type(1) {
    width: 40%;
    margin-left: 90px;
  }

  > img:nth-of-type(2) {
    width: 60%;
    margin-top: -20px;
  }
`

const ContentPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0 -8px 24px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  padding: 30px 20px;
  z-index: 1;
  position: absolute;
  bottom: 0;
`

const OutpayBackground = ({ children }) => {
  return (
    <PageContainer>
      <PageHero>
        <LogoDiv>
          <img alt="Outpay logo" src={SvgOutpayLogotypeDisclimerOnlight} />
        </LogoDiv>
        <img
          style={{ position: 'absolute', width: '70%', top: -80, left: -90 }}
          alt="food"
          src={Food}
        />
        <img
          style={{
            position: 'absolute',
            width: '50%',
            top: 0,
            right: -120,
            transform: 'rotate(-50deg)',
          }}
          alt="food2"
          src={Food2}
        />
        <img
          style={{ position: 'absolute', width: '30%', top: -10, right: 45 }}
          alt="drink"
          src={Drink}
        />
        <ImgGroup>
          <img alt="drink2" src={Drink2} />
          <img alt="food3" src={Food3} />
        </ImgGroup>
      </PageHero>
      <ContentPanel>{children}</ContentPanel>
    </PageContainer>
  )
}

export default OutpayBackground
