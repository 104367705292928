import * as React from 'react'

function SvgArrowLeft(props) {
  return (
    <svg width={18} height={14.828} {...props}>
      <g
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
      >
        <path d="M17 7.414H1" />
        <path data-name="Shape" d="M7 13.414l-6-6 6-6" />
      </g>
    </svg>
  )
}

export default SvgArrowLeft
