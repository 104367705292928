/** @jsx jsx */
// @flow
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'

import SvgCirclechecked from '../../assets/images/svgr-converted/Circlechecked'
import Bin from '../../assets/images/svgr-converted/Bin'

import determineCCLogo from '../../helpers/determine-cc-brand'

const Card = styled.div`
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: calc(84vw * 0.6296);
  width: 90vw;
  padding: 16px;
`

const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const CardNumber = styled.p`
  font-family: Helvetica;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #000000;
  margin: 1em 0 2em 0;
`

const CardExp = styled.p`
  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #949eaf;
  margin: 0;
`

const CircleUnchecked = styled.span`
  width: 24px;
  height: 24px;
  border: solid 1px rgba(148, 158, 175, 0.32);
  background-color: #f8f8f8;
  border-radius: 50%;
`

const SvgBin = styled(Bin)`
  height: 1.2rem;
  width: 1.2rem;
  margin: 0 0.5rem;
`

type CreditCardSelectProps = {
  active: Boolean,
  card: Object,
  handleClick: Function,
  setToDelete: Function,
  index: Number,
}

const CreditCardSelect = ({
  active,
  card,
  handleClick,
  setToDelete,
  index,
}: CreditCardSelectProps) => {
  const setCCAsDefault = () => {
    if (!active) {
      handleClick({ card })
    }
  }

  const expYear = (card.exp_year || '').toString().slice(-2)
  const last4 = (card.last4 || '').toString()
  const cardWithIndex = {
    ...card,
    index,
  }
  return (
    <Card
      css={
        active &&
        css`
          order: -1;
        `
      }
    >
      <CardRow onClick={setCCAsDefault}>
        {determineCCLogo(card.brand)}
        {active ? <SvgCirclechecked /> : <CircleUnchecked />}
      </CardRow>
      <CardRow onClick={setCCAsDefault}>
        <CardNumber css={css``}>{`•••• •••• ••••  ${last4}`}</CardNumber>
      </CardRow>
      <CardRow>
        <CardExp>{`Exp. Date  ${card.exp_month}/${expYear}`}</CardExp>
        {!active && <SvgBin onClick={() => setToDelete(cardWithIndex)} />}
      </CardRow>
    </Card>
  )
}

export default CreditCardSelect
