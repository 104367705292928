import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { isSafari, isIOS } from 'react-device-detect'
import GoogleLoginButton from '../components/Login/Google/google-login'
import AppleLoginButton from '../components/Login/Apple/apple-login'
import SvgFileText from '../assets/images/svgr/FileText'
import OnboardingAddPayment from './onboarding-add-payment'
import getCSSHeight from '../utils/getCSSHeight.js'
import OnboardApple from '../assets/gif/onboard-apple.gif'
import OnboardGoogle from '../assets/gif/onboard-google.gif'

const Container = styled.div`
  background-color: #fffdff;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${getCSSHeight()};
`

const AuthButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  bottom: 0;
`

const TermsContainer = styled.div`
  background-color: #f0f0f1;
  font-size: 10px;
  padding: 4px 10px;
  border-radius: 8px;
`

const Link = styled.a`
  color: #fcac24;
  text-decoration: none;
`

const Spacer = styled.div`
  height: 5px;
`

const Header = styled.div`
  padding: 16px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 16px;
  font-size: 12px;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: ${getCSSHeight(-50)};
`

const OnboardImageWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  width: 100%;
  width: ${(props) => (props.isIOSChrome ? '80%' : '90%')};

  @media screen and (min-height: 635px) {
    width: ${(props) => (props.isIOSChrome ? '98%' : '100%')};
  }

  @media screen and (min-height: 755px) {
    width: 100%;
  }
`

const OnboardImage = styled.img`
  width: 100%;
`

const OnboardingSignIn = ({ handleSkipToMenu }) => {
  return (
    <ContentContainer isIOSChrome={isIOS && !isSafari}>
      <OnboardImageWrapper isIOSChrome={isIOS && !isSafari}>
        {isSafari || isIOS ? (
          <OnboardImage src={OnboardApple} onClick={handleSkipToMenu} />
        ) : (
          <OnboardImage src={OnboardGoogle} onClick={handleSkipToMenu} />
        )}
      </OnboardImageWrapper>
      <AuthButtonContainer>
        {(isSafari || isIOS) && <AppleLoginButton />}
        {isIOS && !isSafari && <Spacer />}
        {!isSafari && <GoogleLoginButton />}
        <TermsContainer>
          By signing in, you agree to Outpay’s{' '}
          <Link
            href="https://www.termsfeed.com/live/27b69fd4-d478-4c53-b2cb-2a411c6eb16c"
            target="_blank"
          >
            Terms{' '}
          </Link>
          &{' '}
          <Link
            href="https://www.termsfeed.com/live/8a6140ca-01a3-410d-95f3-3cc610ce228d"
            target="_blank"
          >
            Privacy Policy
          </Link>
        </TermsContainer>
      </AuthButtonContainer>
    </ContentContainer>
  )
}

const Onboarding = ({ patronLoggedIn }) => {
  const { vendor } = useSelector((state) => state)
  const dispatch = useDispatch()
  const handleSkipToMenu = () => {
    dispatch({
      type: 'app/setShowOnboarding',
      payload: {
        showOnboarding: false,
      },
    })
  }

  return (
    <Container>
      {!patronLoggedIn && (
        <Header>
          <span>{vendor.name}</span>
          <IconContainer onClick={handleSkipToMenu}>
            <span>Skip to Menu</span>
            <SvgFileText />
          </IconContainer>
        </Header>
      )}

      {patronLoggedIn ? (
        <OnboardingAddPayment />
      ) : (
        <OnboardingSignIn handleSkipToMenu={handleSkipToMenu} />
      )}
    </Container>
  )
}

export default Onboarding
