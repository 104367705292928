/** @jsx jsx */
// @flow
import { Fragment } from 'react'
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'
import ModalContainer from '../Modal'
import { Button } from '../Button'
import { ButtonsContainer } from '../../layouts/bill'
import axios from '../../utils/axios'
import CurvedModalContainer from './CurvedModal'
import { Divider } from './ConfirmOpenCheck'
import ModalCloseButton from './ModalCloseButton'
import { useAlert } from 'react-alert'
import determineCCLogo from '../../helpers/determine-cc-brand'

const ContentHeader = styled.h3`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  margin-top: 0;
`

type DeleteCCModalProps = {
  card: Object,
  handleClose: Function,
}

const DeleteCCModal = ({ card, handleClose }: DeleteCCModalProps) => {
  const { patron } = useSelector((state) => state)
  const dispatch = useDispatch()
  const alert = useAlert()
  const handleDelete = async () => {
    if (card.id) {
      console.log(card)
      await axios.API.delete(`/cc/patron/${patron.id}`, {
        data: {
          card_id: card.id,
        },
      })
        .then(({ data }) => {
          let cards = [...patron.cards]
          cards.splice(card.index, 1)
          dispatch({
            type: 'patron/updatePatron',
            payload: {
              cards,
            },
          })
          handleClose()
        })
        .catch((error) => {
          alert.show(error.message || error, {
            timeout: 0,
            header: 'Could not delete credit card.',
            gif: 'warning',
          })
        })
    }
  }
  return (
    <ModalContainer isOpen={!!card} onRequestClose={handleClose}>
      <Fragment>
        <ModalCloseButton />
        <CurvedModalContainer isOpen={!!card}>
          <p
            css={css`
              margin: 10px 0;
            `}
          >
            Delete Credit Card
          </p>
          {card && (
            <ContentHeader
              css={css`
                width: 100%;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;
              `}
            >
              {determineCCLogo(card.brand)}
              <div
                css={css`
                  margin-left: 1rem;
                `}
              >
                {`•••• •••• •••• ${card.last4}`}
              </div>
            </ContentHeader>
          )}
          <p
            css={css`
              margin-bottom: 0;
            `}
          >
            Are you sure you want to delete this card?
          </p>
          <Divider />
          <ButtonsContainer
            css={css`
              margin-top: 15px;
              grid-template-columns: 1fr;

              button {
                margin: 0;
              }
            `}
          >
            <Button
              active
              onClick={handleDelete}
              css={css`
                background-color: #e36f6b;
                color: #fff;
              `}
            >
              Delete
            </Button>
            <Button secondary active onClick={handleClose}>
              Keep
            </Button>
          </ButtonsContainer>
        </CurvedModalContainer>
      </Fragment>
    </ModalContainer>
  )
}

export default DeleteCCModal
