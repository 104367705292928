/** @jsx jsx */
// @flow
import { useEffect, memo } from 'react'
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'

const MenuIFrame = styled.iframe`
  width: 100%;
  height: 99.3%;
  border: none;
`

const MenuIframe = memo(({ menuUrl, logoUrl }) => {
  const buildIMenuBlob = (url) => {
    const getBlobURL = (code, type) => {
      const blob = new Blob([code], { type })
      return URL.createObjectURL(blob)
    }
    const id = url.split('!')[1]
    const source = `
      <html>
        <head>
          <script id=${id} type="text/javascript" src=${url}></script>
          <style>
            html {
              scroll-behavior: smooth;
            }
            div.imp-wrapper-match2 a {
              display: none !important;
            }
            #menuLogoContainer {
              background-color: #fff;
              width: 100vw;
              display: flex;
              flex-flow: row nowrap;
              justify-content: center;
              align-items: center;
            }
            #menuLogo {
              margin: 14px;
            }
            #quicklink-container {
              display: flex;
              flex-flow: row nowrap;
              max-width: 100vw;
              overflow: auto;
              background-color: #fff;
              opacity: 1;
              z-index: 999;
            }
            .quicklink {
              text-align: center;
              white-space: nowrap;
              padding: 14px;
              text-decoration: none;
            }
            .sticky {
              position: fixed;
              top: 0;
            }
            .sticky + .content {
              padding-top: 102px;
            }
            ::-webkit-scrollbar {
                display: none;
            }
          </style>
        </head>
        <body>
        </body>
      </html>
    `

    const blobUrl = getBlobURL(source, 'text/html')
    return blobUrl
  }

  let headingInterval
  const iframeOnload = (event) => {
    const body = event.target.contentWindow.document.body
    body.style.margin = 0
    const logoContainer = event.target.contentWindow.document.createElement(
      'DIV'
    )
    logoContainer.setAttribute('id', 'menuLogoContainer')
    const logo = event.target.contentWindow.document.createElement('IMG')
    logo.setAttribute('src', logoUrl)
    logo.setAttribute('id', 'menuLogo')
    logoContainer.appendChild(logo)
    body.appendChild(logoContainer)
    const linkContainer = event.target.contentWindow.document.createElement(
      'DIV'
    )
    linkContainer.setAttribute('id', 'quicklink-container')
    body.appendChild(linkContainer)
    event.persist()
    headingInterval = setInterval(() => {
      let headings = event.target.contentWindow.document.getElementsByClassName(
        'imp-normal-heading'
      )
      if (headings[0]) {
        let header = event.target.contentWindow.document.getElementById(
          'quicklink-container'
        )
        let sticky = header.offsetTop
        for (let i = 0; i < headings.length; i++) {
          let quicklink = event.target.contentWindow.document.createElement('A')
          quicklink.classList.add('quicklink')
          quicklink.innerHTML = headings[i].innerHTML
          quicklink.addEventListener('click', () => {
            const yOffset = -46
            const element = headings[i]
            const y =
              element.getBoundingClientRect().top +
              event.target.contentWindow.pageYOffset +
              yOffset
            return event.target.contentWindow.scrollTo({
              top: y,
              behavior: 'smooth',
            })
          })
          linkContainer.appendChild(quicklink)
        }
        const stick = () => {
          if (event.target.contentWindow.pageYOffset > sticky) {
            header.classList.add('sticky')
          } else {
            header.classList.remove('sticky')
          }
        }
        event.target.contentWindow.document.getElementsByClassName(
          'imp-menu'
        )[0].scrollTop -= 46
        event.target.contentWindow.onscroll = () => {
          stick()
        }
        clearInterval(headingInterval)
      }
    }, 100)
  }

  const url = menuUrl.includes('imenupro.com')
    ? buildIMenuBlob(menuUrl)
    : menuUrl
  const onLoad = menuUrl.includes('imenupro.com') ? iframeOnload : null

  useEffect(() => {
    return () => {
      clearInterval(headingInterval)
    }
  })
  return (
    <MenuIFrame
      id="menuIFrame"
      title="venue_menu"
      src={url}
      onLoad={onLoad}
    ></MenuIFrame>
  )
})

export default MenuIframe
