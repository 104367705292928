/** @jsx jsx */
// @flow
import { useState, useCallback, useRef } from 'react'
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CurvedModalContainer from './CurvedModal'
import { ConfirmOpenCheckButton } from '../Button'
import AddMobilePay from '../Checkout/AddMobilePay'
import ModalContainer from '../Modal'
import axios from '../../utils/axios'
import ModalCloseButton from './ModalCloseButton'
import Loader from '../../components/Loader/Loader'
import NameForm from '../Form/NameForm'
import { Button } from '../../components/Button'
import AddCreditCard from '../../components/Checkout/AddCreditCard'
import { isSafari } from 'react-device-detect'
import SelectPaymentButton from '../Buttons/SelectPaymentButton'
import { useAlert } from 'react-alert'

const NameContainer = styled.div`
  height: 48px;
  line-height: 48px;
  border-radius: 8px;
  background-color: #f8f8f8;
  width: 100%;
  border: 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #949eaf;
`

const AddPaymentRow = styled.div`
  width: 100%;
`

const ContentHeader = styled.p`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
`

export const Divider = styled.span`
  height: 1px;
  width: 100%;
  background-color: #f0f0f0;
  display: block;
  text-align: center;
  margin: 20px 0;
`

const ContentContainer = styled.div`
  width: 100%;
  z-index: 2;
  max-width: 575px;
  pointer-events: auto;
`

const TooltipText = styled.span`
  position: absolute;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #000;
  padding: 10px;
  text-align: center;
  font-size: 20px;
  color: #000;
  bottom: 96%;
  left: 20px;
  width: 230px;

  ::before {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
  }

  ::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
`

const ConfirmOpenCheckModal = ({ isOpen, setOpenCheckModalOpen }) => {
  const { patron, vendor, checkin, app } = useSelector((state) => state)
  const [showLoader, setShowLoader] = useState(false)
  const [showAddCreditCard, setShowAddCreditCard] = useState(false)
  const [paymentRequest, setPaymentRequest] = useState()
  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()
  const history = useHistory()
  const headerRef = useRef()
  const alert = useAlert()

  console.log('ConfirmOpenCheck', patron)

  const runCheckin = useCallback(() => {
    console.log('RUN CHECKIN')
    if (patron.defaultCard && vendor.id && !checkin.checkinId) {
      dispatch({
        type: 'app/setIsAutoCheckin',
        payload: {
          isAutoCheckin: false,
        },
      })
      setShowLoader(true)
      const table = app.table
      const revenueCenter = app.revenueCenter
      const server = app.server
      const check = app.check
      return axios.API.post(`/checkin/patron/${patron.id}`, {
        vendor_id: vendor.id,
        ...(table && {
          table,
        }),
        ...(revenueCenter && {
          revenueCenter,
        }),
        ...(server && {
          server,
        }),
        ...(check && {
          check,
        }),
      })
        .then(({ data }) => {
          setShowLoader(false)
          dispatch({
            type: 'checkin/postCheckin',
            payload: data,
          })
          history.push('/bill')
        })
        .catch((error) => {
          console.log('error: ', error)
          if (
            (error.message || '')
              .toLowerCase()
              .includes('One or more checkins already exist')
          ) {
            setTimeout(() => {
              runCheckin()
            }, 5000)
          } else {
            setShowLoader(false)
            alert.show(error.message || error, {
              timeout: 0,
              header: 'Error opening check',
              gif: 'warning',
            })
          }
        })
    }
  }, [
    dispatch,
    history,
    vendor.id,
    patron.defaultCard,
    patron.id,
    app.table,
    app.revenueCenter,
    app.server,
    checkin.checkinId,
    app.check,
    alert,
  ])

  useEffect(() => {
    if (app.isAutoCheckin) {
      runCheckin()
    }
  }, [app.isAutoCheckin, runCheckin])

  useEffect(() => {
    const interval = setInterval(() => {
      if (headerRef.current) {
        headerRef.current.classList.toggle('animate__heartBeat')
      }
    }, 2500)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const handleStripeSuccess = useCallback(
    (card) => {
      dispatch({
        type: 'patron/updatePatron',
        payload: {
          defaultCard: card,
          cards: [card],
        },
      })
    },
    [dispatch]
  )

  useEffect(() => {
    if (!isOpen) {
      setShowAddCreditCard(false)
    }
  }, [isOpen])

  useEffect(() => {
    if (!isSafari && isOpen && paymentRequest) {
      paymentRequest.show()
    }
  }, [isOpen, paymentRequest])

  const handlePaymentRequest = useCallback(
    (paymentRequest) => {
      setLoading(false)
      if (!patron.defaultCard && !isSafari) {
        if (paymentRequest) {
          setPaymentRequest(paymentRequest)
          setShowAddCreditCard(false)
        } else {
          setShowAddCreditCard(true)
        }
      }
    },
    [patron.defaultCard]
  )

  const handleStripeCancel = useCallback(
    (event) => {
      setOpenCheckModalOpen(false)
    },
    [setOpenCheckModalOpen]
  )

  if (patron.firstName && patron.lastName) {
    if (patron.defaultCard) {
      return (
        <ModalContainer
          isOpen={isOpen}
          onRequestClose={() => setOpenCheckModalOpen(false)}
        >
          <Fragment>
            <ModalCloseButton onClick={() => setOpenCheckModalOpen(false)} />
            <CurvedModalContainer isOpen={isOpen}>
              <Fragment>
                <ContentHeader ref={headerRef} className="animate__heartBeat">
                  Tell your server or bartender your name and check number
                </ContentHeader>
                <NameContainer>
                  {patron.firstName} {patron.lastName}
                </NameContainer>
                <Divider />
                {!app.isAutoCheckin && <SelectPaymentButton />}
              </Fragment>
              {!app.isAutoCheckin &&
                (showLoader ? (
                  <Loader />
                ) : (
                  <ConfirmOpenCheckButton
                    active={!!patron.defaultCard}
                    handleClick={runCheckin}
                  />
                ))}
            </CurvedModalContainer>
          </Fragment>
        </ModalContainer>
      )
    } else {
      if (showAddCreditCard) {
        return (
          <ModalContainer
            isOpen={isOpen}
            onRequestClose={() => setOpenCheckModalOpen(false)}
          >
            <Fragment>
              <ModalCloseButton onClick={() => setOpenCheckModalOpen(false)} />
              <ContentContainer
                css={css`
                  position: relative;
                `}
              >
                <TooltipText className="scale-in-bottom">
                  Enter payment method
                  <br /> to open your tab.
                </TooltipText>
                <AddCreditCard />
              </ContentContainer>
            </Fragment>
          </ModalContainer>
        )
      } else {
        if (isSafari) {
          return (
            <ModalContainer
              isOpen={isOpen}
              onRequestClose={() => setOpenCheckModalOpen(false)}
            >
              <Fragment>
                <ModalCloseButton
                  onClick={() => setOpenCheckModalOpen(false)}
                />
                <CurvedModalContainer isOpen={isOpen}>
                  <ContentHeader ref={headerRef} className="animate__heartBeat">
                    Select payment method
                  </ContentHeader>
                  <Divider />
                  <AddPaymentRow>
                    <AddMobilePay
                      onSuccess={handleStripeSuccess}
                      onPaymentRequest={handlePaymentRequest}
                    />
                    {loading ? (
                      <Loader />
                    ) : (
                      <Button
                        active
                        dark
                        onClick={() => setShowAddCreditCard(true)}
                      >
                        Add Credit Card
                      </Button>
                    )}
                  </AddPaymentRow>
                </CurvedModalContainer>
              </Fragment>
            </ModalContainer>
          )
        } else {
          return (
            <AddMobilePay
              onSuccess={handleStripeSuccess}
              onCancel={handleStripeCancel}
              onPaymentRequest={handlePaymentRequest}
            />
          )
        }
      }
    }
  } else {
    return (
      <ModalContainer
        isOpen={isOpen}
        onRequestClose={() => setOpenCheckModalOpen(false)}
      >
        <Fragment>
          <ModalCloseButton onClick={() => setOpenCheckModalOpen(false)} />
          <CurvedModalContainer isOpen={isOpen}>
            <ContentHeader>
              Enter your first and last name to open a tab
            </ContentHeader>
            <NameForm />
          </CurvedModalContainer>
        </Fragment>
      </ModalContainer>
    )
  }
}

export default ConfirmOpenCheckModal
