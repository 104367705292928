import React from 'react'
import styled from '@emotion/styled'
import { isSafari, isIOS } from 'react-device-detect'

import GoogleLoginButton from './Login/Google/google-login'
import AppleLoginButton from './Login/Apple/apple-login'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 0 15px;
  z-index: 10;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 575px;
`

const TermsContainer = styled.div`
  background-color: #f0f0f1;
  font-size: 10px;
  padding: 4px 10px;
  border-radius: 8px;
`

const Link = styled.a`
  color: #fcac24;
  text-decoration: none;
`

const TooltipText = styled.span`
  position: absolute;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #000;
  padding: 10px;
  text-align: center;
  font-size: 16px;
  color: #000;
  bottom: 108%;

  ::before {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
  }

  ::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
`
const Spacer = styled.div`
  height: 5px;
`
const ConfirmLogin = () => {
  return (
    <Container>
      <TooltipText className="scale-in-bottom">
        Tap now to start your tab,
        <br />
        then order with your server.
      </TooltipText>
      <Content>
        {(isSafari || isIOS) && <AppleLoginButton />}
        { isIOS && !isSafari && <Spacer />}
        {!isSafari && <GoogleLoginButton />}
        <TermsContainer>
          By signing in, you agree to Outpay’s{' '}
          <Link
            href="https://www.termsfeed.com/live/27b69fd4-d478-4c53-b2cb-2a411c6eb16c"
            target="_blank"
          >
            Terms{' '}
          </Link>
          &{' '}
          <Link
            href="https://www.termsfeed.com/live/8a6140ca-01a3-410d-95f3-3cc610ce228d"
            target="_blank"
          >
            Privacy Policy
          </Link>
        </TermsContainer>
      </Content>
    </Container>
  )
}

export default ConfirmLogin
