import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  checkinId: null,
  vendor: {
    id: null,
  },
} // populate with that the state will look like...

const checkinSlice = createSlice({
  name: 'checkin',
  initialState,
  reducers: {
    postCheckin(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    updateCheckin(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    closeCheckin() {
      return initialState
    },
  },
})

export const { postCheckin, updateCheckin, closeCheckin } = checkinSlice.actions

export default checkinSlice.reducer
