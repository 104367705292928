import React, { useState, useCallback, Fragment } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import AddMobilePay from '../components/Checkout/AddMobilePay'
import AddCreditCard from '../components/Checkout/AddCreditCard'
import OnboardAppleCard from '../assets/gif/onboard-apple-card.gif'
import OnboardApplePayCard from '../assets/gif/onboard-apple-pay-card.gif'
import OnboardGoogleCard from '../assets/gif/onboard-google-card.gif'
import OnboardGooglePayCard from '../assets/gif/onboard-google-pay-card.gif'
import { isSafari, isIOS } from 'react-device-detect'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`

const Fill = styled.span`
  flex: 1;
`

const AddPaymentRow = styled.div`
  width: 80%;
`

const OnboardImage = styled.img`
  width: 65%;

  @media screen and (min-height: 568px) {
    width: 80%;
  }

  @media screen and (min-height: 635px) {
    width: 100%;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  > p {
    margin: 8px 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  opacity: ${(props) => (props.hide ? 0 : 1)};
  -webkit-transition: opacity 1.5s ease-in-out;
  -moz-transition: opacity 1.5s ease-in-out;
  transition: opacity 1.5s ease-in-out;
`

const OnboardingAddPayment = () => {
  const dispatch = useDispatch()
  const [showAddCreditCard, setShowAddCreditCard] = useState(false)
  const [mobilePayAvailable, setMobilePayAvailable] = useState(false)
  const [loading, setLoading] = useState(true)

  const handleStripeSuccess = useCallback(
    (card) => {
      dispatch({
        type: 'patron/updatePatron',
        payload: {
          defaultCard: card,
          cards: [card],
        },
      })
    },
    [dispatch]
  )

  const handlePaymentRequest = useCallback((paymentRequest) => {
    setMobilePayAvailable(paymentRequest ? true : false)
    setLoading(false)

    setTimeout(() => {
      setShowAddCreditCard(true)
    })
  }, [])

  return (
    <Fragment>
      <ContentContainer>
        <Fill />
        {!loading &&
          (isSafari || isIOS ? (
            mobilePayAvailable ? (
              <OnboardImage src={OnboardApplePayCard} />
            ) : (
              <OnboardImage src={OnboardAppleCard} />
            )
          ) : mobilePayAvailable ? (
            <OnboardImage src={OnboardGooglePayCard} />
          ) : (
            <OnboardImage src={OnboardGoogleCard} />
          ))}

        <Wrapper hide={!showAddCreditCard}>
          <AddPaymentRow>
            <AddMobilePay
              onPaymentRequest={handlePaymentRequest}
              onSuccess={handleStripeSuccess}
            />
          </AddPaymentRow>
          {mobilePayAvailable && (
            <Row>
              <p>OR</p>
            </Row>
          )}
          <AddCreditCard />
        </Wrapper>
      </ContentContainer>
    </Fragment>
  )
}

export default OnboardingAddPayment
