/** @jsx jsx */
// @flow
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { useState } from 'react'
import { Button } from '../Button'
import Loader from '../Loader/Loader'
import axios from '../../utils/axios'
import { useAlert } from 'react-alert'
import { useSelector, useDispatch } from 'react-redux'

const FormInput = styled.input`
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  color: #949eaf;

  height: 48px;
  width: 50%;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  outline: none;

  background-color: #f8f8f8;
  border: 0;

  ::placeholder {
    color: #949eaf;
  }
`

const FormContainer = styled.form``

const Row = styled.div`
  display: flex;
  justify-content: center;
`

const NameForm = () => {
  const { patron } = useSelector((state) => state)
  const [firstName, setFirstName] = useState(patron.firstName)
  const [lastName, setLastName] = useState(patron.lastName)
  const [showLoader, setShowLoader] = useState(false)

  const dispatch = useDispatch()
  const alert = useAlert()

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!firstName || !lastName) {
      alert.show('enter first name and last name', {
        timeout: 0,
        header: 'Could not update name. Try again.',
        gif: 'warning',
      })
      return
    }

    setShowLoader(true)
    try {
      const { data } = await axios.API.put(`/patron/${patron.id}`, {
        first_name: firstName,
        last_name: lastName,
      })
      dispatch({
        type: 'patron/updatePatron',
        payload: {
          firstName: data.first_name,
          lastName: data.last_name,
        },
      })
    } catch (error) {
      alert.show(error.message || error, {
        timeout: 0,
        header: 'Could not update name. Try again.',
        gif: 'warning',
      })
    } finally {
      setShowLoader(false)
    }
  }

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Row>
        <FormInput
          css={css`
            margin-right: 8px;
          `}
          placeholder="enter first name"
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
        />
        <FormInput
          placeholder="enter last name"
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
        />
      </Row>
      <Row>
        {showLoader ? (
          <Loader />
        ) : (
          <Button type="submit" active={!showLoader}>
            Update
          </Button>
        )}
      </Row>
    </FormContainer>
  )
}

export default NameForm
