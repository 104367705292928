// @flow
/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Button } from './Button'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 15px;
  z-index: 10;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 575px;
  padding: 10px;
`

const ConfirmOpenCheckFooter = ({ setOpenCheckModalOpen }) => {
  return (
    <Container>
      <Content>
        <Button active onClick={() => setOpenCheckModalOpen(true)}>
          Click Here to Open Check
        </Button>
      </Content>
    </Container>
  )
}

export default ConfirmOpenCheckFooter
