/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { useRef, useEffect } from 'react'
import './CurvedModal.css'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  bottom: -400px;
  transition: bottom 0.25s;
  text-align: center;
  position: fixed;
  z-index: 2;
  max-width: 575px;
  overflow-y: hidden;
  justify-content: flex-end;
  pointer-events: none;
`

const Oval = styled.div`
  position: absolute;
  z-index: 2;
  top: -15%;
  left: -50%;
  background: #fff;
  display: inline-block;
  height: calc(200%);
  width: calc(100% * 2);
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
`

export const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  pointer-events: auto;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 3;
  background: #fff;
  padding: 0 20px 20px 20px;
`

const CurvedModalContainer = ({ children, isOpen }) => {
  const ref = useRef()

  useEffect(() => {
    ref.current.classList.toggle('curved-modal-open')
  }, [isOpen])

  return (
    <ContentContainer ref={ref}>
      <MainContentContainer>
        <Oval />
        <Content>{children}</Content>
      </MainContentContainer>
    </ContentContainer>
  )
}

CurvedModalContainer.propTypes = {
  children: PropTypes.array,
}

export default CurvedModalContainer
