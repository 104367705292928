import React, { useState } from 'react'
import styled from '@emotion/styled'
import ShareIcon from '../assets/images/share.svg'
import ModalContainer from './Modal'
import ModalCloseButton from './modals/ModalCloseButton'
import CurvedModalContainer from './modals/CurvedModal'
import { useSelector } from 'react-redux'
const QRCode = require('qrcode.react')

const ShareIconContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  position: absolute;
  padding: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 11;
`

const ShareIconWrapper = styled.div`
  height: 24px;
  width: 24px;
`

const QRCodeContainer = styled.div`
  margin: 16px 0;
`

const Title = styled.p`
  margin: 8px 0;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
`

const ShareMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { vendor } = useSelector((state) => state)

  const handleClick = () => {
    setIsOpen(true)
  }
  return (
    <>
      <ShareIconContainer onClick={handleClick}>
        <ShareIconWrapper>
          <img src={ShareIcon} alt="share" />
        </ShareIconWrapper>
      </ShareIconContainer>
      <ModalContainer isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <>
          <ModalCloseButton onClick={() => setIsOpen(false)} />
          <CurvedModalContainer isOpen={isOpen}>
            <Title>Scan to view the menu</Title>
            <QRCodeContainer>
              <QRCode
                size={256}
                value={`${document.location.origin}/vendor/${vendor.id}/menu`}
              />
            </QRCodeContainer>
          </CurvedModalContainer>
        </>
      </ModalContainer>
    </>
  )
}

export default ShareMenu
