/** @jsx jsx */
/* global zE */
import { useEffect, useState, useCallback } from 'react'
import { css, jsx } from '@emotion/core'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { useSelector, useDispatch } from 'react-redux'
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  matchPath,
  useLocation,
} from 'react-router-dom'
import socketIOClient from 'socket.io-client'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import './App.css'
import { useAlert } from 'react-alert'
import Context from './context/index'
import axios from './utils/axios'
import AddPayment from './layouts/add-payment'
import MyBill from './layouts/bill'
import Entry from './layouts/entry'
import Landing from './layouts/landing'
import AdminPage from './layouts/admin'
import SelectPayment from './layouts/select-payment'
import PrivateRoute from './components/PrivateRoute'
import ThankYou from './layouts/thank-you'
import SplitBill from './layouts/split-bill'
import currency from 'currency.js'
import buildAlert from './utils/alert'
import getCSSHeight from './utils/getCSSHeight.js'
import RotateScreen from './assets/images/rotate-screen.jpg'
import Config from './config'
import SideNav from './components/SideNav'
import ChatModal from './components/modals/ChatModal'
import BillGuest from './layouts/bill-guest'
import Menu from './layouts/menu'
import Modal from 'react-modal'
import Fallback from './components/Fallback'
import * as Sentry from '@sentry/react'

Modal.setAppElement('#root')

if (process.env.NODE_ENV === 'development') {
  Object.keys(process.env).forEach((key) => {
    console.log(key, process.env[key])
  })
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
const RETRIES = 5

const reload = () => {
  return window.location.reload()
}

let socket

// If app leaves focus for whatever reason (phone closes, new tabs, etc...)
// When app comes back into focus check any pending messages in queue with resendMessages
document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    if (socket) {
      setTimeout(() => {
        resendMessages(RETRIES)
      }, 1000)
    }
  }
})

const resendMessages = (retries) => {
  if (retries === 0) {
    return
  }

  if (socket.connected) {
    socket.emit('resendMessages')
  } else {
    // Socket may not be connected upon page focus
    // delay and call itself
    setTimeout(() => {
      resendMessages(--retries)
    }, 2500)
  }
}

function App() {
  const [socketConnected, setSocketConnected] = useState(false)
  const [lastMessageId, setLastMessageId] = useState(null) // temporary FE fix to avoid duplicate messages
  const { patron, alerts, checkin, app } = useSelector((state) => state)
  const { ticket } = checkin
  const { defaultCard } = patron
  const last4 = defaultCard?.last4
  const { timeSent: lastMessageTimeSent } = checkin
  const dispatch = useDispatch()
  const alert = useAlert()
  const token = Cookies.get('outpay_token')
  const history = useHistory()
  const location = useLocation()
  const vendorPath = useCallback(
    matchPath(location.pathname, {
      path: '/vendor/:id',
      exact: true,
      strict: true,
    }),
    []
  )

  // temporary check to allow us to get to admin view, can remove when admin goes away
  const match = useCallback(useRouteMatch('/admin'), [])
  // hide default zendesk widget and open on button clicks
  zE('webWidget', 'hide')
  // display alerts on top level
  useEffect(() => {
    if (alerts.length > 0) {
      const alertData = alerts[alerts.length - 1]
      console.log('*****ALERT*****', alertData)
      buildAlert(alert, alertData)
    }
  }, [alerts, alert, dispatch])
  // check that token is still valid
  useEffect(() => {
    if (token) {
      const { id, exp, role } = jwtDecode(token)
      const expires = new Date(exp * 1000).getTime() // may want to subtract a day for buffer
      const now = new Date(Date.now()).getTime()
      // token is invalid or expired, delete and set patron to null
      if (!id || !exp || role !== 'patron' || expires < now) {
        Cookies.remove('outpay_token')
        dispatch({
          type: 'patron/logoutPatron',
        })
        dispatch({
          type: 'checkin/closeCheckin',
        })
      }
    }
  }, [token, dispatch])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const table = params.get('table')
    const revenueCenter = params.get('revenueCenter')
    const server = params.get('server')
    const check = params.get('check')
    const action = params.get('action')

    if (table) {
      dispatch({
        type: 'app/updateTable',
        payload: {
          table,
        },
      })
    } else {
      dispatch({
        type: 'app/updateTable',
        payload: {
          table: null,
        },
      })
    }

    if (revenueCenter) {
      dispatch({
        type: 'app/updateRevenueCenter',
        payload: {
          revenueCenter,
        },
      })
    } else {
      dispatch({
        type: 'app/updateRevenueCenter',
        payload: {
          revenueCenter: null,
        },
      })
    }

    if (server) {
      dispatch({
        type: 'app/updateServer',
        payload: {
          server,
        },
      })
    } else {
      dispatch({
        type: 'app/updateServer',
        payload: {
          server: null,
        },
      })
    }

    if (check) {
      dispatch({
        type: 'app/updateCheck',
        payload: {
          check,
        },
      })
    } else {
      dispatch({
        type: 'app/updateCheck',
        payload: {
          check: null,
        },
      })
    }

    if (action) {
      dispatch({
        type: 'app/updateAction',
        payload: {
          action,
        },
      })
    } else {
      dispatch({
        type: 'app/updateAction',
        payload: {
          action: null,
        },
      })
    }
    // eslint-disable-next-line
  }, [])

  // Connect websocket
  useEffect(() => {
    console.log('APP SOCKET USE EFFECT')
    if (!patron.token && socket && socketConnected) {
      socket.disconnect()
      return
    }

    if (patron.token && socket && !socketConnected) {
      // if token and existing socket instance and not connected
      // update token and connect again
      socket.io.opts.query = {
        token: patron.token,
      }
      socket.connect()
    }

    if (patron.token && !socket && !socketConnected) {
      socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
        query: {
          token: patron.token,
        },
        autoConnect: true,
        reconnect: true,
        forceNew: true,
        transports: ['websocket'],
      })

      socket.on('connect', () => {
        console.log('***** SOCKET CONNECTED *****')
        setSocketConnected(true)
      })

      socket.on('disconnect', (reason) => {
        console.log('***** SOCKET DISCONNECTED *****', reason)
        setSocketConnected(false)
      })

      socket.on('reconnect_attempt', () => {
        console.log('***RECONNECT ATTEMPT***')
        socket.io.opts.query = {
          token: patron.token,
        }
      })
    }
  }, [
    patron.id,
    patron.token,
    dispatch,
    socketConnected,
    setSocketConnected,
    alert,
  ])

  const onMessageHandler = useCallback(
    (message) => {
      console.log('***** SOCKET MESSAGE *****', message)
      socket.emit('messageAcknowledged', { messageId: message.messageId })
      // temporary FE fix to avoid duplicate messages
      if (lastMessageId === message.messageId) {
        console.warn(`skipping duplicate message`)
        return
      }
      setLastMessageId(message.messageId)

      if (lastMessageTimeSent > message.timeSent) {
        console.warn(
          `skipping. message is older than last message time ${lastMessageTimeSent}`
        )
        return
      }

      if (message.error) {
        const { code } = message.error
        console.log(code)
        dispatch({
          type: 'alerts/addAlert',
          payload: {
            error: message.error,
            data: message.data,
            // defaults
            timeout: 0,
            onOpen: () => {
              dispatch({
                type: 'alerts/clearAlert',
              })
            },
          },
        })
      }

      if (message.type === 'split payment received') {
        const { splitPayments } = message.data.ticket
        const payment = splitPayments[splitPayments.length - 1]

        let splitPayMessage = ''

        if (payment.subTotal > 0) {
          const percentTippable =
            message.data.ticket.undiscountedTotals.totalForTipCalculation /
            message.data.ticket.undiscountedTotals.subTotal
          const tippablePaid = payment.subTotal * percentTippable
          const percentTipped = Math.round((payment.tip / tippablePaid) * 100)
          splitPayMessage = `${payment.firstName} ${
            payment.lastName ?? ''
          } just paid ${currency(payment.subTotal, {
            fromCents: true,
          }).format()} + ${percentTipped || 0}% tip`
        } else {
          splitPayMessage = `${payment.firstName} ${
            payment.lastName ?? ''
          } just paid ${
            currency(payment.tip, {
              fromCents: true,
            }).format() || 0
          } tip`
        }

        dispatch({
          type: 'alerts/addAlert',
          payload: {
            error: {
              code: 'split_pay_received',
            },
            data: message.data,
            // defaults
            message: splitPayMessage,
            gif: 'pos',
            timeout: 0,
            onOpen: () => {
              dispatch({
                type: 'alerts/clearAlert',
              })
            },
          },
        })
      }

      if (
        message.type === 'post ticket' &&
        !message.error &&
        !ticket?.ticketId
      ) {
        dispatch({
          type: 'alerts/addAlert',
          payload: {
            error: {
              code: 'success_post_ticket',
            },
            data: message.data,
            // defaults
            message: `Checks left inactive for 90 minutes will be closed out to your card ending in •••• ${last4}.`,
            timeout: 0,
            onOpen: () => {
              dispatch({
                type: 'alerts/clearAlert',
              })
            },
          },
        })
      }

      if (
        message.type === 'post ticket' &&
        !message.error &&
        app.table &&
        !message.data.ticket.tableName
      ) {
        dispatch({
          type: 'alerts/addAlert',
          payload: {
            error: {
              code: 'success_post_ticket_from_invalid_table',
            },
            data: message.data,
            // defaults
            message:
              'Give your server your name and let them know a check was opened but not to a table.',
            timeout: 0,
            onOpen: () => {
              dispatch({
                type: 'alerts/clearAlert',
              })
            },
          },
        })
      }

      if (
        message.type === 'post ticket' &&
        !message.error &&
        app.revenueCenter &&
        !message.data.ticket.revenueCenter
      ) {
        dispatch({
          type: 'alerts/addAlert',
          payload: {
            error: {
              code: 'success_post_ticket_from_invalid_revenue_center',
            },
            data: message.data,
            // defaults
            message:
              'Give your server your name and let them know a check was opened but not to a revenue center.',
            timeout: 0,
            onOpen: () => {
              dispatch({
                type: 'alerts/clearAlert',
              })
            },
          },
        })
      }

      if (
        message.type === 'post ticket' &&
        !message.error &&
        app.server &&
        !message.data.ticket.server
      ) {
        dispatch({
          type: 'alerts/addAlert',
          payload: {
            error: {
              code: 'success_post_ticket_from_invalid_server',
            },
            data: message.data,
            // defaults
            message:
              'Give your server your name and let them know a check was opened but not to a server.',
            timeout: 0,
            onOpen: () => {
              dispatch({
                type: 'alerts/clearAlert',
              })
            },
          },
        })
      }

      if (
        ['captureFull', 'capturePartial'].includes(message.type) &&
        !message.error &&
        message.data.checkoutType === 'idle' &&
        message.data.ticket.items.length > 0
      ) {
        dispatch({
          type: 'alerts/addAlert',
          payload: {
            error: {
              code: 'idle_close_items',
            },
            data: message.data,
            // defaults
            timeout: 0,
            onOpen: () => {
              dispatch({
                type: 'alerts/clearAlert',
              })
            },
          },
        })
      }
      switch (message.type) {
        case 'post ticket':
        case 'update ticket':
        case 'build ticket':
        case 'post discount':
        case 'post payment':
        case 'create payment intent':
        case 'update payment intent':
        case 'capture payment intent':
        case 'split payment received':
        case 'initialPreauth':
        case 'capturePartial':
        case 'captureFull':
        case 'captureAddOn':
        case 'captureAvailable':
        case 'createInvoice':
        case 'payInvoice':
        case 'save to database':
          dispatch({
            type: 'checkin/updateCheckin',
            payload: {
              ...message.data,
              timeSent: message.timeSent,
            },
          })
          break
        default:
          console.log('Unknown message type in websocket', message)
      }
    },
    [
      dispatch,
      lastMessageTimeSent,
      ticket,
      app.table,
      app.revenueCenter,
      app.server,
      lastMessageId,
      last4,
    ]
  )

  useEffect(() => {
    if (patron.token && socket && socketConnected) {
      socket.off('message')
      socket.on('message', onMessageHandler)
    }
  }, [patron.token, socketConnected, onMessageHandler])

  useEffect(() => {
    if (patron.id && patron.token === token) {
      axios.API.get(`/checkin/patron/${patron.id}/active`).then(
        ({ data: activeCheckin }) => {
          if (activeCheckin?.checkinId) {
            const vendorId = +vendorPath?.params.id || vendorPath?.params.id
            if (
              vendorId !== undefined &&
              vendorId !== activeCheckin.vendor.id &&
              !activeCheckin.stripe.paymentIssue
            ) {
              return axios.API.put(`/checkin/${activeCheckin.checkinId}/close`)
            } else {
              dispatch({
                type: 'checkin/postCheckin',
                payload: activeCheckin,
              })
              if (
                checkin?.ticket?.open &&
                new Date() - (checkin.timeSent || 0) >
                  Config.WAIT_TIME_FOR_UPDATE_TICKET
              ) {
                axios.API.put(`/checkin/${activeCheckin.checkinId}/?force=true`)
              }

              if (!match) {
                history.push('/bill')
              }
            }
          } else {
            dispatch({
              type: 'checkin/closeCheckin',
            })
          }
        }
      )
    }
    // eslint-disable-next-line
  }, [patron.id, patron.token, dispatch, history, match, vendorPath, token])

  useEffect(() => {
    const listener = () => {
      if (window.orientation === 90 || window.orientation === -90) {
        document
          .getElementById('document')
          .classList.remove('supported-landscape-rotate')
      } else {
        document
          .getElementById('document')
          .classList.add('supported-landscape-rotate')
      }
    }

    listener()

    window.addEventListener('orientationchange', listener)
    return () => {
      window.removeEventListener('orientationchange', listener)
    }
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={Fallback}>
      <Context.Provider value={{}}>
        <div
          id="rotate-device-container"
          css={css`
            width: 100vw;
            height: 100vh;
          `}
        >
          <img
            css={css`
              height: 100%;
              width: 100%;
            `}
            alt="rotate-screen"
            src={RotateScreen}
          />
        </div>
        <div
          id="app-container"
          className="App"
          css={css`
            position: relative;
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            height: ${getCSSHeight()};
            overflow-x: hidden;
          `}
        >
          <Switch>
            <Route exact path="/vendor/:vendor_id">
              <Elements stripe={stripePromise}>
                <Entry />
              </Elements>
            </Route>
            <Route exact path="/vendor/:vendor_id/menu">
              <Menu />
            </Route>
            <PrivateRoute path="/bill">
              <Elements stripe={stripePromise}>
                <MyBill />
              </Elements>
            </PrivateRoute>
            <PrivateRoute path="/add-payment">
              <Elements stripe={stripePromise}>
                <AddPayment />
              </Elements>
            </PrivateRoute>
            <PrivateRoute path="/select-payment">
              <Elements stripe={stripePromise}>
                <SelectPayment />
              </Elements>
            </PrivateRoute>
            <Route path="/thank-you">
              <ThankYou />
            </Route>
            <Route path="/split/:id">
              <Elements stripe={stripePromise}>
                <SplitBill />
              </Elements>
            </Route>
            <Route path="/vendor/:vendor_id/checks/:check_id">
              <Elements stripe={stripePromise}>
                <BillGuest />
              </Elements>
            </Route>
            <Route path="/admin">
              <AdminPage />
            </Route>
            <Route
              path="/.well-known/apple-developer-merchantid-domain-association"
              onEnter={reload}
            />
            <Route path="/robots.txt" onEnter={reload} />
            <Route path="*">
              <Landing />
            </Route>
          </Switch>
          <SideNav />
          <ChatModal />
        </div>
      </Context.Provider>
    </Sentry.ErrorBoundary>
  )
}

export default App
