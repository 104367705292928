import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: null,
  token: null,
  name: null,
  defaultCard: null,
  cards: []
}

const patronSlice = createSlice({
  name: 'patron',
  initialState,
  reducers: {
    createLoginPatron(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    updatePatron(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    logoutPatron() {
      return initialState
    },
  },
})

export const {
  createLoginPatron,
  updatePatron,
  logoutPatron,
} = patronSlice.actions

export default patronSlice.reducer
